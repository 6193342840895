export default function ProductSummary({
    productImage,
    productName,
    quantity,
    productPrice,
}) {
    return (
        <div className="flex justify-between border-b border-gray py-[12px] mt-[12px]">
            <div className="flex gap-[4px]">
                <img
                    src={productImage}
                    alt="Product"
                    className="rounded-xl md:w-[80px] md:h-[80px] w-[60px] h-[60px]"
                />

                <div className="flex flex-col gap-[4px]">
                    <h2 className="fw-bold text-sm leading-[18.9px] text-black">
                        {productName}
                    </h2>
                    <h2 className="font-normal text-sm leading-[18.9px] text-[#505152]">
                        Black, 41
                    </h2>
                    <p className="font-normal text-sm leading-[18.9px] text-[#505152]">
                        x{quantity}
                    </p>
                </div>
            </div>
            <h1 className="fw-bold text-sm leading-[18.9px] text-black">
                {productPrice}
            </h1>
        </div>
    );
}
