import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StoreInfoNonReg = () => {
    const [isFormValid, setIsFormValid] = useState("");
    const navigate = useNavigate();
    const [business_name, setBusinessName] = useState("");
    const [business_address, setBusinessAddress] = useState("");
    const [category_id, setStoreCategory] = useState("");
    const [description, setDescription] = useState("");
    const [business_reg_number, setBusinessRegNumber] = useState("");
    const [twitter_url, setTwitterUrl] = useState("");
    const [instagram_url, setInstagramUrl] = useState("");
    const [website_address, setWebsiteAddress] = useState("");
    const [categories, setCategories] = useState([]);

    const valid = useCallback(() => {
        return (
            business_name !== "" &&
            business_address !== "" &&
            category_id !== "" &&
            description !== "" &&
            business_reg_number !== ""
        );
    }, [
        business_name,
        business_address,
        category_id,
        description,
        business_reg_number,
    ]);

    useEffect(() => {
        setIsFormValid(valid());
    }, [valid]);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/admin/categories",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/vnd.api+json",
                    },
                }
            );

            const result = await response.json();
            if (response.ok) {
                setCategories(result.data);
            } else {
                toast.error("Failed to fetch categories");
            }
        } catch (error) {
            toast.error("Failed to fetch categories");
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        }
    };

    const validate = () => {
        let proceed = true;
        let errorMessage = "Please enter the value in ";

        if (business_name == null || business_name === "") {
            proceed = false;
            errorMessage += "Your business Name ";
        }
        if (business_address == null || business_address === "") {
            proceed = false;
            errorMessage += "Your business Address ";
        }
        if (category_id == null || category_id === "") {
            proceed = false;
            errorMessage += "Your Store Category ";
        }
        if (business_reg_number == null || business_reg_number === "") {
            proceed = false;
            errorMessage += "Your Business registration Number ";
        }
        if (twitter_url !== null || twitter_url === "") {
            proceed = true;
        }
        if (instagram_url !== null || instagram_url === "") {
            proceed = true;
        }
        if (website_address !== null || website_address === "") {
            proceed = true;
        }

        if (!proceed) {
            toast.warning(errorMessage);
        }

        return proceed;
    };
    // const merchant_id = localStorage.getItem("merchant_id");

    const handleSubmit = async (e) => {
        e.preventDefault();
        const merchant_id = localStorage.getItem("merchant_id");
        const token = localStorage.getItem("authToken");
        const data = {
            merchant_id,
            business_name,
            business_address,
            category_id,
            description,
            business_reg_number,
            twitter_url,
            instagram_url,
            website_address,
        };

        if (validate()) {
            try {
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/merchant/store",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(data),
                    }
                );

                const result = await response.json();

                if (response.ok) {
                    localStorage.setItem("merchant_id", merchant_id);
                    toast.success("Registered successfully");
                    navigate("/accountCreated2");
                } else if (response.status === 400) {
                    let errorMessage = Object.values(result.message)
                        .flat()
                        .join("");
                    toast.error(errorMessage);
                } else {
                    toast.error(result.message || "Registration failed");
                }
            } catch (error) {
                toast.error("Registration failed");
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            }
        }
    };

    return (
        <div className="">
            <div className="">
                <div className="2xl:mx-20 pt-20 mx-4">
                    <h2 className="fw-bold text-2xl">Store Information</h2>
                    <h3 className="py-3 pb-10">
                        Fill in these details to set up your store.
                    </h3>
                </div>
                <div>
                    <div className=" md:flex-row flex flex-col justify-between mx-4">
                        <form
                            action=""
                            className=" 2xl:mx-20 w-full"
                            onSubmit={handleSubmit}
                        >
                            <label
                                className="fw-bold mb-3 inline-block"
                                htmlFor="Business Name"
                            >
                                Business Name
                            </label>
                            <br />
                            <input
                                type="text"
                                name="business_name"
                                placeholder="what you will like your store to be named"
                                value={business_name}
                                onChange={(e) =>
                                    setBusinessName(e.target.value)
                                }
                                className=" md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                            />
                            <br />
                            <label
                                className="fw-bold mb-3 inline-block"
                                htmlFor=""
                            >
                                Business Address
                            </label>
                            <br />
                            <input
                                type="text"
                                placeholder="Business Address"
                                value={business_address}
                                onChange={(e) =>
                                    setBusinessAddress(e.target.value)
                                }
                                className=" md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                            />{" "}
                            <br />
                            <div className="w-full">
                                <div className="w-full">
                                    <label
                                        className="fw-bold mb-3 inline-block"
                                        htmlFor="category_id"
                                    >
                                        Store Category
                                    </label>{" "}
                                    <br />
                                    <select
                                        id="category_id"
                                        value={category_id}
                                        onChange={(e) =>
                                            setStoreCategory(e.target.value)
                                        }
                                        className=" md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                                    >
                                        <option value="" className="">
                                            Select a category
                                        </option>
                                        {categories.map((category) => (
                                            <option
                                                key={category.id}
                                                value={category.id}
                                                className=""
                                            >
                                                {category.cat_name}
                                            </option>
                                        ))}
                                    </select>
                                    <br />
                                </div>
                            </div>
                            <label
                                className="fw-bold mb-3 inline-block"
                                htmlFor=""
                            >
                                Description
                            </label>
                            <br />
                            <input
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Describe what your business does in word"
                                className=" md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                            />
                            <br />
                            <label
                                className="fw-bold mb-3 inline-block"
                                htmlFor=""
                            >
                                Business Reg Number
                            </label>
                            <br />
                            <input
                                type="text"
                                value={business_reg_number}
                                onChange={(e) =>
                                    setBusinessRegNumber(e.target.value)
                                }
                                placeholder="Your Business Reg Number"
                                className=" md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                            />
                            <br />
                            <label
                                className="fw-bold mb-3 inline-block"
                                htmlFor=""
                            >
                                X(fka Twitter) URL
                            </label>
                            <br />
                            <input
                                type="text"
                                value={twitter_url}
                                onChange={(e) => setTwitterUrl(e.target.value)}
                                placeholder="Your Twitter Url if avaliable"
                                className=" md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                            />
                            <br />
                            <label
                                className="fw-bold mb-3 inline-block"
                                htmlFor=""
                            >
                                Instagram URL
                            </label>
                            <br />
                            <input
                                type="text"
                                value={instagram_url}
                                onChange={(e) =>
                                    setInstagramUrl(e.target.value)
                                }
                                placeholder="Your Instagram Url if avaliable"
                                className=" md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                            />
                            <br />
                            <label
                                className="fw-bold mb-3 inline-block"
                                htmlFor=""
                            >
                                Website Address
                            </label>
                            <br />
                            <input
                                type="text"
                                value={website_address}
                                onChange={(e) =>
                                    setWebsiteAddress(e.target.value)
                                }
                                placeholder="Your Website Address if avaliable"
                                className=" md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                            />
                            <br />
                            <div className="flex justify-center mt-10 mb-32 text-center cursor-pointer">
                                <button
                                    type="submit"
                                    className={`text-white fw-bold md:block w-full font-light  border-gray border outline-none focus:outline-none rounded-[12px]  p-[12px] py-[15px] ${
                                        isFormValid
                                            ? "bg-gradient-to-r from-red to-orange"
                                            : "bg-orange-300"
                                    }`}
                                    disabled={!isFormValid}
                                >
                                    Continue
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreInfoNonReg;
