import React from "react";
import logo from "../Images/cN1g46Vw_400x400.png";
import { Link } from "react-router-dom";
import { GoPerson } from "react-icons/go";
import { BiStoreAlt } from "react-icons/bi";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";

const UserType = () => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState("");

    const schema = yup.object().shape({
        userType: yup.string().required("User type is required"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data, e) => {
        e.preventDefault();

        const token = localStorage.getItem("authToken");
        const dataObj = { user_type: data.userType };

        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/user_type",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(dataObj),
                }
            );

            await response.json();

            if (data.userType === "buyer") {
                toast.success("User Type selected successfully");
                navigate("/login");
            } else if (data.userType === "seller") {
                toast.success("User Type selected successfully");
                navigate("/business");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div>
            <div>
                <div className="2xl:mx-24 flex items-center justify-between mx-2 mt-4">
                    <Link to="/">
                        <div className="flex items-center ">
                            <div>
                                <img src={logo} alt="" className="w-12" />
                            </div>
                            <div>
                                <h2 className="inline-block text-2xl font-bold">
                                    Quick<span className="text-red">Shop</span>
                                </h2>
                            </div>
                        </div>
                    </Link>
                    <div>
                        <Link to="/SignUp">
                            <button className="text-red px-10 py-2 fw-bold bg-orange-100 rounded-lg">
                                Login
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className=" pt-10 text-center">
                <h2 className="text-2xl fw-bold">Select your user type</h2>
                <p className="pt-2">How do you want to use QuickShop</p>
            </div>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <div className="md:flex-row flex flex-col justify-center mx-4 mt-24">
                    <div
                        className={`border-1 rounded-xl h-20 xl:w-40 2xl:w-40 w-100 md:w-80 2xl:mx-4 flex justify-between mr-4 ${
                            selectedOption === "buyer"
                                ? "border-1 border-red"
                                : "border-1 border-gray"
                        }`}
                    >
                        <div className="2xl:mx-10 flex mt-10">
                            <div className="bg-lightGreen w-10 h-10 ml-2 rounded-full">
                                <h2 className="text-darkGreen px-5 pt-5 text-lg">
                                    <GoPerson />
                                </h2>
                            </div>
                            <div className="2xl:mx-4 px-2">
                                <h2 className="text-xl fw-bold">A Buyer</h2>
                                <h2 className="text-lg">
                                    I want to Buy on QuickShop
                                </h2>
                            </div>
                        </div>
                        <div className="mt-10 mr-6">
                            <input
                                type="radio"
                                name="radio-8"
                                value="buyer"
                                className={` radio radio-error radio-sm w-4 h-4 ${
                                    selectedOption === "buyer" ? "text-red" : ""
                                }`}
                                {...register("userType")}
                                onChange={handleOptionChange}
                            />
                        </div>
                    </div>
                    <div
                        className={`border-1 rounded-xl h-20 xl:w-40 2xl:w-40 w-100 md:w-80 2xl:mx-4 flex justify-between mt-6 md:mt-0 ${
                            selectedOption === "seller"
                                ? "border-1 border-red"
                                : " border-1 border-gray"
                        }`}
                    >
                        <div className="2xl:mx-10 flex mt-10">
                            <div className="bg-skyBlue w-10 h-10 ml-2 rounded-full">
                                <h2 className="text-blue px-5 pt-5 text-lg">
                                    <BiStoreAlt />
                                </h2>
                            </div>
                            <div className="2xl:mx-4 px-2">
                                <h2 className="text-xl fw-bold">A Seller</h2>
                                <h2 className="text-lg">
                                    I want to Sell on QuickShop
                                </h2>
                            </div>
                        </div>
                        <div className="mt-10 mr-6">
                            <input
                                type="radio"
                                name="radio-8"
                                value="seller"
                                className={` radio radio-error radio-sm w-4 h-4 ${
                                    selectedOption === "seller"
                                        ? "text-red"
                                        : ""
                                }`}
                                {...register("userType")}
                                onChange={handleOptionChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-red text-center">
                    {errors.userType && <span>{errors.userType.message}</span>}
                </div>

                <div className=" 2xl:mx-44 md:mx-7 md:w-60 xl:w-99 xl:mx-30 2xl:w-40  flex justify-center w-full py-8 mt-20 mb-32 rounded-lg">
                    <button
                        type="submit"
                        className={`fw-bold text-white bg-orange-300 w-full mx-4 h-22 rounded-lg ${
                            selectedOption
                                ? "bg-gradient-to-r from-red to-orange"
                                : "bg-orange-300"
                        }`}
                        disabled={!selectedOption}
                    >
                        Continue
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UserType;
