import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Image from "../Images/images.jpg";
// import OrderEmpty from "../OrderEmpty/OrderEmpty";

const OrderBuy = ({ title, type }) => {
    // const navigate = useNavigate();
    const [pendingOrders, setPendingOrders] = useState([]);
    const [completedOrders, setCompletedOrders] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialVisit, setInitialVisit] = useState(true);
    const [activeTab, setActiveTab] = useState("Pending Orders");

    const tabs = ["Pending Orders", "Completed Orders"];
    useEffect(() => {
        if (initialVisit && pendingOrders.length === 0) {
            setError("No orders yet.");
        }
    }, [initialVisit, pendingOrders]);

    const fetchPendingOrders = async () => {
        const token = localStorage.getItem("authToken");
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/buyer/ord/pending",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            const data = responseData.data;

            if (Array.isArray(data)) {
                setPendingOrders(data);
            } else {
                console.error("Expected an array but got", data);
                setPendingOrders([]);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
            setInitialVisit(false);
        }
    };

    const fetchCompletedOrders = async () => {
        const token = localStorage.getItem("authToken");
        setLoading(true);
        setError(null);

        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/buyer/ord/completed",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            const data = responseData.data;

            if (Array.isArray(data)) {
                setCompletedOrders(data);
            } else {
                console.error("Expected an array but got", data);
                setCompletedOrders([]);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // const fetchOrderDetails = (orderId) => {
    //     localStorage.setItem("order_id", orderId);
    //     navigate(`/order-details/${orderId}`);
    // };

    // const fetchCompletedDetails = (orderId) => {
    //     localStorage.setItem("order_id", orderId);
    //     navigate("/orderBuy-complete");
    // };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === "Pending Orders") {
            fetchPendingOrders();
        } else if (tab === "Completed Orders") {
            fetchCompletedOrders();
        }
    };

    useEffect(() => {
        fetchPendingOrders();
        return () => {
            fetchPendingOrders();
        };
    }, []);

    return (
        <div className="md:mx-4">
            <h2>{title}</h2>
            <div className=" bg-white md:rounded-lg ">
                <div
                    role="tablist"
                    className=" flex border-b-1 border-b-gray ml-3  "
                >
                    {tabs.map((tab) => (
                        <button
                            key={tab}
                            type="button"
                            role="tab"
                            onClick={() => handleTabClick(tab)}
                            className={`md:p-[16px] p-[10px] fw-bold text-[13px] md:text-base border-b-2 border-transparent text-[#6D6D6D] ${
                                activeTab === tab
                                    ? "border-b-[#F90013]  text-[#F90013]"
                                    : ""
                            }`}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
                <div className="mx-3 md:py-5 py-3">
                    {activeTab === "Pending Orders" && (
                        <>
                            {loading && <div>Loading...</div>}
                            {!loading && error && <div>{error}</div>}
                            {!loading &&
                                !error &&
                                pendingOrders.length === 0 && (
                                    <div className="text-[14px] md:text-base">
                                        No Pending orders yet!
                                    </div>
                                )}
                            {!loading &&
                                !error &&
                                Array.isArray(pendingOrders) &&
                                pendingOrders.map((order) => (
                                    <div
                                        key={order.id}
                                        className="border-1 border-gray md:p-4 p-[8px] flex flex-col md:flex-row justify-between my-2 bg-white md:rounded-lg"
                                    >
                                        <div className="flex items-center gap-[5px]">
                                            {/* <div>
                                                <img
                                                    src={Image}
                                                    alt=""
                                                    className="md:w-24 w-[3rem] md:h-[5rem] rounded-lg"
                                                />
                                            </div> */}
                                            <div className=" text-[14px] md:text-base">
                                                <h2 className="fw-bold">
                                                    {
                                                        order.attributes
                                                            .store_name
                                                    }
                                                </h2>
                                                <p className="">
                                                    {new Date(
                                                        order.attributes.created_at
                                                    ).toLocaleString()}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex md:flex-col md:justify-center text-[14px] md:text-base justify-between items-center mt-[5px] md:mt-0">
                                            <h2 className="">
                                                Order #{order.id}
                                            </h2>
                                            <Link
                                                to={`/order-details/${order.id}`}
                                                className="text-red whitespace-nowrap p-2 bg-orange-100 md:rounded-lg"
                                            >
                                                View Orders
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                    {activeTab === "Completed Orders" && (
                        <>
                            {loading && <div>Loading...</div>}
                            {!loading && error && <div>{error}</div>}
                            {!loading &&
                                !error &&
                                completedOrders.length === 0 && (
                                    <div>No completed orders yet!</div>
                                )}
                            {!loading &&
                                !error &&
                                Array.isArray(completedOrders) &&
                                completedOrders.map((order) => (
                                    <div
                                        key={order.id}
                                        className="border-1 border-gray md:p-4 p-[8px] flex flex-col md:flex-row justify-between my-2 bg-white md:rounded-lg"
                                    >
                                        <div className="flex items-center gap-[5px]">
                                            <div>
                                                <img
                                                    src={Image}
                                                    alt=""
                                                    className="md:w-24 w-[3rem] md:h-[5rem] rounded-lg"
                                                />
                                            </div>
                                            <div className=" text-[14px] md:text-base">
                                                <h2 className="">
                                                    {
                                                        order.attributes
                                                            .store_name
                                                    }
                                                </h2>
                                                <p className="">
                                                    {new Date(
                                                        order.attributes.created_at
                                                    ).toLocaleString()}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex md:flex-col md:justify-center text-[14px] md:text-base justify-between items-center mt-[5px] md:mt-0">
                                            <h2 className="">
                                                Order #{order.id}
                                            </h2>
                                            <Link
                                                to={`/order-details/${order.id}`}
                                                className="bg-lemon text-darkLemon whitespace-nowrap p-2 md:rounded-lg"
                                            >
                                                View Orders
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrderBuy;
