import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../component/Context/Context";

const useAuth = () => {
    const { setLoggedIn } = useContext(Context);
    const navigate = useNavigate();
    const unauntheticated = (result) => {
        if (result?.message === "Unauthenticated") {
            setLoggedIn(false);
            localStorage.removeItem("user");
            localStorage.removeItem("authToken");
            localStorage.removeItem("businessType");
            localStorage.removeItem("firstName");
            navigate("/login");
        }
    };

    return { unauntheticated };
};
export default useAuth;
