/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import { BiStoreAlt } from "react-icons/bi";
import { IoBagOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TbSquares } from "react-icons/tb";
import axios from "axios";
import { Context } from "../Context/Context";
import Group36 from "../Images/Group 36.png";

// import { TbDashboardOff, TbSquares } from "react-icons/tb";

const MerchantDashBoard2 = ({ totalSales, pendingOrders, completedOrders }) => {
    // const [completedOrdersList, setCompletedOrdersList] = useState([]);
    // const [pendingOrdersList, setPendingOrdersList] = useState([]);
    const [recent, setRecent] = useState([]);
    const [id, setId] = useState("");
    const [error, setError] = useState(null);
    const [details, setDetails] = useState({});
    const { userType: user, getMerchant, storeDetails } = useContext(Context);
    const token = localStorage.getItem("authToken");
    const header = [
        "Date Processed",
        // "Product Name",
        "Cost",
        "Status",
        "Receipt",
    ];
    const shareLink = () => {
        if (storeDetails?.id) {
            const storeName = storeDetails.business_name.split(" ").join("-");
            navigator.clipboard
                .writeText(
                    `https://getquickshop.com/merchant-store/${storeName
                        .split(" ")
                        .join("-")}`
                )
                .then(() => {
                    toast("Store link copied to clipboard");
                    return;
                })
                .catch((err) => {
                    toast.error("Error copying stor link");
                });
            return;
        }
        toast.error("try agaim latter");
    };

    const getSingleTransaction = async (id) => {
        setId(id);
        const url = `https://getquickshop.online/api/v1/user/merchant/seller/transactions/${id}`;
        const result = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/vnd.api+json",
            },
        });
        document.getElementById("my_modal_3").showModal();
        setDetails(result.data.data);
    };

    useEffect(() => {
        fetchRecentOrder();
        getMerchant();

        return () => {
            getMerchant();
            fetchRecentOrder();
        };
    }, []);

    const fetchRecentOrder = async () => {
        const res = await axios.get(
            "https://getquickshop.online/api/v1/user/merchant/seller/transactions",
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        setRecent(res.data.data);
    };

    return (
        <div className="h-[90%] overflow-auto w-full md:text-base text-sm  px-[20px] md:px-[100px] ">
            <div className=" flex items-center justify-between gap-[10px] md:py-10 py-5">
                <div className="">
                    <h2 className="text-xl font-medium">
                        Good afternoon, {user.first_name} {user.last_name}.
                    </h2>
                    {/* <p>
                        Here are some things you need to do before you can sell
                        on Quickshop
                    </p> */}
                </div>
                <button
                    className="text-xs bg-red flex items-center px-4 py-2 rounded-lg text-white fw-bold gap-[10px]"
                    onClick={shareLink}
                >
                    <span className="hidden md:inline"> Share Store Link</span>
                    <span className="md:hidden"> Share </span>{" "}
                    <TbSquares className="text-sm block" />
                </button>
            </div>

            <div className="grid md:grid-cols-3 grid-cols-2 md:gap-[20px] gap-[10px] md:py-10 py-5 ">
                <div className="rounded-2xl  bg-white border border-gray">
                    <div className="md:p-6 p-2">
                        <h2 className="pt-7 text-red w-10 h-16 pl-5 text-lg bg-orange-100 rounded-full">
                            <BiStoreAlt className="" />
                        </h2>
                        <h2 className="py-2">Total Sales</h2>
                        <b>{totalSales}</b>
                    </div>
                </div>
                <div className="rounded-2xl  bg-white border border-gray">
                    <div className="md:p-6 p-2">
                        <h2 className="bg-skyBlue pt-7 text-blue w-10 h-16 pl-5 text-lg rounded-full">
                            <IoBagOutline className="" />
                        </h2>
                        <h2 className="py-2">Pending Orders</h2>
                        <b>{pendingOrders}</b>
                    </div>
                </div>
                <div className="rounded-2xl  bg-white border border-gray">
                    <div className="md:p-6 p-2">
                        <h2 className="bg-lightGreen pt-7 text-darkGreen w-10 h-16 pl-5 text-lg rounded-full">
                            <IoBagOutline className="" />
                        </h2>
                        <h2 className="py-2">Completed Orders</h2>
                        <b>{completedOrders}</b>
                    </div>
                </div>
            </div>

            <div className="md:py-10 py-5">
                <h2 className="text-xl font-medium">Recent Transactions</h2>
                <p className="pt-2">
                    Your store overview will appear here once you start selling.
                </p>
            </div>

            <div>
                <div className="">
                    <div className="h-fit  rounded-md bg-white">
                        <div className="overflow-x-auto">
                            <table className="table md:table-auto overflow-x-auto">
                                <thead>
                                    <tr>
                                        {header.map((e) => (
                                            <th className="p-[13px] text-left">
                                                {e}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {error ? (
                                        <tr>
                                            <td
                                                colSpan="8"
                                                className="pt-4 text-center"
                                            >
                                                No Orders found...
                                            </td>
                                        </tr>
                                    ) : (
                                        recent.map((item) => (
                                            <tr>
                                                <td className="p-[13px]">
                                                    {
                                                        item?.attributes
                                                            ?.transaction_date
                                                    }
                                                </td>
                                                <td className="p-[13px]">
                                                    &#8358;
                                                    {item?.attributes?.amount}
                                                </td>
                                                <td className="p-[13px]">
                                                    {item?.attributes?.status}
                                                </td>
                                                <td className="2xl:px-6 whitespace-nowrap px-4">
                                                    <div>
                                                        <button
                                                            className="btn-sm border-gray border-1 rounded-md"
                                                            onClick={() =>
                                                                getSingleTransaction(
                                                                    item?.id
                                                                )
                                                            }
                                                        >
                                                            View
                                                        </button>
                                                        <dialog
                                                            id="my_modal_3"
                                                            className="modal"
                                                        >
                                                            {Object.keys(
                                                                details
                                                            ).length > 0 && (
                                                                <div className="modal-box">
                                                                    <form method="dialog">
                                                                        <button className="btn btn-sm btn-circle btn-ghost right-2 top-2 absolute">
                                                                            ✕
                                                                        </button>
                                                                    </form>
                                                                    <div>
                                                                        <div className="flex justify-center">
                                                                            <img
                                                                                src={
                                                                                    Group36
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="text-center">
                                                                            <h2 className="pt-4 pb-2 font-medium">
                                                                                Payment
                                                                                Received
                                                                            </h2>
                                                                            <h5 className="pb-4 text-sm">
                                                                                Your
                                                                                payment
                                                                                for
                                                                                Order
                                                                                #
                                                                                {
                                                                                    id
                                                                                }{" "}
                                                                                is
                                                                                <br />{" "}
                                                                                {
                                                                                    details
                                                                                        ?.attributes
                                                                                        .status
                                                                                }

                                                                                .
                                                                                The
                                                                                total
                                                                                amount
                                                                                if{" "}
                                                                                {
                                                                                    details
                                                                                        ?.attributes
                                                                                        .amount
                                                                                }{" "}
                                                                                has
                                                                                been{" "}
                                                                                <br />{" "}
                                                                                deposited
                                                                                into
                                                                                your
                                                                                account.
                                                                            </h5>
                                                                            <h6 className="pb-6 text-sm">
                                                                                Thank
                                                                                you
                                                                                for
                                                                                selling
                                                                                on
                                                                                Quickshop!
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </dialog>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// {new Date(
//     order.attributes.created_at,
// ).toLocaleDateString("en-GB")}
export default MerchantDashBoard2;
