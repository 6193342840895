import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const ProductDashBoard = () => {
    const header = [
        "Product Name",
        "Price",
        "Quantity Sold",
        "Date Added",
        "Details",
    ];
    const [product, setProduct] = useState([]);
    const getProduct = async () => {
        const token = localStorage.getItem("authToken");
        const url =
            "https://getquickshop.online/api/v1/user/merchant/seller/products";
        const result = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/vnd.api+json",
            },
        });
        if (result.data.status === "Request was successful.")
            setProduct(result.data.data);
    };

    useEffect(() => {
        getProduct();
        return () => getProduct();
    }, []);

    return (
        <div className="h-[95rem]  py-[48px] md:px-[100px] px-[20px] text-sm md:text-base">
            <div className="flex justify-between items-center">
                <h2 className="fw-bold py-[16px] text-2xl">Products</h2>
                <div>
                    <Link
                        to="/products-addToProduct"
                        className="block bg-gradient-to-r from-red to-orange px-[16px] py-2 text-white rounded-lg"
                    >
                        Add New
                    </Link>
                </div>
            </div>

            <div className="bg-white rounded-[12px] w-full overflow-auto border border-gray mdmt-[40px] mt-[20px] ">
                <table className="table-auto w-full">
                    <thead>
                        <tr className="border-b-gray border-b">
                            {header.map((e) => (
                                <th key={e} className="p-[13px] text-left">
                                    {e}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {product.length > 0
                            ? product.map((prd) => (
                                  <tr
                                      className="border-b-gray border-b"
                                      key={prd.slug}
                                  >
                                      <td className="md:p-[13px] p-[8px] flex items-center md:gap-[10px] gap-[5px]">
                                          <img
                                              src={
                                                  prd.relationships
                                                      .product_images[0].images
                                              }
                                              alt=""
                                              className="md:w-[40px] w-[20px] rounded-[8px]"
                                          />
                                          <span>
                                              {prd.attributes.product_name}
                                          </span>
                                      </td>
                                      <td className="md:p-[13px] p-[8px]">
                                          <h2>
                                              &#8358;
                                              {prd.attributes.selling_price}
                                          </h2>
                                      </td>
                                      <td className="md:p-[13px] p-[8px]">
                                          {prd.attributes.quantity_sold} of{" "}
                                          {prd.attributes.total_count}
                                      </td>
                                      <td className="md:p-[13px] p-[8px]">
                                          {
                                              prd.attributes.created_at.split(
                                                  " "
                                              )[0]
                                          }
                                      </td>
                                      <td className="md:p-[13px] p-[8px]">
                                          <Link
                                              to="/update/product"
                                              state={{ prd }}
                                              className="rounded-[12px] border border-gray  fw-bold px-[14px] py-[8px]"
                                          >
                                              Edit
                                          </Link>
                                      </td>
                                  </tr>
                              ))
                            : ""}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductDashBoard;
