import React, { createContext, useContext, useState } from "react";

const CheckoutContext = createContext();

export const useCheckout = () => useContext(CheckoutContext);

export const CheckoutProvider = ({ children }) => {
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [usedAddress, setUsedAddress] = useState("");
  const [items, setItems] = useState([]);

  return (
    <CheckoutContext.Provider
      value={{
        customerName,
        setCustomerName,
        email,
        setEmail,
        phoneNumber,
        setPhoneNumber,
        totalPrice,
        setTotalPrice,
        selectedOption,
        setSelectedOption,
        usedAddress,
        setUsedAddress,
        items,
        setItems,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};
