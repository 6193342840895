/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import MerchantDashBoard from "../MerchantDashBoard/MerchantDashBoard";
import MerchantDashBoard2 from "../MerchantDashBoard2/MerchantDashBoard2";
import { useContext } from "react";
import { Context } from "../Context/Context";
import useAuth from "../../hooks/useAuth";

const DashBoard = () => {
    const [storeDetails, setStoreDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [componentToRender, setComponentToRender] = useState(null);
    const { setupChange, setLoggedIn } = useContext(Context);
    const { unauntheticated } = useAuth();
    const token = localStorage.getItem("authToken");
    const header = {
        Authorization: `Bearer ${token}`,
        Accept: "application/vnd.api+json",
    };

    const fetchStoreDetails = async () => {
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchant/seller/dashboard",
                {
                    method: "GET",
                    headers: header,
                }
            );

            const result = await response.json();
            unauntheticated(result);
            setStoreDetails(result);
            setLoading(false);

            if (result.data.business_type === "registered") {
                localStorage.setItem("businessType", "registered");
            } else if (result.data.business_type === "non-registered") {
                localStorage.setItem("businessType", "non-registered");
            }

            const responseMessage = result.message;
            if (
                responseMessage ===
                "One of the onboarding processes is incomplete"
            ) {
                setupChange(false);
                setComponentToRender(
                    <MerchantDashBoard
                        store={!result.data.store_details_status}
                        payment={!result.data.store_payment_status}
                        document={!result.data.store_document_status}
                    />
                );
            } else if (responseMessage === null) {
                setupChange(true);
                const totalSales = result.data.total_sales || 0;
                const pendingOrders = result.data.total_new_order || 0;
                const completedOrders = result.data.total_completed_order || 0;
                setComponentToRender(
                    <MerchantDashBoard2
                        totalSales={totalSales}
                        pendingOrders={pendingOrders}
                        completedOrders={completedOrders}
                    />
                );
            } else {
            }
        } catch (error) {
            console.error("Error fetching store details:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStoreDetails();
        return () => {
            fetchStoreDetails();
        };
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!storeDetails) {
        return <div>Error loading store details</div>;
    }

    return componentToRender;
};

export default DashBoard;
