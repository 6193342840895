import React, { useState } from "react";
import QuickShopShipping from "../QuickShopShipping/QuickShopShipping";
import HandleShipping from "../HandleShipping/HandleShipping";

const ShippingDetails = () => {
  const [selectedPage, setSelectedPage] = useState("");

  const handleSelectChange = (event) => {
    setSelectedPage(event.target.value);
  };

  return (
    <div className="border border-[#EBEDEF] md:w-[50%] w-full bg-white  p-[20px] rounded-[12px]">
      <label htmlFor="" className="fw-bold inline-block mb-3">
        How do you want to handle shipping?
      </label>
      <select
        className="border border-gray w-100 rounded-[12px] outline-none  select w-full max-w-lg "
        onChange={handleSelectChange}
        value={selectedPage}
      >
        <option value="">Select an option</option>
        <option value="QuickShopShipping">
          Use Quickshop's Shipping Partner (SHiip)
        </option>
        <option value="HandleShipping">Set Up Shipping Manually</option>
      </select>

      <div className="w-full">
        {selectedPage === "QuickShopShipping" && <QuickShopShipping />}
        {selectedPage === "HandleShipping" && <HandleShipping />}
      </div>
    </div>
  );
};

export default ShippingDetails;
