/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const QuickShopShipping = () => {
    const [formData, setFormData] = useState({
        street: "",
        state: "",
        city: "",
        phone_number: "",
    });

    const [isFormValid, setIsFormValid] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            phone_number: value,
        });
    };

    useEffect(() => {
        const isValid = Object.values(formData).every(
            (field) => field.trim() !== ""
        );
        setIsFormValid(isValid);
    }, [formData]);

    const handleSubmit = async (e) => {
        const token = localStorage.getItem("authToken");
        const merchant_id = localStorage.getItem("merchant_id");
        e.preventDefault();
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchant/shipping-preferences",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ ...formData, merchant_id }),
                }
            );

            if (response.ok) {
                toast.success("Changes saved successfully!");
            } else {
                toast.error("Failed to save changes.");
            }
        } catch (error) {
            toast.error("An error occurred.");
        }
    };

    return (
        <div>
            <div className="w-[100%]">
                <div>
                    {/* <form onSubmit={handleSubmit} className=""> */}
                    <h2 className="mt-[20px] px-[20px] py-[13px] rounded-[12px] fw-bold bg-[#E5F1FF]">
                        SHiiP is a trusted logistics partner that handles local
                        and international deliveries seamlessly. We have
                        partnered them to make your customers received their
                        packages in time.
                    </h2>
                    {/* <div className="py-2">
              <label htmlFor="street">Street</label>
              <input
                type="text"
                name="street"
                className="border-1 border-gray rounded-lg h-[50px] w-full px-4 outline-none"
                value={formData.street}
                onChange={handleChange}
              />
            </div>
            <div className="py-2">
              <label htmlFor="state">State</label>
              <input
                type="text"
                name="state"
                className="border-1 border-gray rounded-lg h-[50px] w-full px-4 outline-none"
                value={formData.state}
                onChange={handleChange}
              />
            </div>
            <div className="py-2">
              <label htmlFor="city">City</label>
              <input
                type="text"
                name="city"
                className="border-1 border-gray rounded-lg h-[50px] w-full px-4 outline-none"
                value={formData.city}
                onChange={handleChange}
              />
            </div>
            <div className="w-100 py-2">
              <PhoneInput
                country={"ng"}
                containerStyle={{ height: "50px" }}
                placeholder="Enter Phone Number"
                value={formData.phone_number}
                onChange={handlePhoneChange}
                inputStyle={{ height: "50px", width: "100%" }}
              />
            </div>
            <div className="py-4">
              <button
                type="submit"
                className={` w-full text-white rounded-lg h-[50px] ${
                  !isFormValid
                    ? "bg-orange-300"
                    : "bg-gradient-to-r from-red to-orange"
                }`}
                disabled={!isFormValid}
              >
                Save Changes
              </button>
            </div> */}
                    {/* </form> */}
                </div>
            </div>
        </div>
    );
};

export default QuickShopShipping;
