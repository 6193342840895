/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import logo from "../Images/cN1g46Vw_400x400.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const CodeVerify = () => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [phone_number, setPhoneNumber] = useState("");
    const navigate = useNavigate();
    const inputRefs = useRef([]);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [phoneNumbers, setPhoneNumbers] = useState(["+1234567890"]);

    useEffect(() => {
        const filled = otp.every(
            (value) => value.trim() !== "" && phone_number.trim() !== ""
        );
        setIsFormFilled(filled);
    }, [otp, phone_number]);

    const handlePhoneChange = (value, country) => {
        setPhoneNumber(value);
    };

    const validate = () => {
        let proceed = true;
        let errorMessage = "Please enter the value in ";

        if (!otp.join("").trim()) {
            proceed = false;
            errorMessage += "code ";
        }
        if (phone_number === "" || phone_number.startsWith("+")) {
            toast.error("Phone number must include the country code");
        } else if (phoneNumbers.includes(phone_number)) {
            proceed = false;
            errorMessage += " phone number already exists ,";
        }

        if (!proceed) {
            toast.warning(errorMessage);
        }
        return proceed;
    };

    const Verified = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");
        const dataObj = {
            code: otp.join(""),
            phone_number,
        };
        if (validate()) {
            try {
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/resetpassword/verify",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(dataObj),
                    }
                );
                const result = await response.json();
                if (response.ok) {
                    toast.success("Verified successfully");
                    navigate("/password");
                } else if (response.status === 422) {
                    let errorMessage = Object.values(result.message);
                    toast.error(errorMessage.join(" "));
                } else {
                    toast.error(result.message || "Verification failed");
                }
            } catch (error) {
                console.error("Error:", error.message);
            }
        }
    };

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (isNaN(value)) return;

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    return (
        <div>
            <div>
                <div className="flex 2xl:mx-24 justify-between items-center mx-2 mt-4">
                    <Link to="/">
                        <div className="flex items-center">
                            <div>
                                <img src={logo} alt="" className="w-12" />
                            </div>
                            <div>
                                <h2 className="inline-block text-2xl font-bold">
                                    Quick<span className="text-red">Shop</span>
                                </h2>
                            </div>
                        </div>
                    </Link>
                    <div>
                        <Link to="/Login">
                            <button className="text-red px-10 py-2 fw-bold bg-orange-100 rounded-lg">
                                Login
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="pt-16 text-center">
                <h2 className="pb-4 text-2xl fw-bold">
                    Enter Verification Code
                </h2>
                <p className="py-2">
                    We sent a code to your number <br /> Kindly enter the code
                    below.
                </p>
            </div>
            <form onSubmit={Verified}>
                <div className="flex justify-center">
                    {otp.map((data, i) => (
                        <div className="mt-4" key={i}>
                            <input
                                type="text"
                                name="otp"
                                value={data}
                                maxLength={1}
                                onChange={(e) => handleChange(e, i)}
                                onFocus={(e) => e.target.select()}
                                className="border-gray w-12 h-12 mx-[5px] text-center border-2 rounded-md outline-none"
                                ref={(el) => (inputRefs.current[i] = el)}
                            />
                        </div>
                    ))}
                </div>
                <div className="2xl:px-10 px-4 md:px-16 xl:px-28 pt-2 flex justify-center">
                    <PhoneInput
                        country={"ng"}
                        containerStyle={{
                            height: "60px",
                            width: "40%",
                            border: "1px solid red",
                            overflow: "hidden",
                            borderRadius: "9px",
                            borderColor: "#DCDDDE",
                        }}
                        placeholder="Enter Phone Number"
                        onChange={handlePhoneChange}
                        value={phone_number}
                        inputStyle={{
                            height: "60px",
                            width: "100%",
                            border: "none",
                        }}
                    />
                </div>
                <div className="flex justify-center py-8 mt-10">
                    <button
                        type="submit"
                        className={`fw-bold text-white bg-orange-300 rounded-lg xl:w-30 w-full mx-4 md:w-60 py-8 ${
                            isFormFilled
                                ? "bg-gradient-to-r from-red to-orange"
                                : "bg-orange-300"
                        }`}
                    >
                        Continue
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CodeVerify;
