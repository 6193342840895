import React, { useContext, useState } from "react";
import logo from "../Images/cN1g46Vw_400x400.png";
import { IoSearchOutline } from "react-icons/io5";
import { LiaTimesSolid } from "react-icons/lia";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Context } from "../Context/Context";
import { CiShoppingCart } from "react-icons/ci";
import BuyerMenu from "../BuyerMenu/BuyerMenu";

const OrderNav = () => {
  const { getTotalItems } = useContext(Context);

  const [menu, setMenu] = useState(false);

  const handleClick = () => {
    setMenu(!menu);
  };

  return (
    <div className="relative">
      <div className="xl:hidden flex items-center justify-between pt-6">
        <div className="flex mx-4">
          <div className="xl:hidden flex items-center">
            {menu ? (
              <LiaTimesSolid size={25} onClick={handleClick} />
            ) : (
              <AiOutlineMenuUnfold size={25} onClick={handleClick} />
            )}
          </div>
          <Link to="/">
            <div className="flex items-center mx-4">
              <img src={logo} alt="" className="w-12" />
              <div>
                <h2 className="inline-block text-2xl font-bold">
                  Quick<span className="text-red">Shop</span>
                </h2>
              </div>
            </div>
          </Link>
        </div>
        <Link to="/cart">
          <div className="mx-16 -mt-2 cursor-pointer">
            <div className="absolute mx-4">
              <CiShoppingCart size={30} />
            </div>
            <div className="absolute mx-[20px] -mt-[6px] flex">
              <p className="bg-red flex items-center justify-center inline-block w-4 h-4 rounded-full">
                <span className="text-xs fw-bold text-white">
                  {getTotalItems()}
                </span>
              </p>
            </div>
          </div>
        </Link>
      </div>

      <div className="xl:hidden focus-within:text-black relative flex items-center h-12 mx-4 mt-4">
        <IoSearchOutline className="absolute ml-4 pointer-events-none" />
        <input
          type="text"
          className="w-100 border-1 border-gray h-10 px-32 pl-10 rounded-md outline-none"
          placeholder="Search for any Product"
        />
      </div>

      <BuyerMenu menu={menu} />
    </div>
  );
};

export default OrderNav;
