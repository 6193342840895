import React, { useState, useEffect, useContext } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { BuyerLinks } from "../Buyer";
import { TbLogout2 } from "react-icons/tb";
import { Context } from "../Context/Context";
const BuyerNav = () => {
    const [active, setActive] = useState();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { logout } = useContext(Context);
    useEffect(() => {
        setActive(pathname.split("-")[0]);
    }, [pathname]);
    return (
        <div className=" flex md:flex-col flex-row justify-between bg-white rounded-lg mb-[20px] overflow-auto md:mb-0 md:h-[25rem]  md:gap-[32px]">
            <div className="flex md:block">
                {BuyerLinks.map((product) => (
                    <>
                        <div key={product.key} className="">
                            <div
                                className={
                                    ("md:flex-1 text-dark-gray",
                                    active === product.path
                                        ? "text-black fw-black bg-orange-100"
                                        : "text-dark-gray")
                                }
                            >
                                <NavLink to={product.path} className="md:block">
                                    <h2 className="md:py-[15px] p-4 w-full hover:bg-orange-100 hover:text-black md:text-left text-center ">
                                        <span className="inline-block mx-2">{product.icon}</span>
                                        {product.Label}
                                    </h2>
                                </NavLink>
                            </div>
                        </div>
                    </>
                ))}
            </div>
            <div className="md:p-2 md:block">
                <div className="text-dark-gray md:py-[15px] p-4">
                    <button
                        onClick={() => {
                            logout();
                            navigate("/");
                        }}
                    >
                        <span className="inline-block">
                            <TbLogout2 />
                        </span>
                        <span className="inline-block mx-2"> Logout</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BuyerNav;
