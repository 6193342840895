/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import image from "../Images/images.jpg";
// import { IoChevronForward, IoChevronBackOutline } from "react-icons/io5";
import Group36 from "../Images/Group 36.png";
import axios from "axios";

const Transaction = () => {
    const token = localStorage.getItem("authToken");
    const [id, setId] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState("Loading...");
    const [selected, setSelected] = useState(1);
    const navs = [
        { name: "All", index: 1 },
        { name: "Completed", index: 2 },
        { name: "Pending", index: 3 },
        { name: "Failed", index: 4 },
    ];
    const header = [
        "Date Processed",
        // "Product Name",
        "Cost",
        "Status",
        "Receipt",
    ];

    const getSingleTransaction = async (id) => {
        setId(id);
        const url = `https://getquickshop.online/api/v1/user/merchant/seller/transactions/${id}`;
        await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: "application/vnd.api+json",
            },
        });
        document.getElementById("my_modal_3").showModal();
    };

    useEffect(() => {
        const fetchAllTransactions = async () => {
            try {
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/merchant/seller/transactions",
                    {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                            Accept: "application/vnd.api+json",
                        },
                    }
                );
                const result = await response.json();
                if (response.ok && Array.isArray(result.data)) {
                    setTransactions(result.data);
                    setError(null);
                } else {
                    setTransactions([]);
                    setError(result.message || "Failed to fetch transactions.");
                }
            } catch (error) {
                setError("Error fetching transactions.");
                setTransactions([]);
            }
        };
        fetchAllTransactions();
    }, []);

    return (
        <div className="h-[90%] py-[48px] md:px-[100px] px-[20px]   overflow-auto">
            <h2 className=" pb-4 text-2xl fw-bold">Transaction</h2>
            <div className="tablePage md:text-base text-sm">
                <div className="flex list-none justify-between mb-[32px]">
                    <div className=" overflow-auto">
                        <ul className="flex cursor-pointer">
                            {navs.map((e) => (
                                <li
                                    className={`whitespace-nowrap p-[16px] fw-bold  ${
                                        selected === e.index
                                            ? "text-red border-b-2 border-red"
                                            : "text-dark-gray border-b-2 border-b-transparent"
                                    }`}
                                    onClick={() => setSelected(e.index)}
                                >
                                    {e.name}{" "}
                                    <span
                                        className={`inline-block text-white px-[8px] rounded-full ${
                                            selected === e.index
                                                ? "bg-red"
                                                : "bg-gray"
                                        }`}
                                    >
                                        0
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="bg-white rounded-[12px] border border-gray overflow-auto">
                    <table class="md:table-auto w-full overflow-auto">
                        <thead className="border-b-1 border-gray h-10 bg-white">
                            <tr className="border-b-gray border-b">
                                <th className="p-[13px] text-left">
                                    <input
                                        type="checkbox"
                                        className="cursor-pointer"
                                    />
                                </th>
                                {header.map((e) => (
                                    <th className="p-[13px] text-left">{e}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {error && (
                                <tr>
                                    <td
                                        colSpan="6"
                                        className="text-center text-red fw-bold p-[13px]"
                                    >
                                        {error}
                                    </td>
                                </tr>
                            )}
                            {transactions.length === 0 && !error ? (
                                <tr>
                                    <td
                                        colSpan="6"
                                        className="text-center fw-bold p-[13px]"
                                    >
                                        No transactions found.
                                    </td>
                                </tr>
                            ) : (
                                transactions.map((item) => (
                                    <tr>
                                        <td className="p-[13px]">
                                            <input
                                                type="checkbox"
                                                className="cursor-pointer"
                                            />
                                        </td>
                                        <td className="p-[13px]">
                                            {item?.attributes?.transaction_date}
                                        </td>
                                        <td className="p-[13px]">
                                            &#8358;{item?.attributes?.amount}
                                        </td>
                                        <td className="p-[13px]">
                                            {item?.attributes?.status}
                                        </td>
                                        <td className="2xl:px-6 whitespace-nowrap px-4">
                                            <div>
                                                <button
                                                    className="btn-sm border-gray border-1 rounded-md"
                                                    onClick={() =>
                                                        getSingleTransaction(
                                                            item?.id
                                                        )
                                                    }
                                                >
                                                    View
                                                </button>
                                                <dialog
                                                    id="my_modal_3"
                                                    className="modal"
                                                >
                                                    <div className="modal-box">
                                                        <form method="dialog">
                                                            <button className="btn btn-sm btn-circle btn-ghost right-2 top-2 absolute">
                                                                ✕
                                                            </button>
                                                        </form>
                                                        <div>
                                                            <div className="flex justify-center">
                                                                <img
                                                                    src={
                                                                        Group36
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="text-center">
                                                                <h2 className="pt-4 pb-2 font-medium">
                                                                    Payment
                                                                    Received
                                                                </h2>
                                                                <h5 className="pb-4 text-sm">
                                                                    Your payment
                                                                    for Order #
                                                                    {id} has
                                                                    been
                                                                    successfully{" "}
                                                                    <br />{" "}
                                                                    processed.
                                                                    The total
                                                                    amount has
                                                                    been <br />{" "}
                                                                    deposited
                                                                    into your
                                                                    account.
                                                                </h5>
                                                                <h6 className="pb-6 text-sm">
                                                                    Thank you
                                                                    for selling
                                                                    on
                                                                    Quickshop!
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </dialog>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    {/* <div className="whitespace-nowrap flex items-center float-right mx-4 mt-6">
          <div className="mx-3">
            <h2>Page 1 of 1</h2>
          </div>
          <div className="border-1 border-gray flex py-2 rounded-md">
            <div>
              <h2 className="mx-2">
                <IoChevronBackOutline />
              </h2>
            </div>
            <div>
              <h2 className="mx-2">
                <IoChevronForward />
              </h2>
            </div>
          </div>
        </div> */}
                </div>
            </div>

            <div className="receiptPage"></div>
        </div>
    );
};

export default Transaction;
