import React from 'react';
import { CgBorderBottom } from "react-icons/cg";

const OrderEmpty = ({type}) => {
  return (
    <div>
        <div className=''>
            <div className='justify-center flex'>
                <h2 className='text-[10rem] pt-[10rem]'><CgBorderBottom/></h2>
            </div>
            <div className='text-center'>
                <h2 className='text-2xl'>No {type} Orders Avaliable</h2>
            </div>

        </div>
    </div>
  )
}

export default OrderEmpty