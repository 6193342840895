/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext } from "react";
import logo from "../Images/logo.png";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../Context/Context";
import MobileNavbar from "../../resuables/Navbar";
import axios from "axios";

const Navbar = () => {
    // const [menu, setMenu] = useState(false);
    const navigate = useNavigate();
    const { loggedIn, userType, cart, token } = useContext(Context);
    const [search, setSearch] = useState("");
    const [allprod, setprod] = useState([]);
    // const [toSend, setToSend] = useState({});
    const searchProduct = useCallback(
        async (data) => {
            const url = `https://getquickshop.online/api/v1/user/buyer/store/search/anything?search=${data}`;
            if (data.length < 3) return;
            try {
                const res = await axios.get(url);
                setprod(res.data.data);
            } catch (error) {
                console.log(error.response.data);
            }
        },
        [search]
    );

    const getProductDetails = async (id, data) => {
        try {
            const res = await axios.get(
                `https://getquickshop.online/api/v1/user/buyer/product-details/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const resp = res.data.data.relationships;
            data.product.product_images = resp.product_images;
            // const businessName = resp.seller.business_name;
            navigate(
                `/product/${data.product.product_name.split(" ").join("-")}`,
                {
                    state: data,
                }
            );
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="hidden md:block">
                <div
                    className={`p-5 z-50   ${
                        loggedIn ? "bg-white shadow-md " : "fixed w-full"
                    }`}
                >
                    <nav
                        className={`${
                            loggedIn ? "" : "bg-grey"
                        } md:mx-[100px] px-[15px] flex  items-center justify-between py-4  rounded-full cursor-pointer`}
                    >
                        <div className="flex items-center">
                            <div className="flex items-center gap-[40px]">
                                <Link to="/">
                                    <img
                                        src={logo}
                                        alt=""
                                        className="w-[100px] md:w-[200px]"
                                    />
                                </Link>
                                {loggedIn ? (
                                    <div className="relative">
                                        <input
                                            type="search"
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                                searchProduct(e.target.value);
                                            }}
                                            placeholder="Search for any product"
                                            className="hidden md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px] md:w-[400px] p-4"
                                        />
                                        {allprod.length > 0 &&
                                        search.length > 0 ? (
                                            <div className="border p-4 rounded-[12px] border-gray absolute w-full bg-white mt-3 ">
                                                {allprod.map((e, i) =>
                                                    e.products.map((k) => (
                                                        <div className="flex justify-between items-center">
                                                            <div className="mb-2">
                                                                <span className="fw-bold block">
                                                                    {
                                                                        k.product_name
                                                                    }
                                                                </span>
                                                                <small className="">
                                                                    {
                                                                        e.store
                                                                            .business_name
                                                                    }
                                                                </small>
                                                            </div>
                                                            <button
                                                                className="text-red"
                                                                onClick={() => {
                                                                    getProductDetails(
                                                                        k.id,
                                                                        {
                                                                            product:
                                                                                k,
                                                                            storeId:
                                                                                e
                                                                                    .store
                                                                                    .id,
                                                                            businessName:
                                                                                e
                                                                                    .store
                                                                                    .business_name,
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                view
                                                            </button>
                                                        </div>
                                                    ))
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                        <ul
                            className="
                    md:flex gap-[25px]
                    items-center 2xl:px-10
                    md:mx:28 fw-bold
                    cursor-pointer"
                        >
                            {!loggedIn ? (
                                <>
                                    <Link to="/">
                                        <li className="hover:text-red md:mx-4 ">
                                            Home
                                        </li>
                                    </Link>
                                    <Link to="/MarketPlace">
                                        <li className="hover:text-red md:mx-4">
                                            MarketPlace
                                        </li>
                                    </Link>
                                    <Link to="/Merchant">
                                        <li className="hover:text-red md:mx-4 ">
                                            Merchant
                                        </li>
                                    </Link>
                                    <Link to="/Blog">
                                        <li className="hover:text-red md:mx-4">
                                            Blog
                                        </li>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    {/* <li>
                                        <Link
                                            to="/signup"
                                            className="hidden text-red px-6 py-5  bg-orange-100 rounded-md fw-black md:block "
                                        >
                                            Sell on Quickshop
                                        </Link>
                                    </li> */}
                                    <li>
                                        <Link
                                            to="/cart"
                                            className="rounded-md relative md:block hidden  w-[30px] md:w-fit"
                                        >
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                                                    stroke="#141516"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                                                    stroke="#141516"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                                                    stroke="#141516"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M9 8H21"
                                                    stroke="#141516"
                                                    strokeMiterlimit="10"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <span className="absolute top-[-10px] h-[20px] w-[20px] rounded-full flex items-center justify-center right-[-10px]   bg-red text-white p-5">
                                                {cart?.length || 0}
                                            </span>
                                        </Link>
                                    </li>
                                </>
                            )}

                            {loggedIn ? (
                                <Link
                                    to={
                                        !userType
                                            ? "/"
                                            : userType?.user_type === "buyer"
                                            ? "/profileBuyer"
                                            : "/dashboard"
                                    }
                                >
                                    <div className="mx-6">
                                        <button className=" px-6 py-2 bg-gradient-to-r  rounded-lg md:flex gap-2 items-center font-light ">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                                                    stroke="#292D32"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                                                    stroke="#292D32"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            Hi,
                                            <span className="capitalize">
                                                {userType?.first_name}
                                            </span>
                                        </button>
                                    </div>
                                </Link>
                            ) : (
                                <Link to="/login">
                                    <div className="mx-6">
                                        <button className="px-6 py-2 bg-gradient-to-r from-red to-orange rounded-lg text-white font-semibold ">
                                            Login
                                        </button>
                                    </div>
                                </Link>
                            )}
                        </ul>
                    </nav>
                </div>
            </div>

            <div className="md:hidden">
                <MobileNavbar />
            </div>
        </>
    );
};

export default Navbar;
