import { Link } from "react-router-dom";
import logo from "../component/Images/logo.png";
import { useContext, useState } from "react";
import { Context } from "../component/Context/Context";

const MobileNavbar = () => {
    const { loggedIn, userType, cart } = useContext(Context);
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    return (
        <nav className="md:hidden  bg-white">
            <div className={`header absolute left-0 w-full ${loggedIn ? "top-0" : "px-[20px]"}`}>
                <div
                    className={`flex items-center justify-between py-[12px] ${
                        loggedIn
                            ? "bg-white shadow-md px-[20px]"
                            : "bg-[#F5F5F5EB] rounded-[60px] px-[24px]  my-[16px]"
                    }  `}
                >
                    <div className="flex items-center justify-center gap-[16px]">
                        {loggedIn ? <ModalButton isOpen={isOpen} clickFn={toggle} /> : ""}
                        <Link to="/">
                            <img src={logo} alt="" className="w-[100px] md:w-[200px]" />
                        </Link>
                    </div>
                    {loggedIn ? (
                        <button>
                            <Link
                                to="/cart"
                                className="rounded-md relative md:block   w-[30px] md:w-fit"
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                                        stroke="#141516"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                                        stroke="#141516"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                                        stroke="#141516"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M9 8H21"
                                        stroke="#141516"
                                        strokeMiterlimit="10"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span className="absolute top-[-10px] h-[15px] w-[15px] rounded-full flex items-center justify-center text-[12px] right-[-10px]   bg-red text-white p-5">
                                    {cart.length || 0}
                                </span>
                            </Link>
                        </button>
                    ) : (
                        <ModalButton isOpen={isOpen} clickFn={toggle} />
                    )}
                </div>
            </div>

            {isOpen ? (
                <div className={"menu bg-white top-0 left-0   h-screen py-[120px]  px-[20px]"}>
                    {!loggedIn ? (
                        <ul className="flex flex-col gap-[40px] fw-bold h-full overflow-auto">
                            <Link to="/" className="hover:text-red md:mx-4 ">
                                Home
                            </Link>
                            <Link to="/MarketPlace" className="hover:text-red md:mx-4">
                                MarketPlace
                            </Link>
                            <Link to="/Merchant" className="hover:text-red md:mx-4 ">
                                Merchant
                            </Link>
                            <Link to="/Blog" className="hover:text-red md:mx-4 ">
                                Blog
                            </Link>

                            <div>
                                <Link
                                    to="/login"
                                    className="bg-gradient-to-r from-red to-orange px-[40px] py-[16px] rounded-[12px] text-white fw-bold inline-block "
                                >
                                    Login
                                </Link>
                            </div>
                        </ul>
                    ) : (
                        <div className="flex flex-col h-full gap-[40px] fw-bold overflow-y-auto">
                            <Link
                                to="/signup"
                                className=" text-red px-6 py-5  bg-orange-100 rounded-md fw-black "
                            >
                                Sell on Quickshop
                            </Link>

                            <Link
                                to={
                                    !userType
                                        ? "/"
                                        : userType?.user_type === "buyer"
                                          ? "/profileBuyer"
                                          : "/dashboard"
                                }
                            >
                                <button className=" p-[12px] shadow-sm rounded-[12px] gap-2 items-center font-bold flex ">
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                                            stroke="#292D32"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                                            stroke="#292D32"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    Hi,
                                    <span className="capitalize">{userType?.first_name}</span>
                                </button>
                            </Link>
                        </div>
                    )}
                </div>
            ) : (
                ""
            )}
        </nav>
    );
};
export default MobileNavbar;

const ModalButton = ({ isOpen, clickFn }) => {
    return (
        <>
            {isOpen ? (
                <button onClick={clickFn}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M19 5L5 19M5 5L19 19"
                            stroke="#141516"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            ) : (
                <button onClick={clickFn}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4 8.5L20 8.5"
                            stroke="#141516"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M4 15.5L20 15.5"
                            stroke="#141516"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </button>
            )}
        </>
    );
};
