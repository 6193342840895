import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { BuyerLinks } from "../Buyer";
import { TbLogout2 } from "react-icons/tb";

const BuyerMenu = ({ menu }) => {
    const [active, setActive] = useState();
    const { pathname } = useLocation();
    useEffect(() => {
        setActive(pathname.split("-")[0]);
    }, [pathname]);
    return (
        <div>
            <div
                className={`${
                    menu ? "translate-x-0" : "-translate-x-full"
                } flex  bg-white w-[250px]  rounded-lg h-screen lg:hidden  h-[100rem] flex-col absolute  text-black  top-20 pt-8 pb-4 gap-8  transition-transform duration-300 font-bold cursor-pointer z-50 `}
            >
                <div className="">
                    <div className="rounded-lg">
                        {BuyerLinks.map((product) => {
                            return (
                                <div key={product.key} className="">
                                    <div className="">
                                        <div className="flex w-[20%] my-2 ">
                                            <div
                                                className={
                                                    ("my-2 flex-1 text-dark-gray ",
                                                    active === product.path
                                                        ? "text-black bg-orange-100"
                                                        : "text-dark-gray")
                                                }
                                            >
                                                <NavLink to={product.path}>
                                                    <h2 className="py-2  w-[250px] hover:bg-orange-100 hover:text-black ">
                                                        <span className=" inline-block mx-2">
                                                            {product.icon}
                                                        </span>
                                                        {product.Label}
                                                    </h2>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div>
                    <button className="text-red whitespace-nowrap px-6 py-2 mx-4 mr-48 fw-bold bg-orange-100 rounded-md">
                        Sell on QuickShop
                    </button>
                </div>
                <div className="px-2 pt-20">
                    <h2 className="text-dark-gray">
                        <TbLogout2 className="inline-block mx-2" />
                        Logout
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default BuyerMenu;
