import React from "react";
import google from "../Images/Type=Black Background, Digital Store=GooglePlay, Size=Small 1.png";
import apple from "../Images/Type=Black Background, Digital Store=Appstore, Size=Small 1.png";
import { TiStarburst } from "react-icons/ti";
import phone from "../Images/Merchant Mockup- Homepage (1).png";

const Seller = ({ lists = [] }) => {
    const color = ["text-red", "text-purple", "text-green", "text-orange"];
    return (
        <div className=" flex md:flex-row flex-col justify-center md:gap-[100px] gap-[61px] ">
            <div className="md:w-[500px] w-full ">
                <img src={phone} alt="" className="w-full object-cover" />
            </div>
            <div className="flex flex-col gap-[25px] md:gap-[40px] justify-center md:px-0">
                {lists.map((e, i) => (
                    <div className="flex gap-[16px] items-center" key={e}>
                        <TiStarburst className={` ${color[i]}`} />
                        {e}
                    </div>
                ))}
                <div className="md:flex md:gap-[16px] gap-[10px]">
                    <a href="https://apps.apple.com/gb/app/quickshop-shop-africa/id6449415717">
                        <img src={apple} alt="" className="w-full md:w-auto" />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.app.owlet">
                        <img src={google} alt="" className="w-full md:w-auto" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Seller;
