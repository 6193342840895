import React from "react";
import logo from "../Images/cN1g46Vw_400x400.png";
import { SideNav } from "../SideNav";
import { Outlet, Link, useLocation } from "react-router-dom";

const Registered = () => {
  const location = useLocation();

  return (
    <div>
      <div>
        <Link to="/">
          <div className="flex items-center 2xl:px-10 px-2 pt-10">
            <div>
              <img src={logo} alt="" className="w-12" />
            </div>
            <div>
              <h2 className="inline-block text-2xl font-bold">
                Quick<span className="text-red">Shop</span>
              </h2>
            </div>
          </div>
        </Link>

        <div className=" ">
          <div className=" grid md:grid-cols-2 w-full grid-cols-1">
            <div>
              <div className="">
                <Outlet />
              </div>
            </div>

            <div className="bg-amber md:w-[100%] 2xl:px-16 px-4 xl:-mt-40 -mt-20 md:-mt-17 pb-21 md:pb-23 md:h-[95rem]  h-[50rem] ">
              <div className="xl:pt-25 pt-20 md:pt-11 md:pt-[23rem]">
                <h2 className="text-2xl fw-bold">
                  Hey Quick<span className="text-red">Shopper 👋</span>
                </h2>
                <p className="py-2 font-normal">
                  We need these details to set up your merchant account.
                </p>
              </div>
              <div>
                <div>
                  {SideNav.map((data) => {
                    const isActive = location.pathname === data.path;
                    // const isCompleted = completedSteps.includes(data.path);
                    return (
                      <div className="" key={data.key}>
                        <div
                          className={`rounded-xl flex items-center px-4 pt-4 pb-6 mt-6 bg-white ${
                            isActive ? "border-1 border-red" : ""
                          } `}
                        >
                          <div>
                            <div className="flex justify-center w-10 h-10 pt-9 bg-gray-100 rounded-full">
                              <h2
                                className={`text-dark-gray w-4  ${
                                  isActive ? "text-red" : ""
                                }`}
                              >
                                {data.icon}
                              </h2>
                            </div>
                          </div>

                          <div className=" 2xl:mx-4">
                            <div className="flex">
                              <h2 className="pb-2 fw-bold cursor-pointer">
                                {data.title}
                              </h2>

                              <div className="mt-1">
                                <h2
                                  className={
                                    isActive ? "text-green" : "text-gray"
                                  }
                                >
                                  {data.goCheck}
                                </h2>
                              </div>
                            </div>
                            <h2 className="text-sm">{data.description}</h2>
                          </div>
                          <div>
                            <h2 className="text-xs ">{data.level}</h2>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registered;
