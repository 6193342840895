/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { RxSlash } from "react-icons/rx";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddNewCoupon = () => {
    const initialFormData = {
        coupon_title: "",
        coupon_code: "",
        discount_type: "",
        amount: "",
        apply_coupon_to: "",
        status: "",
        valid_from: "",
        valid_to: "",
        number_of_coupons: "",
        maximum_number_of_uses_per_customer: "",
        min_cart_amount: "",
    };

    const [formData, setFormData] = useState(initialFormData);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [discount_type, setDiscountType] = useState("");
    const [couponCode, setCouponCode] = useState("");
    const [isFormComplete, setIsFormComplete] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
        setFormData({
            ...formData,
            apply_coupon_to: e.target.value,
        });
    };

    const handleProductSelect = (e) => {
        const product = e.target.value;
        if (product && !selectedProducts.includes(product)) {
            setSelectedProducts([...selectedProducts, product]);
        }
    };

    const handleDiscountTypeChange = (e) => {
        setDiscountType(e.target.value);
        setFormData({
            ...formData,
            discount_type: e.target.value,
        });
    };

    const generateCouponCode = () => {
        const code = Math.floor(1000 + Math.random() * 9000).toString();
        // setCouponCode(code);
        setFormData({
            ...formData,
            coupon_code: code,
        });
    };

    const handleStatusChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validFrom = new Date(formData.valid_from);
        const validTo = new Date(formData.valid_to);
        if (validTo <= validFrom) {
            toast.error(
                "The valid_to date must be a date after the valid_from date"
            );
            return;
        }
        const token = localStorage.getItem("authToken");
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchant/seller/coupon",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(formData),
                }
            );

            const result = await response.json();

            if (response.ok) {
                toast.success("Coupon Created successfully");
                setFormData(initialFormData);
                setSelectedOption("");
                setSelectedProducts([]);
                setDiscountType("");
                setCouponCode("");
            } else if (response.status === 422) {
                let errorMessage = Object.values(result.message)
                    .flat()
                    .join("");
                toast.error(errorMessage);
            } else {
                toast.error(result.message || "Registration failed");
            }
        } catch (error) {
            toast.error("Registration failed");
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        }
    };
    useEffect(() => {
        const checkFormComplete = () => {
            const requiredFields = [
                "coupon_title",
                "coupon_code",
                "discount_type",
                "amount",
                "apply_coupon_to",
                "status",
                "valid_from",
                "valid_to",
                "number_of_coupons",
                "maximum_number_of_uses_per_customer",
            ];

            for (let field of requiredFields) {
                if (!formData[field]) {
                    return false;
                }
            }
            return true;
        };

        setIsFormComplete(checkFormComplete());
    }, [formData]);

    return (
        <div>
            <div className="flex xl:mx-10 mx-4 pt-4">
                <div className="flex items-center">
                    <Link to="/coupons">
                        <h2 className="">Coupons</h2>
                    </Link>
                    <div>
                        <h2>
                            <RxSlash />
                        </h2>
                    </div>
                </div>
                <div className="flex items-center">
                    <div>
                        <h2 className="">Add New Coupon</h2>
                    </div>
                </div>
            </div>
            <div>
                <div className="py-4 xl:mx-10  mx-4">
                    <h2 className="text-2xl">Add Coupon</h2>
                </div>
            </div>
            <div className="xl:mx-10 mx-4 pb-[10rem]">
                <div className="bg-white xl:w-[50%] w-[100%] rounded-lg">
                    <form onSubmit={handleSubmit}>
                        <div className="mx-4 pt-2">
                            <label>Coupon Title</label>
                            <br />
                            <input
                                type="text"
                                name="coupon_title"
                                value={formData.coupon_title}
                                onChange={handleInputChange}
                                className="outline-none border-1 border-gray w-full px-2 rounded-lg py-[10px] my-2"
                                placeholder="e.g Black Friday Sales"
                            />
                        </div>
                        <div className="relative mx-4">
                            <label>Coupon Code</label>
                            <br />
                            <input
                                type="text"
                                name="coupon_code"
                                value={formData.coupon_code}
                                readOnly
                                className="outline-none border-1 border-gray px-2 w-full rounded-lg py-[10px] my-2 "
                                placeholder="Tap to generate a code"
                            />
                            <button
                                type="button"
                                onClick={generateCouponCode}
                                className="absolute right-0 top-[45%] mx-4 bg-[#F9F4F2] text-red p-[4px] rounded-md"
                            >
                                Generate
                            </button>
                        </div>
                        <div className="">
                            <label className="mx-4">Discount Type</label>
                            <div className="flex ">
                                <div className="flex items-center border-1 border-gray rounded-lg py-[10px] my-2 w-full mx-4">
                                    <input
                                        type="radio"
                                        name="discount_type"
                                        value="percentage"
                                        checked={
                                            formData.discount_type ===
                                            "percentage"
                                        }
                                        onChange={handleDiscountTypeChange}
                                        className="radio radio-error radio-sm mx-2"
                                    />
                                    <h2>Percentage</h2>
                                </div>
                                <div className="flex items-center border-1 border-gray rounded-lg py-[10px] my-2 mr-4 w-full">
                                    <input
                                        type="radio"
                                        name="discount_type"
                                        value="fixed"
                                        checked={
                                            formData.discount_type === "fixed"
                                        }
                                        onChange={handleDiscountTypeChange}
                                        className="radio radio-error radio-sm mx-2"
                                    />
                                    <h2>Fixed</h2>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <label className="mx-4">Amount</label>
                            <div className="flex border-1 border-gray rounded-lg mx-4 my-2">
                                <div className="px-4 flex items-center">
                                    <h2>
                                        {discount_type === "fixed"
                                            ? "₦"
                                            : discount_type === "percentage"
                                            ? "%"
                                            : ""}
                                    </h2>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={handleInputChange}
                                        className="border-l-1 py-[10px] px-2 border-gray outline-none "
                                        placeholder="Enter Amount"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="my-2">
                            <div className="px-4 w-full">
                                <label className="">Apply Coupon To</label>
                                <br />
                                <select
                                    name="apply_coupon_to"
                                    value={formData.apply_coupon_to}
                                    onChange={handleSelectChange}
                                    className="outline-none border-1 border-gray py-2 my-2 rounded-lg select w-full max-w-lg"
                                >
                                    <option value="">Select an option</option>
                                    <option value="all">All Products</option>
                                    <option value="specific_product">
                                        Specific Products
                                    </option>
                                    <option value="min_cart_amount">
                                        Minimum Cart Amount
                                    </option>
                                </select>
                            </div>
                        </div>
                        {selectedOption === "specific_product" && (
                            <div className="px-4 w-full ">
                                <select
                                    className=" outline-none border-1 border-gray py-2 rounded-lg select w-full max-w-lg my-2 "
                                    onChange={handleProductSelect}
                                >
                                    <option>Select Products</option>
                                    <option>The Inyene Heels</option>
                                    <option>The Sola Flats</option>
                                    <option>The Ezinne Heels</option>
                                </select>
                                <div className="grid grid-cols-3 gap-2 ">
                                    {selectedProducts.map((product, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center bg-[#E9F4FF] text-sm rounded-lg px-2 py-1"
                                        >
                                            <span>{product}</span>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    setSelectedProducts(
                                                        selectedProducts.filter(
                                                            (item) =>
                                                                item !== product
                                                        )
                                                    )
                                                }
                                                className="ml-2 focus:outline-none"
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {selectedOption === "min_cart_amount" && (
                            <div className="my-2">
                                <label className="mx-4">
                                    Minimum Cart Amount
                                </label>
                                <div className="flex border-1 border-gray rounded-lg mx-4 my-2">
                                    <div className="px-4 flex items-center">
                                        <h2>&#8358;</h2>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="min_cart_amount"
                                            onChange={handleInputChange}
                                            className="border-l-1 py-[10px]  px-2 border-gray outline-none"
                                            placeholder="Enter Amount"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="mx-4">
                            <label>Valid From</label>
                            <br />
                            <input
                                type="date"
                                name="valid_from"
                                value={formData.valid_from}
                                onChange={handleInputChange}
                                className="outline-none border-1 border-gray px-2 rounded-lg py-[10px] my-2 w-full"
                            />
                        </div>
                        <div className="mx-4">
                            <label>Valid To</label>
                            <br />
                            <input
                                type="date"
                                name="valid_to"
                                value={formData.valid_to}
                                onChange={handleInputChange}
                                className="outline-none border-1 border-gray px-2 rounded-lg py-[10px] my-2 w-full"
                            />
                        </div>
                        <div className="mx-4">
                            <label>Number of Coupons</label>
                            <br />
                            <input
                                type="text"
                                name="number_of_coupons"
                                value={formData.number_of_coupons}
                                onChange={handleInputChange}
                                className="outline-none border-1 border-gray w-full px-2 rounded-lg py-[10px] my-2"
                                placeholder="Enter Number"
                            />
                        </div>
                        <div className="mx-4">
                            <label>Max Uses per Customer</label>
                            <br />
                            <input
                                type="text"
                                name="maximum_number_of_uses_per_customer"
                                value={
                                    formData.maximum_number_of_uses_per_customer
                                }
                                onChange={handleInputChange}
                                className="outline-none border-1 border-gray w-full px-2 rounded-lg py-[10px] my-2"
                                placeholder="Enter Number"
                            />
                        </div>
                        <div className="">
                            <label className="mx-4">Coupon Status</label>
                            <div className="flex ">
                                <div className="flex items-center border-1 border-gray rounded-lg py-[10px] w-full mx-4 my-2">
                                    <input
                                        type="radio"
                                        name="status"
                                        value="active"
                                        checked={formData.status === "active"}
                                        onChange={handleStatusChange}
                                        className="radio radio-error radio-sm mx-2"
                                    />
                                    <h2>Active</h2>
                                </div>
                                <div className="flex items-center border-1 border-gray rounded-lg py-[10px] mr-4 w-full my-2">
                                    <input
                                        type="radio"
                                        name="status"
                                        value="inactive"
                                        checked={formData.status === "inactive"}
                                        onChange={handleStatusChange}
                                        className="radio radio-error radio-sm mx-2"
                                    />
                                    <h2>InActive</h2>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center py-6 mx-4 pb-[10rem]">
                            <button
                                type="submit"
                                disabled={!isFormComplete}
                                className={`bg-orange-300 w-full text-white px-4 py-2 rounded-lg ${
                                    isFormComplete
                                        ? "bg-gradient-to-r from-red to-orange"
                                        : "bg-orange-300 cursor-not-allowed"
                                }`}
                            >
                                Create Coupon
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddNewCoupon;
