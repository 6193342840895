/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { IoChevronForward } from "react-icons/io5";
import { IoChevronBackOutline } from "react-icons/io5";

const AddCoupons = () => {
    const [coupons, setCoupons] = useState([]);
    const header = [
        "Coupon Title",
        "Code",
        "Amount",
        "Valid From",
        "Valid To",
        "Status",
        "Details",
    ];
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("Loading coupons...");
    const token = localStorage.getItem("authToken");
    const navigate = useNavigate();
    // const [pages, setPages] = useState();

    useEffect(() => {
        const fetchCoupons = async (page = 1) => {
            setLoading(true); // Set loading to true when fetching starts
            setMessage("");
            try {
                const response = await fetch(
                    `https://getquickshop.online/api/v1/user/merchant/seller/all/coupon?page=${page}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const data = await response.json();

                if (Array.isArray(data)) {
                    setCoupons(data);
                } else if (data.data && Array.isArray(data.data)) {
                    setCoupons(data.data);
                } else {
                    console.error("Unexpected response format:", data);
                    setMessage("Unexpected response format");
                    setCoupons([]);
                }
            } catch (error) {
                console.error("Error fetching coupons:", error);
                setMessage("Error fetching coupons");
                setCoupons([]);
            } finally {
                setLoading(false);
            }
        };

        fetchCoupons();
    }, [token]);

    const handleEditClick = (couponId) => {
        localStorage.setItem("editCouponId", couponId);
        navigate("/coupons-edit");
    };

    const filteredCoupons = coupons.filter(
        (coupon) =>
            (coupon.coupon_title &&
                coupon.coupon_title
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())) ||
            (coupon.coupon_code &&
                coupon.coupon_code
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()))
    );

    return (
        <div className="md:px-[100px] px-[20px] py-[40px] text-sm md:text-base">
            <h2 className="text-2xl mb-[20px] md:mb-[40px] ">Coupons</h2>
            <div className="bg-white w-full rounded-lg pb-[50rem] xl:mb-[20rem]">
                <div className=" py-[12px] flex md:flex-row flex-col md:gap-[10px] md:justify-between md:items-center ">
                    <div className="flex md:flex-row flex-col md:justify-between">
                        <div className="focus-within:text-black relative flex items-center">
                            {/* <IoSearchOutline className="absolute ml-4 pointer-events-none" /> */}
                            <input
                                type="text"
                                className="md:block  font-light w-full   border-gray border outline-none focus:outline-none bg-transparent rounded-[12px] p-4"
                                placeholder="Search for a Coupon"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                        <div className="dropdown dropdown-bottom whitespace-nowrap p-4">
                            <div
                                tabIndex={0}
                                role="button"
                                className="border-1 border-gray rounded-xl flex items-center  px-2 py-2 m-1"
                            >
                                Active Coupons{" "}
                                <span className="px-2">
                                    <IoIosArrowDown />
                                </span>
                            </div>
                            <ul
                                tabIndex={0}
                                className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                            >
                                <li>
                                    <a href="#">Active Coupons</a>
                                </li>
                                <li>
                                    <a href="#">Disabled Coupons</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Link
                        to="/coupons-addNew"
                        className="md:block font-light  border-gray border outline-none focus:outline-none bg-red rounded-[12px] w-fu  p-4 text-white text-center"
                    >
                        Add New Coupons
                    </Link>
                </div>

                {loading && (
                    <div className="flex items-center justify-center h-full">
                        <p>Loading...</p>
                    </div>
                )}

                {!loading && message && (
                    <div className="flex items-center justify-center h-full">
                        <p>{message}</p>
                    </div>
                )}

                {!loading && filteredCoupons.length === 0 && (
                    <div className="flex items-center justify-center h-full">
                        <p>No coupons available</p>
                    </div>
                )}
                {!loading && filteredCoupons.length > 0 && (
                    <div>
                        <div className=" rounded-[12px] border border-gray overflow-auto">
                            <table className="w-full md:table-auto overflow-auto">
                                <thead className="">
                                    <tr className="border-b-1 border-gray w-full">
                                        <th className="p-[13px] text-left">
                                            <input type="checkbox" />
                                        </th>
                                        {header.map((e) => (
                                            <th className="p-[13px] text-left">
                                                {e}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    {filteredCoupons.map((coupon) => (
                                        <tr
                                            key={coupon.id}
                                            className="border-b-1 border-gray w-full"
                                        >
                                            <td className="px-[13px]">
                                                <input
                                                    type="checkbox"
                                                    className=""
                                                />
                                            </td>
                                            <td className="p-[13px]">
                                                {coupon.coupon_title}
                                            </td>
                                            <td className="p-[13px]">
                                                {coupon.coupon_code}
                                            </td>
                                            <td className="p-[13px]">
                                                {coupon.amount}
                                            </td>
                                            <td className="p-[13px]">
                                                {new Date(
                                                    coupon.valid_from
                                                ).toLocaleDateString()}
                                            </td>
                                            <td className="p-[13px]">
                                                {new Date(
                                                    coupon.valid_to
                                                ).toLocaleDateString()}
                                            </td>
                                            <td className="p-[13px]">
                                                <button className="bg-[#EBF8ED] text-[#2F9E40] rounded-xl px-6 py-2">
                                                    {coupon.status}
                                                </button>
                                            </td>
                                            <td className="p-[13px]">
                                                <button
                                                    className="border-1 border-gray rounded-xl px-6 py-2 fw-bold"
                                                    onClick={() =>
                                                        handleEditClick(
                                                            coupon.id
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="xl:mx-10 flex items-center float-right mx-4 mt-6">
                                <div className="mx-3">
                                    <h2>Page 1 of 1</h2>
                                </div>
                                <div className="border-1 border-gray flex py-2 rounded-md">
                                    <div>
                                        <h2 className="mx-2">
                                            <IoChevronBackOutline />
                                        </h2>
                                    </div>
                                    <div>
                                        <h2 className="mx-2">
                                            <IoChevronForward />
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddCoupons;
