/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../Header/Header";
import { Outlet, useNavigate } from "react-router-dom";
import "../App.css";
import { toast } from "react-toastify";
import { Context } from "../Context/Context";

const DashBoardPage = () => {
    const navigate = useNavigate();
    const [isSideBarOpen, setIsSideBarOpen] = useState(false);
    const token = localStorage.getItem("authToken");
    const { userType: user } = useContext(Context);
    if (!token && !user) {
        toast.warn("unauthenticated");
        navigate("/");
        return;
    }

    if (user && user.user_type === "buyer") {
        navigate("/");
        return;
    }

    return (
        <div className={`${isSideBarOpen ? "backdrop-blur" : ""}`}>
            <section>
                <SideBar
                    isOpen={isSideBarOpen}
                    toggleSidebar={() => setIsSideBarOpen(false)}
                >
                    <Outlet />
                </SideBar>
            </section>
        </div>
    );
};

export default DashBoardPage;
