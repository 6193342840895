export default function CartCard({
    item,
    cartItems,
    handleIncrement,
    handleDecrement,
    removeFromCart,
}) {
    return (
        <>
            <div className="hidden md:grid md:grid-cols-3 gap-[10px] md:py-[10px] py-[5px]">
                <div className="flex items-center md:gap-[20px] ">
                    <div className="md:w-[80px] md:h-[80px] w-[60px] h-[60px] md:rounded-[20px] rounded-[4px] overflow-hidden">
                        <img
                            src={item?.product_image?.images}
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="md:fw-bold capitalize">
                        {item.product_name}
                    </div>
                </div>

                <div className="flex items-center justify-center ">
                    <div className="border border-gray rounded-[12px] p-[15px] grid grid-cols-3 text-center w-[150px] fw-bold">
                        <span
                            role="button"
                            type="button"
                            onClick={handleDecrement}
                        >
                            -
                        </span>
                        <span>{cartItems.quantity}</span>
                        <span
                            role="button"
                            type="button"
                            className="text-red"
                            onClick={handleIncrement}
                        >
                            +
                        </span>
                    </div>
                </div>

                <div className=" flex justify-end gap-[60px] items-center ">
                    <span className="fw-bold">₦{cartItems.total_amount} </span>
                    <button onClick={removeFromCart}>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M16.25 4.58398L15.7336 12.9382C15.6016 15.0727 15.5356 16.1399 15.0006 16.9072C14.7361 17.2866 14.3955 17.6067 14.0006 17.8473C13.2018 18.334 12.1325 18.334 9.99395 18.334C7.8526 18.334 6.78192 18.334 5.98254 17.8464C5.58733 17.6054 5.24666 17.2847 4.98224 16.9047C4.4474 16.1361 4.38288 15.0674 4.25384 12.9299L3.75 4.58398"
                                stroke="#646566"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                            <path
                                d="M2.5 4.58366H17.5M13.3798 4.58366L12.8109 3.4101C12.433 2.63054 12.244 2.24076 11.9181 1.99766C11.8458 1.94374 11.7692 1.89578 11.6892 1.85424C11.3283 1.66699 10.8951 1.66699 10.0288 1.66699C9.14069 1.66699 8.69665 1.66699 8.32974 1.86209C8.24842 1.90533 8.17082 1.95524 8.09774 2.0113C7.76803 2.26424 7.58386 2.66829 7.2155 3.47637L6.71077 4.58366"
                                stroke="#646566"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                            <path
                                d="M7.9165 13.75L7.9165 8.75"
                                stroke="#646566"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                            <path
                                d="M12.0835 13.75L12.0835 8.75"
                                stroke="#646566"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                            />
                        </svg>
                    </button>
                    {/* <HiMiniTrash className="cursor-pointer"  /> */}
                </div>
            </div>

            <div
                className="md:hidden flex mb-2 gap-[5px] text-[13px]"
                key={item.id}
            >
                <div className=" w-[60px] h-[60px] shrink-0  rounded-[4px] overflow-hidden">
                    <img
                        src={item?.product_image?.images}
                        alt=""
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="flex-1">
                    <div className="flex justify-between mb-2">
                        <span className="fw-bold capitalize ">
                            {item.product_name}
                        </span>
                        <span className="fw-bold">
                            ₦{cartItems.total_amount}
                        </span>
                    </div>

                    <div className="flex justify-between items-center">
                        <div className="border border-gray rounded-[12px] p-[8px] grid grid-cols-3 text-center w-[96px] fw-bold">
                            <span
                                role="button"
                                type="button"
                                onClick={handleDecrement}
                            >
                                -
                            </span>
                            <span>{cartItems.quantity}</span>
                            <span
                                role="button"
                                type="button"
                                className="text-red"
                                onClick={handleIncrement}
                            >
                                +
                            </span>
                        </div>

                        <button onClick={removeFromCart}>
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M16.25 4.58398L15.7336 12.9382C15.6016 15.0727 15.5356 16.1399 15.0006 16.9072C14.7361 17.2866 14.3955 17.6067 14.0006 17.8473C13.2018 18.334 12.1325 18.334 9.99395 18.334C7.8526 18.334 6.78192 18.334 5.98254 17.8464C5.58733 17.6054 5.24666 17.2847 4.98224 16.9047C4.4474 16.1361 4.38288 15.0674 4.25384 12.9299L3.75 4.58398"
                                    stroke="#646566"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M2.5 4.58366H17.5M13.3798 4.58366L12.8109 3.4101C12.433 2.63054 12.244 2.24076 11.9181 1.99766C11.8458 1.94374 11.7692 1.89578 11.6892 1.85424C11.3283 1.66699 10.8951 1.66699 10.0288 1.66699C9.14069 1.66699 8.69665 1.66699 8.32974 1.86209C8.24842 1.90533 8.17082 1.95524 8.09774 2.0113C7.76803 2.26424 7.58386 2.66829 7.2155 3.47637L6.71077 4.58366"
                                    stroke="#646566"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M7.9165 13.75L7.9165 8.75"
                                    stroke="#646566"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                                <path
                                    d="M12.0835 13.75L12.0835 8.75"
                                    stroke="#646566"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
