import React from "react";
import banner from "../Images/QS-Banner-July-1 (1).jpg";
import shoe from "../Images/Frame 1000001214 (1).png";
import gifted from "../Images/bags.png";
import Product from "../Product/Product";
import Subscribe from "../Subscribe/Subscribe";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { useState, useEffect } from "react";
import Navbar from "../Navbar/Navbar";

const MarketPlace = () => {
    const [categories, setCategories] = useState([]);

    const getCategories = async () => {
        const category = sessionStorage.getItem("category");
        if (category) {
            setCategories(JSON.parse(category));
            return;
        }

        try {
            const response = await fetch(
                `https://getquickshop.online/api/v1/admin/categories`
            );
            const data = await response.json();
            sessionStorage.setItem("category", JSON.stringify(data.data));
            setCategories(data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getCategories();
        return () => getCategories();
    }, []);

    return (
        <>
            <Navbar />
            <div className="justify-evenly px-[20px] md:px-[100px]  grid grid-cols-2 md:grid-cols-3 md:gap-[24px] gap-[15px] md:pt-[100px] pt-[120px] ">
                <div className="md:col-span-2">
                    <img
                        src={banner}
                        alt=""
                        className="w-full object-cover md:h-full h-[80px] rounded-[20px] md:rounded-[40px]"
                    />
                </div>
                <div className=" shrink-0">
                    <img
                        src={shoe}
                        alt=""
                        className="w-full object-cover md:h-full h-[80px] rounded-[20px] md:rounded-[40px]"
                    />
                </div>
            </div>

            <div className="md:pt-[100px] px-[20px] pt-[48px] md:px-[100px]">
                <h2 className="text-xl md:mb-[40px] mb-[20px] fw-bold">
                    All Categories
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-4 md:gap-[40px] gap-[15px]">
                    {categories.length > 0
                        ? categories?.map((category, index) => (
                              <Link
                                  to={`/allProduct?cat=${category?.cat_name
                                      .replace(/ /g, "-")
                                      .replace(/&/g, "and")}`}
                                  key={category?.cat_name}
                                  className=""
                              >
                                  <img
                                      src={category?.cat_image || ""}
                                      className=""
                                      alt=""
                                  />
                                  <h2 className="md:fw-bold text-[14px] text-base pt-2">
                                      {category?.cat_name}
                                  </h2>
                              </Link>
                          ))
                        : ""}
                </div>
            </div>

            <Product type="Featured" />

            <div className="bg-gradient-to-r from-wineRed to-darkRed h-60 mt-[6rem] 2xl:mx-20 md:mx-4 overflow-hidden hidden md:flex">
                <div className=" flex justify-between">
                    <div className="2xl:px-10 w-full px-4 py-10">
                        <h2 className="xl:text-3xl md:text-xl fw-bold text-white">
                            20% discount <br />
                            on first three orders
                        </h2>
                        <Link to="/AllProduct">
                            <button className="rounded-xl fw-bold px-6 py-2 mt-4 text-white bg-orange-200">
                                Shop now{" "}
                                <span>
                                    <IoIosArrowForward className="inline-block" />
                                </span>
                            </button>
                        </Link>
                    </div>
                    <div>
                        <img
                            src={gifted}
                            alt=""
                            className="-mt-[5rem] ml-[7rem]"
                        />
                    </div>
                </div>
            </div>

            <Product type="More" className="xl:flex hidden" />
            <Subscribe />
            <Footer />
        </>
    );
};
export default MarketPlace;
