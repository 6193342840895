import { LuLayoutDashboard } from "react-icons/lu";
import { BiStoreAlt } from "react-icons/bi";
import { IoBagOutline } from "react-icons/io5";
import { AiOutlineInbox } from "react-icons/ai";
import { IoSettingsOutline } from "react-icons/io5";
import { RiSettings6Line } from "react-icons/ri";

   export const DashBoardLinks =[
    {
        key:'dashboard',
        Label:'Dashboard',
        path:'/dashboard',
        icon:<LuLayoutDashboard/>

    },
    {
        key:'products',
        Label:'Products',
        path:'/products',
        icon:<BiStoreAlt/>

    },
    {
        key:'orders',
        Label:'Orders',
        path:'/orders',
        icon: <IoBagOutline/>

    },
    {
        key:'transaction',
        Label:'Transaction',
        path:'/transaction',
        icon:<AiOutlineInbox/>

    },
    {
        key:'coupons',
        Label:'Coupons',
        path:'/coupons',
        icon:<RiSettings6Line/>

    },
    {
        key:'settings',
        Label:'Settings',
        path:'/settings',
        icon: <IoSettingsOutline/>

    },
]