/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

import OrderRow from "../OrderRow";
import { useState, useEffect } from "react";

const Orders = () => {
    const token = localStorage.getItem("authToken");
    const tabs = ["Pending Orders", "Completed Orders"];
    const [pendingOrder, setPendingOrder] = useState([]);
    const [completedOrder, setCompletedOrder] = useState([]);
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const getCompletedOrder = async () => {
        const response = await fetch(
            "https://getquickshop.online/api/v1/user/merchant/ord/completed",
            {
                method: "GET",
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.json();
        setCompletedOrder(data?.data || []);
    };

    const getPendingOrder = async () => {
        const response = await fetch(
            "https://getquickshop.online/api/v1/user/merchant/ord/pending",
            {
                method: "GET",
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.json();
        setPendingOrder(data?.data || []);
    };

    useEffect(() => {
        getPendingOrder();
        getCompletedOrder();
        // return () => {
        //     getPendingOrder();
        //     getCompletedOrder();
        // };
    }, []);

    const header = [
        "Order ID",
        // "Product Name",
        "Cost",
        // "Quantity",
        "Shipping Method",
        "Order Date",
        "Status",
        "Details",
    ];

    return (
        <div className="h-[95rem]">
            <div>
                <h2 className="py-[48px] px-[40px] pb-4 text-2xl fw-bold">
                    Orders
                </h2>
                <div className="flex list-none md:mx-10 justify-between mb-[32px]">
                    <div className=" overflow-auto">
                        <ul className="flex cursor-pointer">
                            {tabs.map((e, i) => (
                                <li
                                    className={`whitespace-nowrap p-[16px] fw-bold  ${
                                        activeTab === e
                                            ? "text-red border-b-2 border-red"
                                            : "text-dark-gray border-b-2 border-b-transparent"
                                    }`}
                                    onClick={() => handleTabClick(e)}
                                >
                                    {e}{" "}
                                    <span
                                        className={`inline-block text-white px-[8px] rounded-full ${
                                            activeTab === e
                                                ? "bg-red"
                                                : "bg-gray"
                                        }`}
                                    >
                                        <span>
                                            {i === 0 && pendingOrder.length}
                                        </span>

                                        <span>
                                            {i === 1 && completedOrder.length}
                                        </span>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                <div className="bg-white md:mx-10 rounded-[12px] border border-gray overflow-hidden">
                    <table className="w-full md:table-auto overflow-auto border-collapse">
                        <thead className="border-b-1 border-gray h-10 bg-white">
                            <tr>
                                {header.map((e) => (
                                    <th className="p-[13px] text-left">{e}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="font-normal text-sm leading-[18.9px] text-[#141516]">
                            {/* Completed Orders */}
                            {activeTab === "Completed Orders" &&
                                completedOrder.map((order) => {
                                    return (
                                        <OrderRow
                                            key={order.id}
                                            orderID={order.id}
                                            cost={order.attributes.total}
                                            shippingMethod={
                                                order.attributes.shipping_method
                                            }
                                            orderDate={new Date(
                                                order.attributes.created_at
                                            ).toLocaleDateString("en-GB")}
                                            status={
                                                order.attributes.order_status
                                            }
                                        />
                                    );
                                })}

                            {activeTab === "Pending Orders" &&
                                pendingOrder.map((order) => {
                                    return (
                                        <OrderRow
                                            key={order.id}
                                            orderID={order.id}
                                            cost={order.attributes.total}
                                            shippingMethod={
                                                order.attributes.checkout_method
                                            }
                                            orderDate={new Date(
                                                order.attributes.created_at
                                            ).toLocaleDateString("en-GB")}
                                            status={order.attributes.status}
                                        />
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Orders;
