/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useContext } from "react";
import ProductHeader from "../ProductHeader/ProductHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RxSlash } from "react-icons/rx";
import "react-phone-input-2/lib/style.css";
import Footer from "../Footer/Footer";
import { isLoggedIn } from "../../utils/auth";
import AddressCard from "../AddressCard";
import CartMenu from "../CartMenu/CartMenu";
import useCountry from "../../hooks/useCountry";
import ProductSummary from "../ProductSummary";
import getAddress from "../../api/getAddress";
import createDeliveryOrder from "../../api/createDeliveryOrder";
import getUserId from "../../api/getUserId";
import createPickupOrder from "../../api/createPickupOrder";
import { useCheckout } from "../../CheckoutContext";
import Navbar from "../Navbar/Navbar";
import { Context } from "../Context/Context";
import getCartSummary from "../../api/getCartSummary";
import { toast } from "react-toastify";
import axios from "axios";

// Debounce function for address input
function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

const CheckOut = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
        setCustomerName,
        setEmail,
        setPhoneNumber,
        setTotalPrice,
        customerName,
        email,
        phoneNumber,
        totalPrice,
        selectedOption,
        setSelectedOption,
        usedAddress,
        setUsedAddress,
        setItems,
    } = useCheckout();
    const { userType: user } = useContext(Context);
    const token = localStorage.getItem("authToken");
    const [address, setAddress] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const { states, message, getCitites, cities } = useCountry();
    const [shippingOptions, setShippingOptions] = useState([]);
    const [deliveryPrice, setDeliveryPrice] = useState("");
    const [redisKey, setRedisKey] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [checkOutItem, setCheckoutItem] = useState([]);
    const [pickupAddress, setPickupAddress] = useState("");
    const [isDelivery, setIsDelivery] = useState(false);
    const [shippingType, setShippingType] = useState("");
    const [deliveryOption, setDeliveryOption] = useState("");
    const [availableAddress, setAvailableAddress] = useState([]);
    const [loading, setLoading] = useState(false);
    const [existingAdress, setExistingAddress] = useState(true);
    const [selectedAddress, setSelectedAddress] = useState();
    const [optShipping, setOptShipping] = useState({});
    const { items, merchant_id } = location.state || {
        items: [],
        merchant_id: "",
    };
    const subTotal = items.reduce(
        (acc, currentItem) =>
            acc + currentItem.selling_price * currentItem.quantity_sold,
        0
    );
    const tax = 0; // (subTotal * 0.05).toFixed(2);
    const handleAddress = (e) => setAddress(e.target.value);
    const handlePostal = (e) => setPostalCode(e.target.value);
    const debouncedHandleAddressChange = debounce(handleAddress, 2000); //Delay for 2 seconds
    const debouncedHandlePostalCode = debounce(handlePostal, 2000);

    const handleOption = (e) => {
        setDeliveryOption(e.target.value);
        setSelectedOption(e.target.value);
    };

    const changeChosen = () => setExistingAddress(!existingAdress);

    const addAddress = async () => {
        if (existingAdress) return;
        const formData = new FormData();
        formData.append(
            "contact_name",
            user?.first_name + " " + user?.last_name
        );
        formData.append("contact_number", user?.phone_number);
        formData.append("location", address);
        formData.append("city", selectedCity);
        formData.append("state", selectedState);
        formData.append("postcode", postalCode);
        formData.append("country_code", "+234");
        formData.append("country_name", "Nigeria");
        const resp = {
            contact_name: customerName,
            contact_number: phoneNumber,
            city: selectedCity,
            state: selectedState,
            location: address,
            postcode: postalCode,
            country_code: "+234",
            country_name: "Nigeria",
        };

        setSelectedAddress(() => resp);
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/buyer/address",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                }
            );

            const data = await response.json();
            return data;
        } catch (error) {
            console.log(error);
        }
    };

    setTotalPrice(Number(subTotal) + Number(deliveryPrice) + Number(tax));

    const getShippingOptions = async (merchant_id) => {
        const payload = {
            type: "local",
            toAddress: {
                name: user?.first_name + " " + user?.last_name,
                email: user?.email,
                address: usedAddress || "lagos",
                phone: user?.phone_number,
            },
            parcels: {
                width: 32.5,
                length: 32.5,
                height: 32.5,
                weight: "2",
            },
            items: items.map((item) => {
                return {
                    name: item.product_name,
                    description: item.product_description,
                    weight: "22",
                    category: item.category_id,
                    amount: item.selling_price,
                    quantity: item.quantity_sold,
                };
            }),
        };
        try {
            const resp = await axios.post(
                `https://getquickshop.online/api/v1/user/buyer/shipping/${merchant_id}`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = resp.data.data;
            const quickShop = data?.quickshop_partner;
            const handleShipping = data?.handle_shipping;

            if (handleShipping?.length > 0) setShippingOptions(handleShipping);
            if (quickShop?.rates) {
                setShippingOptions(quickShop.rates);
                setRedisKey(quickShop?.redis_key);
                setShippingType("quickshop_partner");
            }
            setIsDelivery(true);
        } catch (error) {
            setIsDelivery(false);
            toast.error(error.response.data.message);
        }
    };

    const addressGet = async () => {
        try {
            const resp = await axios.get(
                "https://getquickshop.online/api/v1/user/buyer/address",
                {
                    headers: {
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setAvailableAddress(resp.data.data);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmitOrder = async () => {
        if (!selectedOption) {
            toast.warn("select a delivery option");
            return;
        }

        setLoading(true);
        if (selectedOption === "pickup") {
            try {
                const pickupOrderData = {
                    token: token,
                    merchant_id,
                    subtotal: subTotal.toFixed(2),
                    total: totalPrice,
                    items: checkOutItem,
                };
                const response = await axios.post(
                    "https://getquickshop.online/api/v1/user/buyer/orders-bypickup",
                    pickupOrderData,
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                const resp = response.data;
                localStorage.setItem("order_id", resp.data.order.id);
                localStorage.setItem("order_amount", resp.data.order.total);
                setItems(items);
                navigate("/buyerPayment");
            } catch (error) {
                toast.warn("Failed to create pickup order:");
            }
        }

        if (selectedOption === "delivery" && shippingOptions) {
            if (!shippingOptions) {
                toast.warn("You have not choosen a shipping opton");
                return;
            }
            await addAddress();
            if (Object.keys(selectedAddress).length < 1) {
                toast.info("Choose or add a delivery address");
            }

            let deliveryOrderData = {
                token: token,
                merchantId: merchant_id,
                rateId: optShipping.id,
                shippingInformation: {
                    name: user.first_name + " " + user.last_name,
                    mobile_number: user.phone_number,
                    customer_shipping_address: selectedAddress.location,
                },
                shippingMethod: optShipping.type,
                estimatedDeliveryDate: optShipping.estimated_days,
                subtotal: subTotal,
                shippingFee: optShipping.amount,
                total: totalPrice,
                shippingAddress: {
                    street: selectedAddress.location,
                    city: selectedAddress.city,
                    state: selectedAddress.state,
                    zip_code: selectedAddress.postcode,
                },
                items: checkOutItem,
            };

            if (shippingType === "quickshop_partner")
                deliveryOrderData["redisKey"] = redisKey;
            try {
                const resp = await createDeliveryOrder(deliveryOrderData);
                localStorage.setItem("order_id", resp.data.order.id);
                localStorage.setItem("order_amount", resp.data.order.total);
                setItems(items);
                navigate("/buyerPayment");
            } catch (error) {
                console.error("Failed to create delivery order:", error);
            }
        }
    };

    const getSummary = async () => {
        const cart = [];
        items.map((item) =>
            cart.push({
                id: item.product_id,
                quantity: item.quantity_sold,
            })
        );

        const url = `https://getquickshop.online/api/v1/user/buyer/get-cart-summary/${merchant_id}`;
        try {
            const resp = await axios.post(
                url,
                { cart: cart },
                {
                    headers: {
                        Accept: "application/vnd.api+json",
                        "Content-Type": "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = resp.data.data;
            setCustomerName(data.buyer.first_name + " " + data.buyer.last_name);
            setPhoneNumber(data.buyer.phone_number);
            setEmail(data.buyer.email);
            setPickupAddress(data.merchant_address);
            const checkoutItem = data.cart_items;
            const arr = [];
            for (let item of checkoutItem) {
                const dt = {
                    product_name: item.attributes.product_name,
                    quantity: item.attributes.quantity,
                };
                arr.push(dt);
            }
            setCheckoutItem(arr);
        } catch (error) {
            console.error(error.response.data);
        }
    };

    const shippingOptionSelection = (option) => setOptShipping(option);

    useEffect(() => {
        addressGet();
        getShippingOptions(merchant_id);
        getSummary();
        return () => {
            getSummary();
            getShippingOptions(merchant_id);
            addressGet();
        };
    }, []);

    return (
        <>
            <Navbar />
            <div className="bg-grey h-fit pb-[10rem]  py-[100px] md:py-[20px] md:px-[100px]">
                <div className="px-[20px]">
                    <div className=" flex">
                        <Link to="/">
                            <h2>Home</h2>
                        </Link>
                        <div className="flex items-center">
                            <span className="inline-block">
                                <RxSlash />
                            </span>
                            <div className="text-red inline-block">
                                <Link to="/Cart">Cart</Link>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="inline-block">
                                <RxSlash />
                            </span>
                            <div className="text-red inline-block">
                                <Link to="/Cart">Checkout</Link>
                            </div>
                        </div>
                    </div>
                    <h2 className="py-10 text-xl fw-bold">CheckOut</h2>
                </div>

                <div className="md:grid md:grid-cols-3 gap-[32px] flex flex-col">
                    <div className="col-span-2 p-[20px] md:p-[12px]">
                        <div className="bg-white  w-full rounded-lg">
                            {deliveryOption === "delivery" && (
                                <>
                                    <div className="border-b-1 border-grey md:flex-row flex items-center justify-between px-4 py-2 text-xl">
                                        <div className="">
                                            <h2>Your Information</h2>
                                        </div>
                                        {!isLoggedIn && (
                                            <div className="text-center">
                                                <h2>
                                                    Already have an account?
                                                    <Link to="/login">
                                                        <span className="text-red fw-bold">
                                                            {" "}
                                                            Log in
                                                        </span>
                                                    </Link>
                                                </h2>
                                            </div>
                                        )}
                                    </div>
                                    {existingAdress ? (
                                        <div className="addressesChoose px-4 py-2 flex flex-col gap-3">
                                            <p className="fw-bold">
                                                Choose Address
                                            </p>
                                            {availableAddress.length > 0
                                                ? availableAddress.map(
                                                      (e, i) => (
                                                          <label
                                                              role="button"
                                                              className="w-full rounded-[12px] border  border-gray  p-4 flex gap-3 items-center"
                                                          >
                                                              <input
                                                                  type="radio"
                                                                  name="address"
                                                                  value={
                                                                      e
                                                                          ?.attributes
                                                                          ?.location
                                                                  }
                                                                  onChange={() => {
                                                                      setSelectedAddress(
                                                                          e.attributes
                                                                      );
                                                                  }}
                                                              />
                                                              {
                                                                  e?.attributes
                                                                      ?.location
                                                              }
                                                          </label>
                                                      )
                                                  )
                                                : "No address found"}
                                        </div>
                                    ) : (
                                        <form>
                                            <div className="w-full px-4 py-2">
                                                <label
                                                    htmlFor=""
                                                    className="fw-bold"
                                                >
                                                    Address
                                                </label>
                                                <br />
                                                <input
                                                    type="text"
                                                    className=" md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                                                    placeholder="Your Address"
                                                    onChange={(event) =>
                                                        debouncedHandleAddressChange(
                                                            event
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="flex flex-col md:flex-row">
                                                <div className="w-full px-4 py-2">
                                                    <label
                                                        htmlFor=""
                                                        className="fw-bold"
                                                    >
                                                        State
                                                    </label>
                                                    <br />
                                                    <select
                                                        className="md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                                                        onChange={(e) => {
                                                            getCitites(
                                                                e.target.value
                                                            );
                                                            setSelectedState(
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <option
                                                            defaultValue
                                                            selected
                                                        >
                                                            Select a State
                                                        </option>
                                                        {states?.length > 0 ? (
                                                            states.map((st) => (
                                                                <option
                                                                    value={
                                                                        st.name
                                                                    }
                                                                >
                                                                    {st.name}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <>{message}</>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="w-full px-4 py-2">
                                                    <label
                                                        htmlFor=""
                                                        className="fw-bold"
                                                    >
                                                        City
                                                    </label>
                                                    <br />
                                                    <select
                                                        onChange={(e) =>
                                                            setSelectedCity(
                                                                e.target.value
                                                            )
                                                        }
                                                        className=" md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                                                    >
                                                        <option
                                                            disabled
                                                            defaultValue
                                                        >
                                                            Select a City
                                                        </option>
                                                        {cities?.length > 0 ? (
                                                            cities.map((st) => (
                                                                <option
                                                                    value={st}
                                                                >
                                                                    {st}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <>{message}</>
                                                        )}
                                                    </select>
                                                </div>

                                                <div className="w-full px-4 py-2">
                                                    <label
                                                        htmlFor=""
                                                        className="font-medium"
                                                    >
                                                        Postal Code
                                                    </label>
                                                    <br />
                                                    <input
                                                        type="text"
                                                        className="md:block w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                                                        placeholder="Postal Code"
                                                        onChange={(e) => {
                                                            debouncedHandlePostalCode(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    )}

                                    <div className="p-4">
                                        <button
                                            type="button"
                                            className="bg-gradient-to-r from-red to-orange p-[10px]  fw-bold text-white rounded-[12px] ml-auto block my-2 mr-2 "
                                            onClick={changeChosen}
                                        >
                                            {existingAdress
                                                ? " Add New Address"
                                                : "Choose from existing"}
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="bg-white  rounded-lg  mt-5 ">
                            <div className="p-2 w-full">
                                <div className="border-b-1 border-grey px-4 py-2">
                                    <h2 className="fw-bold">
                                        Shipping Details
                                    </h2>
                                </div>
                                <div className="border-b-1 border-grey px-4 pt-6 pb-2 mb-[12px]">
                                    <h2 className="md:fw-bold md:text-base text-sm ">
                                        Shipping Method
                                    </h2>
                                </div>
                                <div className="flex gap-[12px] flex-col md:flex-row  mx-4 pb-[12px]">
                                    <div className="border-1 border-gray  flex items-center p-8  rounded-lg">
                                        <input
                                            type="radio"
                                            name="orderType"
                                            value="delivery"
                                            className="radio radio-error radio-sm mx-2"
                                            onChange={handleOption}
                                            disabled={!isDelivery}
                                        />
                                        <h2 className="">Delivery</h2>
                                    </div>
                                    <div className="border-1 border-gray  flex items-center p-8  rounded-lg">
                                        <input
                                            type="radio"
                                            name="orderType"
                                            value="pickup"
                                            className="radio radio-error radio-sm mx-2 "
                                            onChange={(e) => {
                                                setDeliveryPrice(0);
                                                handleOption(e);
                                            }}
                                        />
                                        <h2>Pickup</h2>
                                    </div>
                                </div>

                                {deliveryOption === "pickup" ? (
                                    <div className="flex flex-col gap-[8px]">
                                        <h2 className="font-medium text-[14px] text-black leading-[18.9px]">
                                            Pickup Address
                                        </h2>
                                        <AddressCard address={pickupAddress} />
                                    </div>
                                ) : (
                                    ""
                                )}

                                {deliveryOption === "delivery" ? (
                                    <div className="flex flex-col gap-[8px]">
                                        <h2 className="font-medium text-[14px] text-black leading-[18.9px]">
                                            Delivery Options
                                        </h2>
                                        {shippingOptions.map((option) => {
                                            return (
                                                <div className="border-1 border-gray 2xl:pr-4 flex justify-between items-center py-8 pl-3 pr-10 rounded-lg">
                                                    <div className="flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="deliveryOption"
                                                            value={option.type}
                                                            className="radio radio-error w-[20px] h-[20px] radio-sm mx-2"
                                                            onChange={() => {
                                                                setDeliveryPrice(
                                                                    option.amount
                                                                );
                                                                shippingOptionSelection(
                                                                    option
                                                                );
                                                            }}
                                                        />
                                                        <h2 className="font-normal text-[14px] leading-[18.9px]">
                                                            {option.type}
                                                            <span>
                                                                (
                                                                {
                                                                    option.estimated_days
                                                                }
                                                                )
                                                            </span>
                                                        </h2>
                                                    </div>
                                                    <h2 className="font-normal text-[14px] leading-[18.9px]">
                                                        ₦{option.amount}
                                                    </h2>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="px-[20px] md:px-0">
                        <div className="bg-white w-full rounded-[8px] md:p-[20px] p-[12px] border border-[#EBEDEF] text-[#3C3D3E]">
                            <h2 className="fw-bold">Order Summary</h2>
                            {items.map((item, index) => (
                                <ProductSummary
                                    key={index}
                                    productName={item.product_name}
                                    productPrice={item.selling_price}
                                    quantity={item.quantity_sold}
                                    productImage={item.product_images[0].images}
                                />
                            ))}

                            <div className="border-b-1 border-gray py-[28px] flex flex-col  md:gap-[20px] gap-[12px]">
                                <div className="flex justify-between ">
                                    <h2>Subtotal</h2>
                                    <h2 className="fw-bold">
                                        &#8358;{subTotal}
                                    </h2>
                                </div>

                                {selectedOption === "delivery" && (
                                    <div className="flex justify-between ">
                                        <h2>Delivery</h2>
                                        <h2 className="fw-bold">
                                            &#8358;{deliveryPrice}
                                        </h2>
                                    </div>
                                )}

                                <div className="flex justify-between ">
                                    <h2>Total</h2>
                                    <span className="fw-bold">
                                        &#8358;
                                        {totalPrice.toFixed(2)}
                                    </span>
                                </div>

                                <div className=" flex justify-center">
                                    {!isLoggedIn && (
                                        <button className="bg-gradient-to-r from-red to-orange w-full p-[17px]  fw-bold text-white rounded-[12px]">
                                            <Link to="/signUp">
                                                Proceed to Payment
                                            </Link>
                                        </button>
                                    )}

                                    {isLoggedIn && (
                                        <button
                                            className={` w-full p-[17px]  fw-bold text-white rounded-[12px] ${
                                                loading
                                                    ? "bg-amber"
                                                    : "bg-gradient-to-r from-red to-orange"
                                            }`}
                                            onClick={handleSubmitOrder}
                                            disabled={loading}
                                        >
                                            {!loading
                                                ? "Proceed to Payment"
                                                : "Creating order"}
                                        </button>
                                    )}
                                </div>
                            </div>

                            <Link to="/cart">
                                <div className="text-center text-red">
                                    <h2>Return to Cart</h2>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CheckOut;
