/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from "react";
import logo from "../Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import { Context } from "../Context/Context";

const SignUp = () => {
    const navigate = useNavigate();
    const { loggedIn } = useContext(Context);
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [type, setType] = useState("password");
    const [icon, setIcon] = useState("Show");
    const [phoneNumbers, setPhoneNumbers] = useState(["+1234567890"]);
    const [loading, setLoading] = useState(false);
    const [existingEmails, setExistingEmails] = useState([
        "existing@example.com",
    ]);

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        date_of_birth: "",
        phone_number: "",
        country_code: "",
        country_name: "",
        termsAccepted: false,
    });

    useEffect(() => {
        const isFilled = Object.entries(formData).every(([key, value]) => {
            if (typeof value === "boolean") {
                return value;
            } else {
                return value.trim() !== "";
            }
        });
        setIsFormFilled(isFilled);
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handlePhoneChange = (value, country) => {
        setFormData((prevState) => ({
            ...prevState,
            phone_number: "+" + value,
            country_code: country.dialCode,
            country_name: country.name,
        }));
    };

    const handleToggle = () => {
        if (type === "password") {
            setIcon("Hide");
            setType("text");
        } else {
            setIcon("Show");
            setType("password");
        }
    };

    const validate = () => {
        let proceed = true;
        const check = formData.phone_number.split("+234");
        let errorMessage = "Please enter the value in ";

        if (check[1].length !== 10) {
            proceed = false;
            toast.warn("invalid phone number format");
            return;
        }

        if (formData.first_name.trim() === "") {
            proceed = false;
            errorMessage += "Your First Name ";
        }
        if (formData.last_name.trim() === "") {
            proceed = false;
            errorMessage += "Your Last Name ";
        }
        if (formData.email.trim() === "") {
            proceed = false;
            errorMessage += "Your Email Address ";
        } else if (existingEmails.includes(formData.email)) {
            proceed = false;
            errorMessage += "Email already exists ,";
        }
        if (formData.password.trim() === "") {
            proceed = false;
            errorMessage += "Password ";
        } else if (formData.password.length < 6) {
            proceed = false;
            errorMessage += "Password must be at least 6 characters ";
        }
        if (formData.date_of_birth.trim() === "") {
            proceed = false;
            errorMessage += "Your Date of Birth ";
        } else if (new Date(formData.date_of_birth) >= new Date("2014-06-24")) {
            proceed = false;
            errorMessage +=
                " Your Date of Birth must be before 2014 to register ,";
        }
        if (
            formData.phone_number === "" ||
            !formData.phone_number.startsWith("+" + formData.country_code)
        ) {
            proceed = false;
            toast.error("Phone number must include the country code");
        } else if (phoneNumbers.includes(formData.phone_number)) {
            proceed = false;
            errorMessage += " phone number already exists ,";
        }

        if (!formData.termsAccepted) {
            proceed = false;
            errorMessage += "Accept the terms and conditions  ,";
        }

        if (!proceed) {
            toast.warning(errorMessage);
        } else {
            if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
            ) {
                proceed = false;
                toast.warning("Please enter a valid email address");
            }
        }

        return proceed;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!isFormFilled) {
            setLoading(false);
            return;
        }
        const data = { ...formData };

        if (validate()) {
            try {
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/register",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                        },
                        body: JSON.stringify(data),
                    }
                );

                const result = await response.json();
                if (response.ok) {
                    localStorage.clear();
                    localStorage.setItem("authToken", result.data.access_token);
                    localStorage.setItem(
                        "firstName",
                        result.data.user.first_name
                    );
                    toast.success(
                        "Registered successfully: A verification code has been sent to your number"
                    );

                    setExistingEmails([...existingEmails, formData.email]);
                    setPhoneNumbers([...phoneNumbers, formData.phone_number]);
                    navigate("/verify");
                } else if (response.status === 422) {
                    let errorMessage = Object.values(result.message)
                        .flat()
                        .join("");
                    if (
                        errorMessage ===
                        "This email address is already registered."
                    ) {
                        navigate("/login");
                    }
                    toast.error(errorMessage);
                } else {
                    toast.error(result.message || "Registration failed");
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast.error("Registration failed");
            }
        }
        setLoading(false);
    };

    return (
        <div>
            <div className="px-[20px] md:px-[100px] flex items-center justify-between py-[20px]">
                <Link to="/">
                    <img src={logo} alt="" className="md:w-[200px] w-[100px]" />
                </Link>

                {loggedIn ? (
                    ""
                ) : (
                    <div>
                        <Link
                            to="/Login"
                            className="px-[23px] py-[11px] md:px-[37px] md:py-[11px] bg-[#FFF0EA] rounded-[12px] text-[#F90013] fw-bold block"
                        >
                            Login
                        </Link>
                    </div>
                )}
            </div>

            <div className="py-[120px] px-[20px] md:px-0 flex flex-col items-center mx-auto">
                <h2 className="mb-[60px] text-[24px] fw-bold">
                    Let's get you Started 👋{" "}
                </h2>
                <form onSubmit={handleSubmit} className="md:w-[400px]">
                    <div className="flex flex-col gap-[28px]">
                        <div className="flex justify-between gap-[20px]">
                            <div className="w-full">
                                <label
                                    htmlFor="First Name"
                                    className="block mb-3 fw-bold"
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                    placeholder=" Your First Name"
                                    className="w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="w-full">
                                <label
                                    htmlFor="Last Name"
                                    className="block mb-3 fw-bold"
                                >
                                    Your Last Name
                                </label>
                                <input
                                    type="text"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                    placeholder=" Your Last Name"
                                    className="w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div>
                            <label
                                htmlFor="Email Address"
                                className="block mb-3 fw-bold"
                            >
                                Email Address
                            </label>
                            <input
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder=" Your Email Address"
                                className="w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                                autoComplete="off"
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="Email Address"
                                className="block mb-3 fw-bold"
                            >
                                Phone Number
                            </label>

                            <PhoneInput
                                country={"ng"}
                                containerStyle={{
                                    height: "60px",
                                    width: "100%",
                                    border: "1px solid red",
                                    overflow: "hidden",
                                    borderRadius: "12px",
                                    borderColor: "#DCDDDE",
                                }}
                                placeholder="Enter Phone Number"
                                onChange={handlePhoneChange}
                                value={formData.phone_number}
                                inputStyle={{
                                    height: "60px",
                                    width: "100%",
                                    border: "none",
                                }}
                            />
                        </div>

                        <div>
                            <label
                                htmlFor="date_of_birth"
                                className="block mb-3 fw-bold"
                            >
                                Date of Birth
                            </label>
                            <input
                                type="date"
                                name="date_of_birth"
                                value={formData.date_of_birth}
                                onChange={handleChange}
                                placeholder="Date of Birth"
                                className="w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                                autoComplete="off"
                            />
                        </div>

                        <div className="relative">
                            <label
                                htmlFor="Password"
                                className="block mb-3 fw-bold"
                            >
                                Password
                            </label>

                            <input
                                type={type}
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="minimum of 8 characters"
                                className="w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                                autoComplete="off"
                            />
                            <div className="mr-14 flex float-right -mt-10">
                                <span
                                    onClick={handleToggle}
                                    className="text-red absolute underline"
                                >
                                    {icon}
                                </span>
                            </div>
                        </div>

                        <div className="">
                            <label
                                htmlFor="check cursor-pointer"
                                className="flex gap-[10px] text-[14px]"
                            >
                                <input
                                    type="checkbox"
                                    id="check"
                                    checked={formData.termsAccepted}
                                    onChange={(e) =>
                                        setFormData((prevState) => ({
                                            ...prevState,
                                            termsAccepted: e.target.checked,
                                        }))
                                    }
                                />
                                <div className="block">
                                    I have read,understand and agree to
                                    QuickShop's <br />
                                    <a
                                        href="#"
                                        className="text-red underline"
                                        target="_blank"
                                    >
                                        terms & condition
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href="#"
                                        className="text-red underline"
                                        target="_blank"
                                    >
                                        privacy policy
                                    </a>
                                </div>
                            </label>
                        </div>
                        <button
                            className={`fw-bold  w-full p-4 rounded-[12px] text-white ${
                                isFormFilled
                                    ? "bg-gradient-to-r from-red to-orange"
                                    : "bg-orange-300"
                            }`}
                            disabled={!isFormFilled || loading}
                        >
                            {loading ? "Processing..." : "Continue"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SignUp;
