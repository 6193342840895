import { Link } from "react-router-dom";

export default function OrderRow({
    orderID,
    productName,
    cost,
    quantity,
    shippingMethod,
    orderDate,
    status,
    details,
}) {
    return (
        <tr>
            <th className="p-[13px] border-b border-gray">{orderID}</th>
            {/* <td className="p-[13px]">{productName}</td> */}
            <td className="p-[13px] border-b border-gray">{cost}</td>
            {/* <td className="p-[13px] border border-gray">{quantity}</td> */}
            <td className="p-[13px] border-b border-gray">{shippingMethod}</td>
            <td className="p-[13px] border-b border-gray">{orderDate}</td>
            <td className="p-[13px] border-b border-gray">{status}</td>
            <td className="p-[13px] border-b border-gray">
                <Link
                    to={`/orders-view/${orderID}`}
                    style={{
                        display: "inline-block",
                        padding: "10px 20px",
                        color: "#fff",
                        backgroundColor: "#007bff",
                        border: "none",
                        borderRadius: "4px",
                        textDecoration: "none",
                        textAlign: "center",
                    }}
                >
                    View
                </Link>
            </td>
        </tr>
    );
}
