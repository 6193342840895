import { toast } from "react-toastify";

async function createDeliveryOrder({
    merchantId,
    rateId,
    redisKey,
    shippingInformation,
    shippingMethod,
    estimatedDeliveryDate,
    subtotal,
    shippingFee,
    total,
    shippingAddress,
    items,
    token,
}) {
    const payload = {
        merchant_id: merchantId,
        rate_id: rateId,
        redis_key: redisKey,
        shipping_information: shippingInformation,
        shipping_method: shippingMethod,
        estimated_delivery_date: estimatedDeliveryDate,
        subtotal,
        shipping_fee: shippingFee,
        total,
        shipping_address: shippingAddress,
        items,
    };

    try {
        const response = await fetch(
            "https://getquickshop.online/api/v1/user/buyer/orders",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            }
        );

        const data = await response.json();
        toast.success("Delivery order created");
        return data;
    } catch (error) {
        toast.error("Failed to create delivery order");
        console.error("Error creating order:", error);
        throw error;
    }
}

export default createDeliveryOrder;
