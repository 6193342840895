import React from "react";
import Home from "./component/Home/Home";
import MarketPlace from "./component/MarketPlace/MarketPlace";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Merchant from "./component/Merchant/Merchant";
import Blog from "./component/Blog/Blog";
import Contact from "./component/Contact.jsx/Contact";
import AllProduct from "./component/AllProduct/AllProduct";
import ProductView from "./component/ProductView/ProductView";
import SignUp from "./component/SignUp/SignUp";
import Verify from "./component/Verify/Verify";
import Business from "./component/Business/Business";
import NonRegistered from "./component/NonRegistered/NonRegistered";
import Login from "./component/Login/Login";
import Forgot from "./component/Forgot/Forgot";
import CodeVerify from "./component/CodeVerify/CodeVerify";
// import Password from "./component/Password/Password";
import Registered from "./component/Registered/Registered";
import UserType from "./component/UserType/UserType";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UploadRegister from "./component/UploadRegister/UploadRegister";
import OrderView from "./component/OrderView/OrderView";
import AddToProduct from "./component/AddToProduct/AddToProduct.jsx";
import Orders from "./component/Orders/Orders";
import Settings from "./component/Settings/Settings";
import Transaction from "./component/Transaction/Transaction";
import StoreInfoRegister from "./component/StoreInfoRegister/StoreInfoRegister";
import PaymentRegister from "./component/PaymentRegister/PaymentRegister";
import PaymentNonReg from "./component/PaymentNonReg/PaymentNonReg";
import UploadNonReg from "./component/UploadNonReg/UploadNonReg";
import StoreInfoNonReg from "./component/StoreInfoNonReg/StoreInfoNonReg";
import Cart from "./component/Cart/Cart";
import CheckOut from "./component/CheckOut/CheckOut";
import ProductDashBoard from "./component/ProductDashBoard/ProductDashBoard";
import WishList from "./component/WishList/WishList";
import BuyerBoard from "./component/BuyerBoard/BuyerBoard";
import ProfileBuyer from "./component/ProfileBuyer/ProfileBuyer";
import Pending from "./component/Pending/Pending";
// import CompletedOrders from "./component/CompletedOrders/CompletedOrders";
import OrderBuy from "./component/OrderBuy/OrderBuy.jsx";
import AccountCreated from "./component/AccountCreated/AccountCreated.jsx";
import AccountCreated2 from "./component/AccountCreated2/AccountCreated2.jsx";
// import Coupons from "./component/Coupons/Coupons.jsx";
import AddCoupons from "./component/AddCoupons/AddCoupons.jsx";
import AddNewCoupon from "./component/AddNewCoupon/AddNewCoupon.jsx";
import EditCoupon from "./component/EditCoupon/EditCoupon.jsx";
import DashBoardPage from "./component/DashBoardPage/DashBoardPage.jsx";
import DashBoard from "./component/DashBoard/DashBoard.jsx";
import OrderNav from "./component/OrderNav/OrderNav.jsx";
import BuyerPayment from "./component/BuyerPayment/BuyerPayment.jsx";
import PaymentSuccess from "./component/PaymentSuccess/PaymentSuccess.jsx";
import OrderEmpty from "./component/OrderEmpty/OrderEmpty.jsx";
import MerchantStore from "./component/MerchantStore/MerchantStore.jsx";
import EditProduct from "./component/AddToProduct/EditProduct.jsx";
import EditPayment from "./component/EditPayment/EditPayment.jsx";

function App() {
    return (
        <div>
            <ToastContainer
                position="top-center"
                hideProgressBar={true}
                closeOnClick
                className="fw-bold"
            />
            {/* <div className="loading text-2xl text-center absolute pointer-events-none ">Loading...</div> */}

            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/marketPlace" element={<MarketPlace />} />
                    <Route path="/merchant" element={<Merchant />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/allProduct" element={<AllProduct />} />
                    <Route path="/productView" element={<ProductView />} />
                    <Route path="/product/:id?" element={<ProductView />} />
                    <Route path="/signUp" element={<SignUp />} />
                    <Route path="/business" element={<Business />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot" element={<Forgot />} />
                    <Route path="/codeVerify" element={<CodeVerify />} />
                    <Route path="/verify" element={<Verify />} />
                    {/* <Route path="/password" element={<Password />} /> */}
                    <Route path="/userType" element={<UserType />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/checkOut" element={<CheckOut />} />
                    <Route
                        path="/accountCreated"
                        element={<AccountCreated />}
                    />
                    <Route
                        path="/accountCreated2"
                        element={<AccountCreated2 />}
                    />
                    <Route path="/orderNav" element={<OrderNav />} />
                    <Route path="/buyerPayment" element={<BuyerPayment />} />
                    <Route
                        path="/paymentSuccess"
                        element={<PaymentSuccess />}
                    />
                    <Route path="/orderEmpty" element={<OrderEmpty />} />
                    <Route
                        path="/merchant-store/:storeId"
                        element={<MerchantStore />}
                    />

                    <Route element={<Registered />}>
                        <Route
                            path="/storeInfo"
                            element={<StoreInfoRegister />}
                        />
                        <Route
                            path="/uploadInfo"
                            element={<UploadRegister />}
                        />
                        <Route
                            path="/paymentInfo"
                            element={<PaymentRegister />}
                        />
                    </Route>
                    <Route element={<NonRegistered />}>
                        <Route
                            path="/storeInfo2"
                            element={<StoreInfoNonReg />}
                        />
                        <Route path="/uploadInfo2" element={<UploadNonReg />} />
                        <Route
                            path="/paymentInfo2"
                            element={<PaymentNonReg />}
                        />
                    </Route>

                    <Route element={<DashBoardPage />}>
                        <Route path="/dashboard" element={<DashBoard />} />
                        <Route
                            path="/products"
                            element={<ProductDashBoard />}
                        />
                        <Route
                            path="/products-addToProduct"
                            element={<AddToProduct />}
                        />
                        <Route
                            path="/update/product"
                            element={<EditProduct />}
                        />
                        <Route path="/orders" element={<Orders />} />
                        <Route
                            path="/orders-view/:id"
                            element={<OrderView />}
                        />
                        <Route path="/transaction" element={<Transaction />} />
                        <Route path="/coupons" element={<AddCoupons />} />
                        {/* <Route path="/coupons-add" element={<AddCoupons />} /> */}
                        <Route
                            path="/coupons-addNew"
                            element={<AddNewCoupon />}
                        />
                        <Route path="/coupons-edit" element={<EditCoupon />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/payment" element={<EditPayment />} />
                    </Route>

                    <Route element={<BuyerBoard />}>
                        <Route path="/orderBuy" element={<OrderBuy />} />
                        <Route
                            path="/order-details/:id"
                            element={<Pending />}
                        />
                        {/* <Route
                            path="/orderBuy-complete"
                            element={<CompletedOrders />}
                        /> */}
                        <Route
                            path="/profileBuyer"
                            element={<ProfileBuyer />}
                        />
                        <Route path="/wishList" element={<WishList />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
