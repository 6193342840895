import React, { useContext, useState } from "react";
import hero from "../Images/Merchant Image on Home Screen (1).png";
import hero2 from "../Images/Buyer Hero - Homepage.png";
import Subscribe from "../Subscribe/Subscribe";
import Footer from "../Footer/Footer";
import Marquee from "react-fast-marquee";
import { GiStarShuriken } from "react-icons/gi";
import Navbar from "../Navbar/Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Seller from "../Seller/Seller";
import { Context } from "../Context/Context";

const Home = () => {
    const listSeller = [
        "Sell variety of products",
        "Embedded delivery options",
        "Access to customers",
        "Manage your store on your mobile app",
    ];
    const listBuyer = [
        "Buy variety of products",
        "Access to in-built delivery options.",
        "Affordable and diverse product options",
        "Manage your orders on your mobile appp",
    ];

    const { loggedIn } = useContext(Context);
    const [act, setAct] = useState(false);
    const handleToggle = (type = false) => {
        setAct(type);
    };

    return (
        <>
            <>
                <Navbar />
                <div
                    className={`bg-amber ${
                        loggedIn
                            ? "md:h-[800px] min-h-screen"
                            : "md:h-screen min-h-screen"
                    }  flex  flex-col md:flex-row md:px-[100px] px-[20px] gap-[40px] pt-[120px] md:pt-0  md:pb-[57px] pd:mb-0`}
                >
                    <div className="flex-1 shrink-0  md:flex flex-col md:gap-[28px] justify-center">
                        <h2 className="md:text-[58px] md:fw-bold text-[40px] fw-bold leading-[40px] md:leading-normal">
                            Make
                            <span className="text-red">
                                {" "}
                                extra money <br />
                            </span>{" "}
                            on QuickShop
                        </h2>
                        <p className="my-[20px] md:my-0 text-[14px] md:text-base">
                            Sell your products globally with the click of a
                            button.
                        </p>
                        <div className="flex items-center fw-bold gap-[20px]">
                            <Link
                                to={loggedIn ? "/AllProduct" : "/signup"}
                                className="md:px-[37px] md:py-[19px] px-[12px] py-[16px] text-[14px] md:text-base  rounded-[12px] bg-gradient-to-r from-red to-orange text-white"
                            >
                                {loggedIn ? "Go to Products" : "Get Started"}
                            </Link>
                            <Link
                                to="/Contact"
                                className="md:px-[37px] md:py-[19px] px-[12px] py-[16px] text-[14px] md:text-base rounded-[12px] text-red bg-orange-100"
                            >
                                Contact Sales
                            </Link>
                        </div>
                    </div>

                    <div className="flex-1 shrink-0 md:h-full flex items-center">
                        <div className="md:h-[60%]  w-full md:rounded-[40px] rounded-[20px] overflow-hidden">
                            <img
                                src={hero}
                                alt=""
                                className="w-full object-cover h-full"
                            />
                        </div>
                    </div>
                </div>

                <div className="bg-white md:h-[800px] flex-col md:flex-row flex md:px-[100px] px-[20px] gap-[40px] pt-[120px] md:pt-0  pb-[57px] pd:mb-0">
                    <div className="flex-1 shrink-0  flex flex-col gap-[28px] justify-center">
                        <h2 className="md:text-[58px] md:fw-bold text-[40px] fw-bold leading-[40px] md:leading-normal">
                            Need Something?
                            <span className="block">
                                we've{" "}
                                <span className="text-red">got it all</span>
                            </span>
                        </h2>
                        <p>
                            QuickShop connects you to business and brands <br />{" "}
                            Across Africa.
                        </p>
                        <div className="flex items-center fw-bold gap-[20px]">
                            <Link
                                to="/AllProduct"
                                className="md:px-[37px]  md:py-[19px] px-[12px] py-[16px] text-[14px] md:text-base   rounded-[12px] text-red bg-orange-100"
                            >
                                Start Shopping &rarr;
                            </Link>
                        </div>
                    </div>

                    <div className="flex-1 shrink-0 h-full flex items-center">
                        <div className="lg:h-[70%]  md:h-[50%] w-full rounded-[20px] md:rounded-[40px] overflow-hidden">
                            <img
                                src={hero2}
                                alt=""
                                className="w-full object-cover h-full"
                            />
                        </div>
                    </div>
                </div>

                <div className="">
                    <Marquee
                        autoFill={true}
                        direction="left"
                        className="bg-orange-50"
                    >
                        <ul className="flex gap-[41px]">
                            {Array(3)
                                .fill()
                                .map(() =>
                                    [
                                        "Shoes",
                                        "bags",
                                        "short",
                                        "dresses",
                                        "shirts",
                                    ].map((e) => (
                                        <li className="flex items-center py-[10px] gap-[5px] px-[10px]">
                                            <span className="uppercase">
                                                {e}
                                            </span>
                                            <GiStarShuriken className="text-red " />
                                        </li>
                                    ))
                                )}
                        </ul>
                    </Marquee>
                </div>

                <div className="md:py-[160px] py-[100px] px-[20px] md:px-0">
                    <h2 className="fw-bold text-center md:text-[32px] text-[24px]">
                        What you get with QuickShop
                    </h2>
                    <h6 className="text-center">
                        Quickshop is the trusted destination for merchants
                        seeking wider audiences{" "}
                        <span className="block">
                            and shoppers seeking a seamless and secure shopping
                            experience.
                        </span>
                    </h6>
                    <div className="flex justify-center md:py-[80px] py-[40px]">
                        <div className="border-gray border-1 p-[4px] rounded-[12px] flex gap-[18px] w-[270px]">
                            <button
                                onClick={() => handleToggle()}
                                className={`md:py-[17px] py-[9px]  flex-1 shrink-0 rounded-[8px]  fw-bold text-black ${
                                    !act ? "bg-red text-white" : ""
                                }`}
                            >
                                For Merchant
                            </button>
                            <button
                                onClick={() => handleToggle(true)}
                                className={`md:py-[17px] py-[9px] flex-1 shrink-0 rounded-[8px]  fw-bold text-black ${
                                    act ? "bg-red text-white" : ""
                                }`}
                            >
                                For Buyers
                            </button>
                        </div>
                    </div>
                    <Seller lists={!act ? listSeller : listBuyer} />
                </div>

                <div className="md:py-[80px] py-[40px] px-[20px]  md:px-0">
                    <h2 className="fw-bold text-center md:text-[32px] text-[24px]">
                        What our people are saying.
                    </h2>
                    <h6 className="text-center">
                        Over 1000 shoppers and merchants trust QuickShop. Join
                        this{" "}
                        <span className="block">
                            community of forward thinking people.
                        </span>
                    </h6>
                    <div className="md:py-[80px] p-[20px]"></div>
                    <Swipers />
                </div>

                <Subscribe />
                <Footer />
            </>
        </>
    );
};

export default Home;

const testimonials = [
    {
        name: "TheScentMafia",
        testimony:
            "I really enjoyed the seamless experience from onboarding to the delivery of my goods to my customers",
    },
    {
        name: "Closet Assistant",
        testimony:
            "My customer base and revenue has increased significantly since I joined Quickshop.",
    },
    {
        name: "Jerseys Hub",
        testimony:
            "Quickshop helped my overcome the logistics nightmare I used to face as a business owner",
    },
    {
        name: "Skin Concierge",
        testimony: "It’s been a great experience so far with Quickshop",
    },
];

const Swipers = ({ classes }) => (
    <Swiper
        spaceBetween={30}
        pagination={{
            clickable: true,
            loop: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
    >
        {testimonials.map((e) => (
            <SwiperSlide key={e.name}>
                <div
                    className={
                        "border-1  border-[#9747FF] relative border-solid md:p-[56px] p-[20px] flex flex-col md:gap-[28px] gap-[15px] md:w-[520px] w-full mx-auto md:rounded-[16px] rounded-[8px]"
                    }
                >
                    <div
                        className={`${
                            classes ||
                            "w-[30px] h-[30px] md:w-[100px] md:h-[100px] overflow-hidden rounded-full mx-auto"
                        }`}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            fill="gray"
                        >
                            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z" />
                        </svg>
                    </div>
                    <h5 className="md:fw-bold text-center text-[15px] md:text-base">
                        {e.testimony}
                    </h5>
                    <h6 className="py-4 font-thin text-center text-[15px] md:text-base fw-bold">
                        {e.name}
                    </h6>
                </div>
            </SwiperSlide>
        ))}
    </Swiper>
);
