import React from "react";
import { CiShoppingCart } from "react-icons/ci";
import { Link } from "react-router-dom";

const EmptyCart = () => {
    return (
        <div>
            <div className="flex md:justify-center">
                <h2 className="fw-bold md:text-[52px] text-[30px]">
                    <CiShoppingCart />
                </h2>
            </div>
            <div className="md:text-center">
                <h3 className="md:text-xl text-lg fw-bold">
                    Your Cart Is Empty
                </h3>
                <p className="pt-2">
                    Browse our All Products and discover our best products!
                </p>
                <Link to="/allProduct">
                    <button className=" bg-gradient-to-r from-red to-orange py-4 md:px-[70px] px-[30px] md:mt-4 mt-2 rounded-lg text-white md:fw-bold ">
                        Start Shopping
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default EmptyCart;
