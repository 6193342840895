/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context } from "../Context/Context";

const PaymentNonReg = () => {
    const navigate = useNavigate();
    const [isFormValid, setIsFormValid] = useState("");
    const [bank_name, setBankName] = useState("");
    const [bank_code, setBankCode] = useState("");
    const [account_number, setAccountNumber] = useState("");
    const [bvn, setBvn] = useState("");
    const [banks, setBanks] = useState([]);
    const [accountName, setAccountName] = useState("");
    const [isAccountNameFetched, setIsAccountNameFetched] = useState(false);
    const { userType: user } = useContext(Context);
    const merchant_id = user?.merchant_id?.id || "";

    const valid = useCallback(() => {
        return (
            bank_name.trim() !== "" &&
            account_number.trim() !== "" &&
            bvn.trim() !== "" &&
            bank_code !== ""
        );
    }, [bank_name, account_number, bvn, bank_code]);

    useEffect(() => {
        setIsFormValid(valid());
    }, [valid]);

    const fetchBanks = async () => {
        const token = localStorage.getItem("authToken");
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchant/banks/NG",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const result = await response.json();
            if (response.ok) {
                setBanks(result.data);
            } else {
                toast.error("Failed to fetch banks");
            }
        } catch (error) {
            toast.error("There was a problem with the fetch operation");
            console.error("Fetch operation error:", error);
        }
    };

    useEffect(() => {
        if (!merchant_id) {
            navigate("/login");
            return;
        }
        fetchBanks();
    }, []);

    const fetchAccountName = async (accountNumber, bankCode) => {
        const token = localStorage.getItem("authToken");
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchant/banks/resolve-account",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        account_number: accountNumber,
                        bank_code: bankCode,
                    }),
                }
            );
            const result = await response.json();
            if (response.ok) {
                setAccountName(result.data.account_name);
                setIsAccountNameFetched(true);
            } else {
                toast.error(result.message || "Failed to fetch account name");
                setIsAccountNameFetched(false);
            }
        } catch (error) {
            toast.error("There was a problem with the fetch operation");
            console.error("Fetch operation error:", error);
            setIsAccountNameFetched(false);
        }
    };

    const handleBankSelect = (e) => {
        const selectedBank = banks.find((bank) => bank.name === e.target.value);
        if (selectedBank) {
            setBankName(selectedBank.name);
            setBankCode(selectedBank.code);
        }
    };

    const handleAccountNumberChange = (e) => {
        const accountNumber = e.target.value;
        setAccountNumber(accountNumber);
        if (bank_code && accountNumber.length >= 10) {
            fetchAccountName(accountNumber, bank_code);
        }
    };

    const validate = () => {
        let proceed = true;
        let errorMessage = "Please enter the value in ";

        if (bank_name == null || bank_name === "") {
            proceed = false;
            errorMessage += "Your Bank Name ";
        }
        if (bank_code == null || bank_code === "") {
            proceed = false;
            errorMessage += "Your Bank code ";
        }
        if (account_number == null || account_number === "") {
            proceed = false;
            errorMessage += "Your Account Number ";
        }

        if (bvn == null || bvn === "") {
            proceed = false;
            errorMessage += "Your Bvn Number";
        }

        if (!proceed) {
            toast.warning(errorMessage);
        }

        return proceed;
    };

    const handlePayment = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("authToken");
        const data = { merchant_id, bank_name, account_number, bvn, bank_code };

        if (validate()) {
            try {
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/merchant/payment",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(data),
                    }
                );

                const result = await response.json();

                if (response.ok) {
                    toast.success("Payment registered successfully");
                    navigate("/login");
                } else {
                    toast.error(result.message || "Registration failed");
                }
            } catch (error) {
                toast.error("Registration failed");
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            }
        }
    };

    return (
        <div>
            <div className="md:flex-row flex flex-col justify-between">
                <div>
                    <div className="2xl:mx-10">
                        <div className=" 2xl:pt-20 2xl:mx-10 pt-24 pb-4 mx-4">
                            <h2 className="text-2xl fw-bold">
                                Payment Information
                            </h2>
                            <p>
                                Fill in these details to set up payment
                                processing.
                            </p>
                        </div>
                        <form
                            action=""
                            className="2xl:mx-10 2xl:w-full mx-4"
                            onSubmit={handlePayment}
                        >
                            <label htmlFor="" className="">
                                Bank Name
                            </label>
                            <br />
                            <select
                                value={bank_name}
                                onChange={handleBankSelect}
                                className=" border-gray h-22 border-1 select w-full max-w-lg px-4 mb-4 rounded-md outline-none"
                                onClick={() => fetchBanks()}
                            >
                                <option value="">Select your Bank</option>
                                {banks
                                    .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map((bank) => (
                                        <option
                                            key={bank.code}
                                            value={bank.name}
                                        >
                                            {bank.name}
                                        </option>
                                    ))}
                            </select>
                            <br />
                            <label htmlFor="">Account Number</label>
                            <br />
                            <input
                                type="text"
                                placeholder="Your Account Number"
                                value={account_number}
                                onChange={handleAccountNumberChange}
                                className=" border-gray h-22 w-100 border-1 px-4 mb-4 rounded-md outline-none"
                            />
                            <br />
                            {isAccountNameFetched && (
                                <>
                                    <label htmlFor="">Account Name</label>
                                    <br />
                                    <input
                                        type="text"
                                        placeholder="Account Name"
                                        value={accountName}
                                        readOnly
                                        className=" border-gray h-22 w-100 border-1 px-4 mb-4 rounded-md outline-none"
                                    />
                                    <br />
                                </>
                            )}
                            <label htmlFor="">Bank Verification Number</label>{" "}
                            <br />
                            <input
                                type="text"
                                placeholder="Bank Verification Number"
                                value={bvn}
                                onChange={(e) => setBvn(e.target.value)}
                                className=" border-gray h-22 w-100 border-1 px-4 mb-4 rounded-md outline-none"
                            />
                            <label htmlFor="">Bank Code</label> <br />
                            <input
                                type="text"
                                placeholder="Bank Code"
                                value={bank_code}
                                onChange={(e) => setBankCode(e.target.value)}
                                className="border-gray h-22 w-100 border-1 px-4 mb-4 rounded-md outline-none"
                            />
                            <br />
                            <div className=" flex justify-center mt-10 mb-32">
                                <button
                                    type="submit"
                                    className={`fw-bold text-white w-full py-8 bg-orange-300 rounded-lg ${
                                        isFormValid
                                            ? "bg-gradient-to-r from-red to-orange"
                                            : "bg-orange-300"
                                    }`}
                                    disabled={!isFormValid}
                                >
                                    Continue
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PaymentNonReg;
