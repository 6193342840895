/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useContext } from "react";
import logo from "../Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Context } from "../Context/Context";

const Forgot = () => {
    const navigate = useNavigate();
    const { loggedIn } = useContext(Context);
    if (loggedIn) {
        navigate("/");
    }
    const [phone_number, setPhoneNumber] = useState("+234");
    const [isFormValid, setIsFormValid] = useState(false);
    const [phoneNumbers, setPhoneNumbers] = useState(["+1234567890"]);

    const valid = useCallback(() => {
        return phone_number.trim() !== "";
    }, [phone_number]);

    useEffect(() => {
        setIsFormValid(valid());
    }, [valid]);

    const handlePhoneChange = (value, country) => {
        setPhoneNumber(value);
    };

    const validate = () => {
        let proceed = true;
        let errorMessage = "Please enter the value in ";
        if (phone_number === "" || phone_number.startsWith("+")) {
            toast.error("Phone number must include the country code");
        } else if (phoneNumbers.includes(phone_number)) {
            proceed = false;
            errorMessage += " phone number already exists ,";
        }

        if (!proceed) {
            toast.warning(errorMessage);
        }
        return proceed;
    };

    const handleReset = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");
        const data = { phone_number };

        if (validate()) {
            try {
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/resetpassword",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(data),
                    }
                );

                const result = await response.json();

                if (response.ok) {
                    toast.success("Code sent successfully");
                    navigate("/codeVerify");
                } else if (response.status === 422) {
                    let errorMessage = Object.values(result.message);
                    errorMessage.forEach((errorArray) => {
                        errorArray.forEach((error) => {
                            toast.error(error);
                        });
                    });
                } else {
                    toast.error(result.message || "Reset password failed");
                }
            } catch (error) {
                toast.error("Reset password failed");
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            }
        }
    };

    return (
        <div>
            <div className="md:px-[100px] px-[20px] flex items-center justify-between py-[20px]">
                <Link to="/">
                    <img src={logo} alt="" className="md:w-[200px] w-[100px]" />
                </Link>

                <div>
                    <Link
                        to="/signUp"
                        className="px-[23px] py-[11px] md:px-[37px] md:py-[11px] bg-[#FFF0EA] rounded-[12px] text-[#F90013] fw-bold block"
                    >
                        Create a Store
                    </Link>
                </div>
            </div>
            <div className="py-[120px] flex flex-col items-center mx-auto">
                <div className="mb-[60px] text-center">
                    <h2 className="text-[24px] fw-bold">Forgot Password?</h2>
                    <p className="">
                        Enter your phone number and we will send you a code{" "}
                        <br /> to reset your password.
                    </p>
                </div>
                <form className="md:w-[400px]" onSubmit={handleReset}>
                    <div>
                        <label
                            htmlFor="phone_number"
                            className="block mb-3 fw-bold"
                        >
                            Phone Number
                        </label>
                        <PhoneInput
                            country={"ng"}
                            containerStyle={{
                                height: "60px",
                                width: "100%",
                                border: "1px solid red",
                                overflow: "hidden",
                                borderRadius: "9px",
                                borderColor: "#DCDDDE",
                            }}
                            placeholder="Enter Phone Number"
                            onChange={handlePhoneChange}
                            value={phone_number}
                            inputStyle={{
                                height: "60px",
                                width: "100%",
                                border: "none",
                            }}
                        />
                    </div>
                    <button
                        type="submit"
                        className={`fw-bold w-full p-4 rounded-[12px] text-white my-[28px] ${
                            isFormValid
                                ? "bg-gradient-to-r from-red to-orange"
                                : "bg-orange-300"
                        }`}
                        disabled={!isFormValid}
                    >
                        Continue
                    </button>
                    <Link to="/Login" className="text-center block">
                        Go Back to Log In
                    </Link>
                </form>
            </div>
        </div>
    );
};

export default Forgot;
