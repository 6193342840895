import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useCountry from "../../hooks/useCountry";
const HandleShipping = () => {
    const { states, message, getCitites, cities } = useCountry();
    const [formData, setFormData] = useState({
        region: "",
        shipping_fee: "",
    });

    const [regions, setRegions] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        const isValid = Object.values(formData).every(
            (field) => field.trim() !== ""
        );
        setIsFormValid(isValid);
    }, [formData]);

    const handleAddRegion = () => {
        if (isFormValid) {
            setRegions([...regions, formData]);
            setFormData({ region: "", shipping_fee: "" });
        }
    };

    const handleRemoveRegion = (index) => {
        const updatedRegions = regions.filter((_, i) => i !== index);
        setRegions(updatedRegions);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");
        const merchant_id = localStorage.getItem("merchant_id");

        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchant/shipping-preferences",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ regions, merchant_id }),
                }
            );
            if (response.ok) {
                toast.success("Shipping locations saved successfully!");
            } else {
                toast.error("Failed to save shipping locations.");
            }
        } catch (error) {
            toast.error("An error occurred.");
        }
    };

    return (
        <div className="mt-[25px]">
            <form onSubmit={handleSubmit} className="flex flex-col gap-[25px]">
                <div className="flex justify-between items-center">
                    <h2>Shipping Locations</h2>
                    <button
                        type="button"
                        onClick={handleAddRegion}
                        className={`py-[12px] fw-bold px-[24px] text-white rounded-[12px]  ${
                            !isFormValid
                                ? "bg-orange-300"
                                : "bg-gradient-to-r from-red to-orange"
                        }`}
                        disabled={!isFormValid}
                    >
                        Add
                    </button>
                </div>
                <div className="">
                    <label
                        htmlFor="region"
                        className="fw-bold inline-block  mb-3"
                    >
                        City
                    </label>
                    <select
                        type="text"
                        name="region"
                        className="border border-gray rounded-[12px] outline-none  select w-full max-w-lg "
                        value={formData.region}
                        onChange={(e) => {
                            handleChange(e);
                            getCitites(e.target.value);
                        }}
                    >
                        <option disabled selected>
                            Select a State
                        </option>
                        {states?.length > 0 ? (
                            states.map((st) => (
                                <option value={st.name}>{st.name}</option>
                            ))
                        ) : (
                            <>{message}</>
                        )}
                    </select>
                </div>
                <div className="flex flex-col md:flex-row w-full items-center gap-[22px]">
                    <div className="flex-1">
                        <label
                            htmlFor="region"
                            className="fw-bold inline-block  mb-3"
                        >
                            Area
                        </label>
                        <select
                            type="text"
                            name="region"
                            className="border border-gray rounded-[12px] outline-none  select  w-full max-w-lg "
                            value={formData.region}
                            onChange={handleChange}
                        >
                            <option disabled selected>
                                Select a City
                            </option>
                            {cities?.length > 0 ? (
                                cities.map((st) => (
                                    <option value={st}>{st}</option>
                                ))
                            ) : (
                                <>{message}</>
                            )}
                        </select>
                    </div>
                    <div className="flex-1 shrink-0">
                        <div>
                            <label
                                htmlFor="shipping_fee"
                                className="fw-bold inline-block  mb-3"
                            >
                                Shipping Fee
                            </label>
                        </div>
                        <div className="rounded-[12px] overflow-hidden border border-gray flex ">
                            <div className="border-r w-[20%] shrink-0 border-gray px-4 flex items-center">
                                <h2>&#8358;</h2>
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="shipping_fee"
                                    placeholder="Enter Amount"
                                    className=" p-4 flex-1 outline-none"
                                    value={formData.shipping_fee}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="">
                    <ul>
                        {regions.map((region, index) => (
                            <li
                                key={index}
                                className="flex justify-between items-center mb-2"
                            >
                                <span>
                                    {region.region} - &#8358;
                                    {region.shipping_fee}
                                </span>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveRegion(index)}
                                    className="text-red-500"
                                >
                                    Remove
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="text-right">
                    <button
                        type="submit"
                        className="py-[12px] px-[24px] fw-bold text-white rounded-[12px] bg-gradient-to-r from-red to-orange"
                        disabled={regions.length === 0}
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default HandleShipping;
