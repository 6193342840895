import React from "react";
import BuyerNav from "../BuyerNav/BuyerNav";
import Footer from "../Footer/Footer";
// import ProductHeader from "../ProductHeader/ProductHeader";
import { RxSlash } from "react-icons/rx";
import { Link, Outlet, useLocation } from "react-router-dom";
// import OrderNav from "../OrderNav/OrderNav";
import Navbar from "../Navbar/Navbar";

const BuyerBoard = () => {
    const location = useLocation();

    const getTitle = (path) => {
        switch (path) {
            case "/orderBuy":
                return "Order";
            case "/orderBuy-completed":
                return "Order";
            case "/orderBuy-pending":
                return "Order 200";
            case "/orderBuy-complete":
                return "Order 200";
            case "/profileBuyer":
                return "Profile";
            case "/wishList":
                return "WishList";
            default:
                return "Default Title";
        }
    };
    const title = getTitle(location.pathname);
    return (
        <div className="">
            {/* <ProductHeader/> */}
            <Navbar />
            {/* <OrderNav /> */}
            <div className="bg-grey pb-[10rem] md:px-[100px] px-[15px] ">
                <div className="">
                    <div className="">
                        <div>
                            <div className="flex py-4">
                                <Link to="/">
                                    <h2>Home</h2>
                                </Link>
                                <div className="flex items-center">
                                    <span className="inline-block">
                                        <RxSlash />
                                    </span>
                                    <div className="text-red inline-block">
                                        <Link to="/Cart">Cart</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <h2 className="py-4 text-2xl fw-black">
                                    {title}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className=" md:flex-row flex flex-col">
                        <div className=" md:block  md:w-[20%] shrink-0">
                            <BuyerNav />
                        </div>
                        <div className="md:flex-1">
                            <Outlet context={{ title }} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default BuyerBoard;
