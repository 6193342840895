
import { IoBagOutline } from "react-icons/io5";
import { GoPerson } from "react-icons/go";
import { MdOutlineFavoriteBorder } from "react-icons/md";


   export const BuyerLinks =[
    {
        key:'profile',
        Label:'Profile',
        path:'/profileBuyer',
        icon:<GoPerson/>

    },
    
    {
        key:'orders',
        Label:'Orders',
        path:'/orderBuy',
        icon: <IoBagOutline/>

    },
   
    {
        key:'WishList',
        Label:'WishList',
        path:'/wishList',
        icon: <MdOutlineFavoriteBorder/>

    },
]