/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useContext, useState, useEffect, useCallback } from "react";
import {
    Link,
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import Footer from "../Footer/Footer";
import { Context } from "../Context/Context";
import ProductCard from "../ProductCard";
import Navbar from "../Navbar/Navbar";
import useCategory from "./useCategory";

const AllProduct = ({ addToCart }) => {
    const { getCategories, categories } = useCategory();
    const [subCategories, setSubCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [categoryProducts, setCategoryProducts] = useState([]);
    let [labelTiltle, setLabel] = useState("");
    const {
        products,
        togglePagination,
        setSelectedCategory: setCat,
        loader,
        getAllProducts,
        productMessage,
    } = useContext(Context);

    const [searchParams, setSearchParams] = useSearchParams();
    const category = searchParams.get("cat");
    const [selected, setSelected] = useState("");

    useEffect(() => {
        getCategories();
    }, []);

    const getSubCategories = useCallback(async (categoryId) => {
        try {
            const response = await fetch(
                `https://getquickshop.online/api/v1/admin/categories/${categoryId}/subcategories`
            );
            const data = await response.json();
            setSubCategories(data.data);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            getSubCategories(selectedCategory);
        }
    }, [selectedCategory, getSubCategories]);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        const newCategory = event.target.id;
        setSearchParams(newCategory);
        setSelected(newCategory);
        getAllProducts(1, event.target.value);
        setSelectedSubCategory("");
    };

    const handleSubCategoryChange = (event) => {
        setSelectedSubCategory(event.target.value);
    };

    const getProductBySubCategory = useCallback(async (subCategoryId) => {
        try {
            const response = await fetch(
                `https://getquickshop.online/api/v1/admin/subcategories/${subCategoryId}/products`
            );
            const data = await response.json();
            return data;
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        if (selectedSubCategory) {
            getProductBySubCategory(selectedSubCategory).then((data) => {
                setCategoryProducts(data.data);
            });
        } else {
            setCategoryProducts([]); // Reset products when no subcategory is selected
        }
    }, [selectedSubCategory, getProductBySubCategory]);

    return (
        <div>
            <Navbar />
            <div className="py-[100px] md:py-0 text-small md:text-base">
                {/* <div className="md:pt-[100px] hidden md:block"></div> */}
                <div className="md:px-[100px] md:pb-[100px] px-[20px]">
                    <div className=" flex justify-between pt-6 mb-10">
                        <div className="md:text-[2xl] text-lg fw-bold">
                            {labelTiltle}
                        </div>
                    </div>

                    <div className="md:grid 2xl:grid-cols-[20%,80%] xl:grid-cols-[30%,70%]  grid-cols-[100] ">
                        <div className="xl:flex xl:flex-col  gap-[40px]">
                            {categories.length > 0 ? (
                                <div className="border rounde-[4px] border-gray  rounded-[8px]">
                                    <h1 className="flex justify-between items-center fw-bold p-[13px] border-b border-b-gray">
                                        Category
                                        <span>
                                            <ArrowButttonDown />
                                        </span>{" "}
                                    </h1>
                                    <div className="flex flex-col gap-[16px] px-[13px] py-[16px]">
                                        {categories.map((category) => (
                                            <>
                                                <label
                                                    htmlFor={category.cat_name}
                                                    className="flex items-center justify-between cursor-pointer"
                                                >
                                                    {category.cat_name}
                                                    <input
                                                        type="radio"
                                                        name="category"
                                                        value={category.id}
                                                        id={category.cat_name}
                                                        checked={
                                                            category.cat_name ==
                                                            selected
                                                        }
                                                        onChange={
                                                            handleCategoryChange
                                                        }
                                                    />
                                                </label>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}

                            {subCategories.length > 0 ? (
                                <div className="border rounde-[4px] border-gray  rounded-[8px] md:mt-0  mt-5">
                                    <h1 className="flex justify-between items-center fw-bold p-[13px] border-b border-b-gray">
                                        Sub Category{" "}
                                        <span>
                                            <ArrowButttonDown />
                                        </span>{" "}
                                    </h1>
                                    <div className="flex flex-col gap-[16px] px-[13px] py-[16px]">
                                        {subCategories.map((subCategory) => (
                                            <>
                                                <label
                                                    htmlFor={
                                                        subCategory.sub_cat_name
                                                    }
                                                    className="flex items-center justify-between cursor-pointer"
                                                >
                                                    {subCategory.sub_cat_name}
                                                    <input
                                                        type="radio"
                                                        name="category"
                                                        value={subCategory.id}
                                                        id={
                                                            subCategory.sub_cat_name
                                                        }
                                                        onChange={(e) => {
                                                            setLabel(
                                                                subCategory.sub_cat_name
                                                            );
                                                            handleSubCategoryChange(
                                                                e
                                                            );
                                                        }}
                                                    />
                                                </label>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="md:mt-0 mt-5">
                            <div className=" md:grid-cols-3 md:ml-20 grid grid-cols-2 gap-y-[48px] gap-x-[37px] md:mx-4">
                                {!selectedSubCategory &&
                                products?.data?.length > 0
                                    ? products?.data?.map((product, index) => (
                                          <div
                                              key={index}
                                              addToCart={addToCart}
                                          >
                                              <ProductCard
                                                  product={product}
                                                  key={product.id}
                                                  productImage={
                                                      product
                                                          ?.product_images?.[0]
                                                          ?.images
                                                  }
                                                  productName={
                                                      product?.product_name
                                                  }
                                                  productPrice={
                                                      product?.selling_price
                                                  }
                                                  storeName={
                                                      product?.merchant_type
                                                          ?.stores?.[0]
                                                          ?.business_name
                                                  }
                                                  storeId={
                                                      product.merchant_type
                                                          ?.stores?.[0]?.id
                                                  }
                                                  addToCart={addToCart}
                                              />
                                          </div>
                                      ))
                                    : ""}

                                {selectedSubCategory
                                    ? categoryProducts.map((product) => (
                                          <div
                                              key={product.id}
                                              addToCart={addToCart}
                                          >
                                              <Link
                                                  state={{ product }}
                                                  to={`/product/${product.product_name}`}
                                              >
                                                  <ProductCard
                                                      key={product.id}
                                                      productImage={
                                                          product
                                                              .product_images[0]
                                                              .images
                                                      }
                                                      productName={
                                                          product.product_name
                                                      }
                                                      productPrice={
                                                          product.selling_price
                                                      }
                                                      // storeName={product.merchant_type.stores[0].business_name}
                                                      addToCart={addToCart}
                                                  />
                                              </Link>
                                          </div>
                                      ))
                                    : ""}
                            </div>

                            <div>
                                {loader ? (
                                    <div className="py-5 flex flex-col justify-center items-center">
                                        <div class="loader">
                                            <span class="bar"></span>
                                            <span class="bar"></span>
                                            <span class="bar"></span>
                                        </div>
                                        <p className="fw-bold p-3">
                                            {" "}
                                            {productMessage}{" "}
                                        </p>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="flex items-center gap-[30px] justify-center mt-[100px]">
                                {products ? (
                                    <>
                                        {products.prev_page_url ||
                                        products.pagination.prev_page_url ? (
                                            <button
                                                className="flex gap-[12px] items-center"
                                                onClick={() =>
                                                    togglePagination(
                                                        products?.prev_page_url?.split(
                                                            "="
                                                        )[1] ||
                                                            products.pagination.prev_page_url.split(
                                                                "="
                                                            )[1]
                                                    )
                                                }
                                            >
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M3.33203 10L16.6654 10"
                                                        stroke="#8C8D8E"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M7.49867 14.1673C7.49867 14.1673 3.33204 11.0986 3.33203 10.0006C3.33202 8.90262 7.4987 5.83398 7.4987 5.83398"
                                                        stroke="#8C8D8E"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                Previous Page
                                            </button>
                                        ) : (
                                            ""
                                        )}

                                        {products?.next_page_url ||
                                        products?.pagination?.next_page_url ? (
                                            <button
                                                className="flex gap-[12px] items-center justify-end"
                                                onClick={() =>
                                                    togglePagination(
                                                        products?.next_page_url?.split(
                                                            "="
                                                        )[1] ||
                                                            products?.pagination?.next_page_url?.split(
                                                                "="
                                                            )[1]
                                                    )
                                                }
                                            >
                                                Next Page
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M16.6654 10L3.33203 10"
                                                        stroke="#141B34"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M12.5 14.1673C12.5 14.1673 16.6667 11.0986 16.6667 10.0006C16.6667 8.90262 12.5 5.83398 12.5 5.83398"
                                                        stroke="#141B34"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        {/* <FashionImage/> */}
                        {/* <Header/> */}
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default AllProduct;

export const ArrowButttonDown = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.6978 8.32502C15.5089 8.12357 15.1925 8.11336 14.991 8.30222L9.99967 12.9816L5.00831 8.30222C4.80685 8.11336 4.49044 8.12357 4.30157 8.32502C4.11271 8.52648 4.12291 8.8429 4.32437 9.03176L9.6577 14.0318C9.85003 14.2121 10.1493 14.2121 10.3416 14.0318L15.675 9.03176C15.8764 8.8429 15.8866 8.52648 15.6978 8.32502Z"
            fill="#141516"
        />
    </svg>
);
