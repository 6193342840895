/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imageAdd from "../Images/image-add.png";
import { useContext } from "react";
import { Context } from "../Context/Context";
import axios from "axios";

const StoreDetails = () => {
    const { userType: user, getMerchant, storeDetails } = useContext(Context);
    const [document, setDocument] = useState();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        business_name: storeDetails?.business_name || "",
        description: storeDetails?.description || "",
        business_address: storeDetails?.business_address || "",
        twitter_url: storeDetails?.twitter_url || "",
        instagram_url: storeDetails?.instagram_url || "",
        website_address: storeDetails?.website_address || "",
        return_policy: "",
        store_logo: "",
        store_banner: "",
        store_logoName: "",
        store_bannerName: "",
    });

    const getDocument = async () => {
        const token = localStorage.getItem("authToken");
        const url = `https://getquickshop.online/api/v1/user/merchant/documents/${user.merchant_id.id}`;
        const res = await axios.get(url, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/vnd.api+json",
                Authorization: `Bearer ${token}`,
            },
        });
        setDocument(res.data?.data?.document);
    };

    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const logoInputRef = useRef(null);
    const storeBannerInputRef = useRef(null);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: files[0],
                [`${name}Name`]: files[0].name,
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const checkFields = () => {
        for (let key in formData) {
            if (
                key !== "instagram_url" &&
                key !== "website_address" &&
                key !== "twitter_url" &&
                (formData[key] === "" || formData[key] === null)
            ) {
                setAllFieldsFilled(false);
                return;
            }
        }
        setAllFieldsFilled(true);
    };

    useEffect(() => {
        checkFields();
        getMerchant();
        getDocument();
        return () => {
            checkFields();
            getMerchant();
            getDocument();
        };
    }, []);

    const handleRemoveImage = (type) => {
        setFormData((prevState) => ({
            ...prevState,
            [type]: null,
            [`${type}Name`]: "",
        }));
        if (type === "store_logo") {
            logoInputRef.current.value = "";
        } else if (type === "store_banner") {
            storeBannerInputRef.current.value = "";
        }
    };

    const validate = () => {
        let proceed = true;
        let errorMessage = "Please enter the value in ";

        if (formData.business_name.trim() === "") {
            proceed = false;
            errorMessage += "Your Business Name ";
        }
        if (formData.description.trim() === "") {
            proceed = false;
            errorMessage += "Your Description Name ";
        }
        if (
            formData.website_address.trim() === "" &&
            formData.website_address !== null
        ) {
            proceed = true;
        }
        if (
            formData.instagram_url.trim() === "" &&
            formData.instagram_url !== null
        ) {
            proceed = true;
        }
        if (
            formData.return_policy.trim() === "" &&
            formData.return_policy !== null
        ) {
            proceed = true;
        }
        if (
            formData.twitter_url.trim() === "" &&
            formData.twitter_url !== null
        ) {
            proceed = true;
        }

        if (!proceed) {
            toast.warning(errorMessage);
        }

        return proceed;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const token = localStorage.getItem("authToken");

        if (validate()) {
            try {
                const data = new FormData();
                Object.keys(formData).forEach((key) => {
                    if (formData[key]) data.append(key, formData[key]);
                });
                data.append("merchant_id", user.merchant_id.id);
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/merchant/store-info",
                    {
                        method: "POST",
                        headers: {
                            Accept: "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: data,
                    }
                );
                const result = await response.json();

                if (response.ok) {
                    toast.success("Store details updated successfully");
                } else if (response.status === 422) {
                    // let errorMessage = Object.values(result.message).flat().join(", ");
                    toast.error("Store details update failed");
                } else {
                    toast.error(
                        result.message || "Store details update failed"
                    );
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                toast.error("store details update failed: " + error.message);
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            }
        }
    };

    return (
        <div className="">
            <form action="" onSubmit={handleSubmit}>
                <div className="flex flex-col md:flex-row gap-[20px] ">
                    <div className=" bg-white rounded-lg flex-1 shring-0 p-[19px] border border-gray">
                        <h2 className="fw-bold"> About Store</h2>
                        <div className="flex mt-[16px]">
                            <div className="flex flex-col">
                                <div className="">
                                    <h2 className=" fw-bold mb-3">
                                        Store Logo
                                    </h2>
                                    <div className="flex items-center gap-[20px] mb-[28px]">
                                        <div>
                                            {formData.store_logo ? (
                                                <img
                                                    src={URL.createObjectURL(
                                                        formData.store_logo
                                                    )}
                                                    alt="Store Logo"
                                                    className="object-cover md:w-[80px] md:h-[80px] w-[60px] h-[60px] rounded-full"
                                                />
                                            ) : (
                                                <>
                                                    {storeDetails &&
                                                    storeDetails?.store_logo ? (
                                                        <img
                                                            src={
                                                                storeDetails?.store_logo
                                                            }
                                                            className="md:w-[80px] md:h-[80px] w-[60px] h-[60px] rounded-full"
                                                            alt="logo"
                                                        />
                                                    ) : (
                                                        <h2 className="text-sm fw-bold bg-[#EBEDEF] text-[#21447A] p-[20px] rounded-full">
                                                            LO
                                                        </h2>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div className="border-1 border-[#EBEDEF] px-[12px] py-[8px]  rounded-[8px] flex items-center">
                                                <div>
                                                    {formData.store_logo ? (
                                                        <div className="flex items-center">
                                                            <span>
                                                                {
                                                                    formData.store_logoName
                                                                }
                                                            </span>
                                                            <FaTimes
                                                                className="ml-2 cursor-pointer"
                                                                onClick={() =>
                                                                    handleRemoveImage(
                                                                        "store_logo"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <label
                                                            htmlFor="store_logo-upload"
                                                            className="flex items-center gap-[3px] cursor-pointer"
                                                        >
                                                            <img
                                                                src={imageAdd}
                                                                alt=""
                                                            />
                                                            <span className=" text-[#646566] fw-bold text-[14px]">
                                                                Change Photo
                                                            </span>
                                                        </label>
                                                    )}
                                                    <input
                                                        type="file"
                                                        id="store_logo-upload"
                                                        name="store_logo"
                                                        ref={logoInputRef}
                                                        onChange={handleChange}
                                                        className="hidden"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h2 className=" fw-bold mb-3">
                                        Store Banner
                                    </h2>
                                    <div className="flex items-center gap-[20px] ">
                                        <div>
                                            {formData.store_banner ? (
                                                <img
                                                    src={URL.createObjectURL(
                                                        formData.store_banner
                                                    )}
                                                    alt="Store Banner"
                                                    className="object-cover md:w-[80px] md:h-[80px] w-[60px] h-[60px] rounded-full"
                                                />
                                            ) : (
                                                <>
                                                    {storeDetails &&
                                                    storeDetails?.store_banner ? (
                                                        <img
                                                            src={
                                                                storeDetails.store_banner
                                                            }
                                                            className="md:w-[80px] md:h-[80px] w-[60px] h-[60px] rounded-full"
                                                            alt="logo"
                                                        />
                                                    ) : (
                                                        <h2 className="text-sm fw-bold bg-[#EBEDEF] text-[#21447A] p-[20px] rounded-full">
                                                            LO
                                                        </h2>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div className="border-1 border-[#EBEDEF] px-[12px] py-[8px]  rounded-[8px] flex items-center">
                                                <div>
                                                    {formData.store_banner ? (
                                                        <div className="flex items-center">
                                                            <span>
                                                                {
                                                                    formData.store_bannerName
                                                                }
                                                            </span>
                                                            <FaTimes
                                                                className="ml-2 cursor-pointer"
                                                                onClick={() =>
                                                                    handleRemoveImage(
                                                                        "store_banner"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <label
                                                            htmlFor="store_banner-upload"
                                                            className="flex items-center gap-[3px] cursor-pointer"
                                                        >
                                                            <img
                                                                src={imageAdd}
                                                                alt=""
                                                            />
                                                            <span className=" text-[#646566] fw-bold text-[14px]">
                                                                Change Photo
                                                            </span>
                                                        </label>
                                                    )}
                                                    <input
                                                        type="file"
                                                        id="store_banner-upload"
                                                        name="store_banner"
                                                        ref={
                                                            storeBannerInputRef
                                                        }
                                                        onChange={handleChange}
                                                        className="hidden"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-[28px] mt-[28px]">
                            <div className="">
                                <label
                                    htmlFor=""
                                    className="fw-bold inline-block mb-3"
                                >
                                    Business Name
                                </label>
                                <input
                                    type="text"
                                    placeholder="Business Name"
                                    name="business_name"
                                    value={formData.business_name}
                                    onChange={handleChange}
                                    className="border border-gray w-100 rounded-[12px] p-4 outline-none"
                                />
                                <br />
                            </div>

                            <div className="">
                                <label
                                    htmlFor=""
                                    className="fw-bold inline-block mb-3"
                                >
                                    Business Address
                                </label>
                                <input
                                    type="text"
                                    placeholder="Business Address"
                                    name="business_address"
                                    value={formData.business_address}
                                    onChange={handleChange}
                                    className="border border-gray w-100 rounded-[12px] p-4 outline-none"
                                />
                            </div>

                            <div className="">
                                <label
                                    htmlFor=""
                                    className="fw-bold inline-block mb-3"
                                >
                                    Store Description
                                </label>
                                <input
                                    type="text"
                                    placeholder="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    className="border border-gray w-100 rounded-[12px] p-4 outline-none"
                                />
                            </div>

                            <div className="">
                                <label
                                    htmlFor=""
                                    className="fw-bold inline-block mb-3"
                                >
                                    Return Policy
                                </label>
                                <textarea
                                    type="text"
                                    placeholder="Return policy"
                                    name="return_policy"
                                    value={formData.return_policy}
                                    onChange={handleChange}
                                    className="border border-gray w-100 rounded-[12px] p-4 outline-none"
                                ></textarea>
                            </div>

                            <div className="text-right">
                                <button
                                    type="submit"
                                    className={`rounded-[12px] p-4 fw-bold text-white ${
                                        loading
                                            ? "bg-orange-300"
                                            : " bg-gradient-to-r from-red to-orange "
                                    }`}
                                    disabled={loading}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="flex-1 gap-[19px]  flex flex-col">
                        <div className="p-[19px]  bg-white rounded-[12px] border border-gray">
                            <h2 className="fw-bold pt-2 mx-4">
                                Social Media Handles
                            </h2>
                            <div>
                                <div className="m-4">
                                    <label htmlFor="" className="fw-bold">
                                        X (fka as Twitter) URL
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        placeholder="X (fka as Twitter) URL"
                                        name="twitter_url"
                                        value={formData.twitter_url}
                                        onChange={handleChange}
                                        className="h-22 border-1 border-gray w-100 px-2 rounded-lg outline-none"
                                    />
                                    <br />
                                </div>
                                <div className="fw-bold m-4">
                                    <label htmlFor="">Instagram URL</label>
                                    <br />
                                    <input
                                        type="text"
                                        placeholder="Instagram URL"
                                        name="instagram_url"
                                        value={formData.instagram_url}
                                        onChange={handleChange}
                                        className="h-22 border-1 border-gray w-100 px-2 rounded-lg outline-none"
                                    />
                                    <br />
                                </div>
                                <div className="fw-bold m-4">
                                    <label htmlFor="">Website Address</label>
                                    <br />
                                    <input
                                        type="text"
                                        placeholder="Website Address"
                                        name="website_address"
                                        value={formData.website_address}
                                        onChange={handleChange}
                                        className="h-22 border-1 border-gray w-100 px-2 rounded-lg outline-none"
                                    />
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className="p-[19px] bg-white rounded-[12px] border border-gray">
                            <h2 className="fw-bold">Documents</h2>
                            <div className="mt-[16px]">
                                <div className="">
                                    <label
                                        htmlFor=""
                                        className="fw-bold inline-block mb-3"
                                    >
                                        Identification Type
                                    </label>{" "}
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder=""
                                            name="identification_type"
                                            value={document?.NIN ? "NIN" : ""}
                                            disabled
                                            onChange={handleChange}
                                            className="border border-gray w-100 rounded-[12px] p-4 outline-none"
                                        />
                                        {document?.NIN ? (
                                            <h2 className="text-[#2F9E40] absolute top-[50%] translate-y-[-50%] right-0 px-4">
                                                Verified
                                            </h2>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-[16px]">
                                <div className="">
                                    <label
                                        htmlFor=""
                                        className="fw-bold inline-block mb-3"
                                    >
                                        Certificate of Incorporation
                                    </label>{" "}
                                    <div className="relative">
                                        <input
                                            type="text"
                                            placeholder=""
                                            name="identification_type"
                                            value={
                                                document?.certificate_of_incorporation
                                                    ? "Present"
                                                    : "Update on mobile"
                                            }
                                            disabled
                                            onChange={handleChange}
                                            className="border border-gray w-100 rounded-[12px] p-4 outline-none"
                                        />
                                        {document?.certificate_of_incorporation ? (
                                            <h2 className="text-[#2F9E40] absolute top-[50%] translate-y-[-50%] right-0 px-4">
                                                Verified
                                            </h2>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                {/* <div className="">
                    <label htmlFor="" className=" fw-bold">
                      Certificate of Incorporation
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="Certificate of Incorporation"
                      name="certificate_of_incorporation"
                      value={formData.certificate_of_incorporation}
                      readOnly
                      onChange={handleChange}
                      className="h-22 border-1 border-gray w-100 px-2 rounded-lg outline-none"
                    />
                    <br />
                    <h2 className="text-[#2F9E40] absolute top-[45%] right-0 px-4">
                      Verified
                    </h2>
                  </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default StoreDetails;
