/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context } from "../Context/Context";
const StoreInfoNonReg = () => {
    const [isFormValid, setIsFormValid] = useState("");
    const navigate = useNavigate();
    const [business_name, setBusinessName] = useState("");
    const [business_address, setBusinessAddress] = useState("");
    const [category_id, setStoreCategory] = useState("");
    const [description, setDescription] = useState("");
    const [categories, setCategories] = useState([]);
    const { userType: user } = useContext(Context);

    const merchant_id = user?.merchant_id?.id || "";

    const valid = useCallback(() => {
        return (
            business_name !== "" &&
            business_address !== "" &&
            category_id !== "" &&
            description !== ""
        );
    }, [business_name, business_address, category_id, description]);

    useEffect(() => {
        setIsFormValid(valid());
    }, [valid]);

    useEffect(() => {
        if (!merchant_id) {
            navigate("/login");
            return;
        }
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/admin/categories",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/vnd.api+json",
                    },
                }
            );

            const result = await response.json();
            if (response.ok) {
                setCategories(result.data);
            } else {
                toast.error("Failed to fetch categories");
            }
        } catch (error) {
            toast.error("Failed to fetch categories");
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        }
    };

    const validate = () => {
        let proceed = true;
        let errorMessage = "Please enter the value in ";

        if (business_name == null || business_name === "") {
            proceed = false;
            errorMessage += "Your business Name ";
        }
        if (business_address == null || business_address === "") {
            proceed = false;
            errorMessage += "Your business Address ";
        }
        if (category_id == null || category_id === "") {
            proceed = false;
            errorMessage += "Your Store Category ";
        }
        if (description == null || description === "") {
            proceed = false;
            errorMessage += "Descriptions ";
        }

        if (!proceed) {
            toast.warning(errorMessage);
        }

        return proceed;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");
        const data = {
            merchant_id,
            business_name,
            business_address,
            category_id,
            description,
        };

        if (validate()) {
            try {
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/merchant/store",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(data),
                    }
                );

                const result = await response.json();

                if (response.ok) {
                    toast.success("Registered successfully");
                    navigate("/accountCreated2");
                } else if (response.status === 400) {
                    let errorMessage = Object.values(result.message)
                        .flat()
                        .join("");
                    toast.error(errorMessage);
                } else {
                    toast.error(result.message || "Registration failed");
                }
            } catch (error) {
                toast.error("Registration failed");
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            }
        }
    };

    return (
        <div className="">
            <div className="">
                <div className="2xl:mx-20 pt-20 mx-4">
                    <h2 className="fw-bold text-2xl">Store Information</h2>
                    <h3 className="py-3 pb-10">
                        Fill in these details to set up your store.
                    </h3>
                </div>
                <div>
                    <div className=" md:flex-row flex flex-col justify-between mx-4">
                        <form
                            action=""
                            className=" 2xl:mx-20 w-full"
                            onSubmit={handleSubmit}
                        >
                            <label htmlFor="Business Name">Business Name</label>
                            <br />
                            <input
                                type="text"
                                name="business_name"
                                placeholder="what you will like your store to be named"
                                value={business_name}
                                onChange={(e) =>
                                    setBusinessName(e.target.value)
                                }
                                className=" h-22 border-1 border-gray w-full px-4 mt-2 mb-2 rounded-md outline-none"
                            />
                            <br />
                            <label htmlFor="">Business Address</label>
                            <br />
                            <input
                                type="text"
                                placeholder="Business Address"
                                value={business_address}
                                onChange={(e) =>
                                    setBusinessAddress(e.target.value)
                                }
                                className=" h-22 border-1 border-gray w-full px-4 mt-2 mb-4 rounded-md outline-none"
                            />{" "}
                            <br />
                            <div className="w-full">
                                <label htmlFor="category_id">
                                    Store Category
                                </label>{" "}
                                <br />
                                <select
                                    id="category_id"
                                    value={category_id}
                                    onChange={(e) =>
                                        setStoreCategory(e.target.value)
                                    }
                                    className="h-22 border-1 border-gray select w-full max-w-lg px-4 mt-2 mb-4 overflow-hidden rounded-md outline-none"
                                >
                                    <option value="" className="">
                                        Select a category
                                    </option>
                                    {categories.map((category) => (
                                        <option
                                            key={category.id}
                                            value={category.id}
                                            className=""
                                        >
                                            {category.cat_name}
                                        </option>
                                    ))}
                                </select>
                                <br />
                            </div>
                            <label htmlFor="">Description</label>
                            <br />
                            <input
                                type="text"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Describe what your business does in word"
                                className=" h-22 border-1 border-gray w-full px-4 mt-2 mb-4 rounded-md outline-none"
                            />
                            <br />
                            <div className="flex justify-center mt-10 mb-32 text-center cursor-pointer">
                                <button
                                    type="submit"
                                    className={`fw-bold text-white bg-orange-300 rounded-lg w-full py-8 ${
                                        isFormValid
                                            ? "bg-gradient-to-r from-red to-orange"
                                            : "bg-orange-300"
                                    }`}
                                    disabled={!isFormValid}
                                >
                                    Continue
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreInfoNonReg;
