import React, { useState, useEffect } from "react";
import alert from "../Images/alert-circle.png";
import { RxSlash } from "react-icons/rx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditCoupon = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [discount_type, setDiscountType] = useState("");
    const [setCouponCode] = useState("");
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [formData, setFormData] = useState({
        coupon_title: "",
        coupon_code: "",
        discount_type: "",
        amount: "",
        apply_coupon_to: "",
        status: "",
        valid_from: "",
        valid_to: "",
        number_of_coupons: "",
        maximum_number_of_uses_per_customer: "",
        min_cart_amount: "",
    });

    useEffect(() => {
        const formValues = Object.values(formData);
        setIsFormComplete(formValues.every((value) => !!value));
    }, [formData]);

    useEffect(() => {
        const fetchCoupon = async () => {
            const token = localStorage.getItem("authToken");
            const couponId = localStorage.getItem("editCouponId");
            try {
                const response = await fetch(
                    `https://getquickshop.online/api/v1/user/merchant/seller/coupon/${couponId}`,
                    {
                        method: "GET",
                        headers: {
                            Accept: "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (response.ok) {
                    const couponData = await response.json();
                    if (couponData && couponData.data) {
                        setFormData({
                            coupon_title: couponData.data.coupon_title || "",
                            coupon_code: couponData.data.coupon_code || "",
                            discount_type: couponData.data.discount_type || "",
                            amount: couponData.data.amount || "",
                            apply_coupon_to:
                                couponData.data.apply_coupon_to || "",
                            status: couponData.data.status || "",
                            valid_from: couponData.data.valid_from || "",
                            valid_to: couponData.data.valid_to || "",
                            number_of_coupons:
                                couponData.data.number_of_coupons || "",
                            maximum_number_of_uses_per_customer:
                                couponData.data
                                    .maximum_number_of_uses_per_customer || "",
                            min_cart_amount:
                                couponData.data.min_cart_amount || "",
                        });
                        setIsDataFetched(true);
                    }
                } else {
                    toast.error("Failed to fetch coupon details");
                }
            } catch (error) {
                toast.error("Failed to fetch coupon details");
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            }
        };
        fetchCoupon();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
        setFormData({
            ...formData,
            apply_coupon_to: e.target.value,
        });
    };

    const handleProductSelect = (e) => {
        const product = e.target.value;
        if (product && !selectedProducts.includes(product)) {
            setSelectedProducts([...selectedProducts, product]);
        }
    };

    const handleDiscountTypeChange = (e) => {
        setDiscountType(e.target.value);
        setFormData({
            ...formData,
            discount_type: e.target.value,
        });
    };

    const generateCouponCode = () => {
        const code = Math.floor(1000 + Math.random() * 9000).toString();
        setCouponCode(code);
        setFormData({
            ...formData,
            coupon_code: code,
        });
    };

    const handleStatusChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem("authToken");
        const couponId = localStorage.getItem("editCouponId");
        try {
            const response = await fetch(
                `https://getquickshop.online/api/v1/user/merchant/seller/coupon/${couponId}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ ...formData, couponId }),
                }
            );

            const result = await response.json();

            if (response.ok) {
                toast.success("Coupon updated successfully");
                resetForm();
                navigate("/coupons");
            } else if (response.status === 422) {
                let errorMessage = Object.values(result.message)
                    .flat()
                    .join("");
                toast.error(errorMessage);
            } else {
                toast.error(result.message || "Registration failed");
            }
        } catch (error) {
            toast.error("Registration failed");
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        }
    };
    const handleDelete = async () => {
        const token = localStorage.getItem("authToken");
        const couponId = localStorage.getItem("editCouponId");
        try {
            const response = await fetch(
                `https://getquickshop.online/api/v1/user/merchant/seller/coupon/${couponId}`,
                {
                    method: "DELETE",
                    headers: {
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.ok) {
                toast.success("Coupon deleted successfully");
                navigate("/coupons");
            } else {
                toast.error("Failed to delete coupon");
            }
        } catch (error) {
            toast.error("Failed to delete coupon");
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        }
    };
    const handleCancel = () => {
        document.getElementById("my_modal_3").close();
    };
    const resetForm = () => {
        setFormData({
            coupon_title: "",
            coupon_code: "",
            discount_type: "",
            amount: "",
            apply_coupon_to: "",
            status: "",
            valid_from: "",
            valid_to: "",
            number_of_coupons: "",
            maximum_number_of_uses_per_customer: "",
            min_cart_amount: "",
        });
        setIsFormComplete(false);
    };

    return (
        <div>
            <div className="flex xl:mx-10 mx-4 pt-4">
                <div className="flex items-center">
                    <Link to="/coupons">
                        <h2 className="">Coupons</h2>
                    </Link>
                    <div>
                        <h2>
                            <RxSlash />
                        </h2>
                    </div>
                </div>
                <div className="flex items-center">
                    <div>
                        <h2 className="">Add New Coupon</h2>
                    </div>
                </div>
            </div>
            <div className="flex justify-between">
                <div className=" xl:mx-10  mx-4 py-6">
                    <h2 className="text-2xl">Edit Coupon</h2>
                </div>
                <div className="mx-6">
                    <button
                        className=" text-red bg-[#FFEFF1] mx-4 btn "
                        onClick={() =>
                            document.getElementById("my_modal_3").showModal()
                        }
                    >
                        Delete
                    </button>
                    <div className="mx-4 w-full xl:w-[30%]">
                        <dialog id="my_modal_3" className="modal ">
                            <div className="modal-box ">
                                <form method="dialog">
                                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                                        ✕
                                    </button>
                                </form>
                                <div className="">
                                    <div className="flex justify-center">
                                        <img
                                            src={alert}
                                            alt=""
                                            className="bg-[#FFEFF1] p-4 rounded-full"
                                        />
                                    </div>
                                    <div className="text-center pt-4 whitespace-nowrap">
                                        <h3 className="pb-2 text-xl fw-bold">
                                            Remove Item
                                        </h3>
                                        <h6>
                                            Are you sure you want to delete this
                                            post? This <br />
                                            action cannot be undone.
                                        </h6>
                                    </div>
                                    <div className="flex justify-evenly pt-6">
                                        <div className="  mr-2">
                                            <button
                                                className="py-8 px-14 border-2 rounded-lg"
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div className="mr-6">
                                            <button
                                                className="py-8 px-14 bg-gradient-to-r from-red to-orange text-white rounded-md"
                                                onClick={handleDelete}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </dialog>
                    </div>
                </div>
            </div>
            <div className="xl:mx-10 mx-4 pb-[10rem]">
                <div className="bg-white xl:w-[50%] rounded-lg">
                    <form onSubmit={handleSubmit}>
                        <div className="mx-4 pt-2">
                            <label>Coupon Title</label>
                            <br />
                            <input
                                type="text"
                                name="coupon_title"
                                value={formData.coupon_title}
                                onChange={handleInputChange}
                                className="outline-none border-1 border-gray w-full px-2 rounded-lg py-[10px] my-2"
                                placeholder="e.g Black Friday Sales"
                            />
                        </div>
                        <div className="relative mx-4 ">
                            <label>Coupon Code</label>
                            <br />
                            <input
                                type="text"
                                name="coupon_code"
                                value={formData.coupon_code}
                                readOnly
                                className="outline-none border-1 border-gray px-2 w-full rounded-lg py-[10px] my-2"
                                placeholder="Tap to generate a code"
                            />
                            <button
                                type="button"
                                onClick={generateCouponCode}
                                className="absolute right-0 top-[45%] mx-4 bg-[#F9F4F2] text-red p-[4px] rounded-md"
                            >
                                Generate
                            </button>
                        </div>
                        <div className="">
                            <label className="mx-4">Discount Type</label>
                            <div className="flex">
                                <div className="flex items-center border-1 border-gray rounded-lg py-[10px] my-2 w-full mx-4">
                                    <input
                                        type="radio"
                                        name="discount_type"
                                        value="percentage"
                                        onChange={handleDiscountTypeChange}
                                        className="radio radio-error radio-sm mx-2"
                                    />
                                    <h2>Percentage</h2>
                                </div>
                                <div className="flex items-center border-1 border-gray rounded-lg py-[10px] my-2 mr-4 w-full">
                                    <input
                                        type="radio"
                                        name="discount_type"
                                        value="fixed"
                                        onChange={handleDiscountTypeChange}
                                        className="radio radio-error radio-sm mx-2"
                                    />
                                    <h2>Fixed</h2>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <label className="mx-4">Amount</label>
                            <div className="flex border-1 border-gray rounded-lg mx-4 my-2">
                                <div className="px-4 flex items-center">
                                    <h2>
                                        {discount_type === "fixed"
                                            ? "₦"
                                            : discount_type === "percentage"
                                            ? "%"
                                            : ""}
                                    </h2>
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        name="amount"
                                        value={formData.amount}
                                        onChange={handleInputChange}
                                        className="border-l-1 py-[10px] px-2 border-gray outline-none"
                                        placeholder="Enter Amount"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="my-2">
                            <div className="px-4 w-full">
                                <label>Apply Coupon To</label>
                                <br />
                                <select
                                    name="apply_coupon_to"
                                    value={formData.apply_coupon_to}
                                    onChange={handleSelectChange}
                                    className="w-full outline-none border-1 border-gray py-2 rounded-lg select max-w-lg my-2"
                                >
                                    <option value="">Select an option</option>
                                    <option value="all">All Products</option>
                                    <option value="specific_product">
                                        Specific Products
                                    </option>
                                    <option value="min_cart_amount">
                                        Minimum Cart Amount
                                    </option>
                                </select>
                            </div>
                        </div>
                        {selectedOption === "specific_product" && (
                            <div className="px-4 w-full ">
                                <select
                                    className="w-full outline-none border-1 border-gray py-2 rounded-lg  select  max-w-lg my-2"
                                    onChange={handleProductSelect}
                                >
                                    <option>Select Products</option>
                                    <option>The Inyene Heels</option>
                                    <option>The Sola Flats</option>
                                    <option>The Ezinne Heels</option>
                                </select>
                                <div className="grid grid-cols-3 gap-2 ">
                                    {selectedProducts.map((product, index) => (
                                        <div
                                            key={index}
                                            className="flex items-center bg-[#E9F4FF] text-sm rounded-lg px-2 py-1"
                                        >
                                            <span>{product}</span>
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    setSelectedProducts(
                                                        selectedProducts.filter(
                                                            (item) =>
                                                                item !== product
                                                        )
                                                    )
                                                }
                                                className="ml-2 focus:outline-none"
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {selectedOption === "min_cart_amount" && (
                            <div className="my-2">
                                <label className="mx-4">
                                    Minimum Cart Amount
                                </label>
                                <div className="flex border-1 border-gray rounded-lg mx-4 my-2">
                                    <div className="px-4 flex items-center">
                                        <h2>&#8358;</h2>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            name="min_cart_amount"
                                            onChange={handleInputChange}
                                            className="border-l-1 py-[10px] px-2 border-gray outline-none "
                                            placeholder="Enter Amount"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="mx-4">
                            <label>Valid From</label>
                            <br />
                            <input
                                type="date"
                                name="valid_from"
                                value={formData.valid_from}
                                onChange={handleInputChange}
                                className="outline-none border-1 border-gray px-2 rounded-lg py-[10px] my-2 w-full"
                            />
                        </div>
                        <div className="mx-4">
                            <label>Valid To</label>
                            <br />
                            <input
                                type="date"
                                name="valid_to"
                                value={formData.valid_to}
                                onChange={handleInputChange}
                                className="outline-none border-1 border-gray px-2 rounded-lg py-[10px] my-2 w-full"
                            />
                        </div>
                        <div className="mx-4">
                            <label>Number of Coupons</label>
                            <br />
                            <input
                                type="text"
                                name="number_of_coupons"
                                value={formData.number_of_coupons}
                                onChange={handleInputChange}
                                className="outline-none border-1 border-gray w-full px-2 rounded-lg py-[10px] my-2"
                                placeholder="Enter Number"
                            />
                        </div>
                        <div className="mx-4">
                            <label>Max Uses per Customer</label>
                            <br />
                            <input
                                type="text"
                                name="maximum_number_of_uses_per_customer"
                                value={
                                    formData.maximum_number_of_uses_per_customer
                                }
                                onChange={handleInputChange}
                                className="outline-none border-1 border-gray w-full px-2 rounded-lg py-[10px] my-2"
                                placeholder="Enter Number"
                            />
                        </div>
                        <div className="">
                            <label className="mx-4">Coupon Status</label>
                            <div className="flex">
                                <div className="flex items-center border-1 border-gray rounded-lg py-[10px] my-2 w-full mx-4">
                                    <input
                                        type="radio"
                                        name="status"
                                        value="active"
                                        checked={formData.status === "active"}
                                        onChange={handleStatusChange}
                                        className="radio radio-error radio-sm mx-2"
                                    />
                                    <h2>Active</h2>
                                </div>
                                <div className="flex items-center border-1 border-gray rounded-lg py-[10px] my-2 mr-4 w-full">
                                    <input
                                        type="radio"
                                        name="status"
                                        value="inactive"
                                        checked={formData.status === "inactive"}
                                        onChange={handleStatusChange}
                                        className="radio radio-error radio-sm mx-2"
                                    />
                                    <h2>InActive</h2>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center py-6 mx-4 pb-[10rem]">
                            <button
                                type="submit"
                                className={`w-full text-white px-4 py-2 rounded-lg ${
                                    isFormComplete || !isDataFetched
                                        ? "bg-gradient-to-r from-red to-orange"
                                        : "bg-orange-300 "
                                }`}
                                // disabled={!isFormComplete || !isDataFetched}
                            >
                                Save changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditCoupon;
