/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { RxSlash } from "react-icons/rx";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditPayment = () => {
    const [bank_name, setBankName] = useState("");
    const [bank_code, setBankCode] = useState("");
    const [account_number, setAccountNumber] = useState("");
    const [accountName, setAccountName] = useState("");
    const [banks, setBanks] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const { state } = useLocation();
    const payment = state?.payment || {};

    const valid = useCallback(() => {
        return bank_name !== "" && account_number !== "" && accountName !== "";
    }, [bank_name, account_number, accountName]);

    useEffect(() => {
        setIsFormValid(valid());
    }, [valid]);

    const fetchBanks = async () => {
        const token = localStorage.getItem("authToken");
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchant/banks/NG",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const result = await response.json();
            if (response.ok) {
                setBanks(result.data);
                if (payment?.account_number) {
                    setAccountNumber(payment?.account_number);
                    setBankName(payment?.bank_name);
                    const bankCode = result.data.find(
                        (e) =>
                            e.name.toLowerCase() ===
                            payment?.bank_name.toLowerCase()
                    );
                    fetchAccountName(payment?.account_number, bankCode.code);
                }
            } else {
                toast.error("Failed to fetch banks");
            }
        } catch (error) {
            toast.error("There was a problem with the fetch operation");
            console.error("Fetch operation error:", error);
        }
    };

    useEffect(() => {
        fetchBanks();
    }, []);

    const fetchAccountName = async (accountNumber, bankCode) => {
        const token = localStorage.getItem("authToken");
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchant/banks/resolve-account",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        account_number: accountNumber,
                        bank_code: bankCode,
                    }),
                }
            );
            const result = await response.json();
            if (response.ok) {
                setAccountName(result.data.account_name);
            } else {
                toast.error(result.message || "Failed to fetch account name");
            }
        } catch (error) {
            toast.error("There was a problem with the fetch operation");
            console.error("Fetch operation error:", error);
        }
    };

    const handleBankSelect = (e) => {
        const selectedBank = banks.find((bank) => bank.name === e.target.value);
        if (selectedBank) {
            setBankName(selectedBank.name);
            setBankCode(selectedBank.code);
        }
    };

    const handleAccountNumberChange = (e) => {
        const accountNumber = e.target.value;
        setAccountNumber(accountNumber);
        if (accountNumber.length >= 10 && bank_code) {
            fetchAccountName(accountNumber, bank_code);
        }
    };

    return (
        <div>
            <div className="h-[50rem]">
                <div className="2xl:px-20  flex px-4 py-4">
                    <Link to="/settings">
                        <h2>Settings</h2>
                    </Link>
                    <div className="flex items-center">
                        <span className="inline-block">
                            <RxSlash />
                        </span>
                        <div className=" inline-block">
                            <Link to="/Cart">Edit Payment Information</Link>
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded-lg w-[50%] h-[25rem] mx-20">
                    <form action="">
                        <div className=" pt-4 mx-2 bg-white rounded-lg">
                            <label htmlFor="" className=" mx-2">
                                Bank Name
                            </label>
                            <br />
                            <select
                                value={bank_name}
                                onChange={handleBankSelect}
                                className=" border-gray h-22 border-1 select w-full max-w-lg px-4 rounded-md outline-none"
                            >
                                <option value="">Select your Bank</option>
                                {banks
                                    .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map((bank) => (
                                        <option
                                            selected={
                                                bank.name === payment.bank_name
                                            }
                                            key={bank.name}
                                            value={bank.name}
                                        >
                                            {bank.name}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="m-2">
                            <label htmlFor="" className="fw-bold">
                                Account Number
                            </label>
                            <br />
                            <input
                                type="text"
                                value={account_number}
                                onChange={handleAccountNumberChange}
                                placeholder=" Account Number"
                                className="h-22 border-1 border-gray w-100 px-4 mt-2 rounded-lg outline-none"
                            />
                            <br />
                        </div>
                        <div className="m-2">
                            <label htmlFor="" className="fw-bold">
                                Account Name
                            </label>
                            <br />
                            <input
                                value={accountName}
                                type="text"
                                placeholder="Account Name"
                                className="h-22 border-1 border-gray w-100 px-2 mt-2 rounded-lg outline-none"
                                readOnly
                            />
                            <br />
                        </div>

                        <div className=" fw-bold float-right mx-4 my-4 text-sm">
                            <button
                                className={`px-6 py-8 mt-4 mb-10 text-white bg-orange-300 rounded-lg ${
                                    isFormValid
                                        ? "bg-gradient-to-r from-red to-orange"
                                        : "bg-orange-300"
                                }`}
                                disabled={!isFormValid}
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditPayment;
