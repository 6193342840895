/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PaymentInfo = () => {
    const [paymentInfo, setPaymentInfo] = useState([]);
    const [accountName, setAccountName] = useState("");

    useEffect(() => {
        fetchPaymentInfo();
    }, []);

    const fetchPaymentInfo = async () => {
        const token = localStorage.getItem("authToken");
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchants/payment",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.ok) {
                const result = await response.json();
                if (Array.isArray(result.data)) {
                    setPaymentInfo(result.data);
                } else {
                    setPaymentInfo([result.data]);
                }
            } else {
                toast.error("Failed to fetch payment information");
            }
        } catch (error) {
            toast.error("Failed to fetch payment information");
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        }
    };
    const fetchAccountName = async (accountNumber, bankCode) => {
        const token = localStorage.getItem("authToken");
        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchant/banks/resolve-account",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        account_number: accountNumber,
                        bank_code: bankCode,
                    }),
                }
            );
            const result = await response.json();
            if (response.ok) {
                setAccountName(result.data.account_name);
            } else {
                toast.error(result.message || "Failed to fetch account name");
            }
        } catch (error) {
            toast.error("There was a problem with the fetch operation");
            console.error("Fetch operation error:", error);
        }
    };

    const header = ["Account Number", "Bank Name", "Details"];

    return (
        <div className="bg-white rounded-[12px]">
            <table className="w-full md:table-auto overflow-x-auto">
                <thead>
                    <tr className="border-b-gray border-b">
                        {header.map((e, index) => (
                            <th key={index} className="p-[13px] text-left">
                                {e}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {paymentInfo.map((payment, index) => (
                        <tr key={index}>
                            <td className="p-[13px]">
                                {payment.account_number}
                            </td>
                            <td className="p-[13px]">{payment.bank_name}</td>
                            {/* <td className="p-[13px]">{payment.account_name}</td>
                            <td className="p-[13px]">{payment.currency}</td> */}
                            <td className="p-[13px]">
                                <Link to="/payment" state={{ payment }}>
                                    <button className="rounded-[12px] border border-gray text-red fw-bold px-[14px] py-[8px]">
                                        Edit
                                    </button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default PaymentInfo;
