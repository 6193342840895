import React, { useState, useEffect, useContext } from "react";
import logo from "../Images/logo.png";
// import { TbLogout2 } from "react-icons/tb";
import { DashBoardLinks } from "../Navigation";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { MdHeadset } from "react-icons/md";
import { useLocation } from "react-router-dom";
// import { GrClose } from "react-icons/gr";
import { Context } from "../Context/Context";
import { toast } from "react-toastify";

const SideBar = ({ children }) => {
    const [active, setActive] = useState("");
    const { pathname } = useLocation();
    // const [progress, setProgress] = useState(false);
    const { logout, setupCompleted } = useContext(Context);
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
    // const [completeSetup, setCompleted] = useState(localStorage.getItem("setup"));

    useEffect(() => {
        setActive(pathname.split("-")[0]);
    }, [pathname]);

    return (
        <div className="md:flex relative md:static ">
            <div
                className={`z-50 md:shrink-0 h-screen fixed md:sticky top-0 border-r-1 border-r-gray inset-y-0 transform transition-transform duration-300 ease-in-out bg-white w-64 md:w-[300px] overflow-y-auto ${
                    isSidebarOpen
                        ? "translate-x-0 "
                        : "-translate-x-full md:translate-x-0"
                }`}
            >
                <div className="flex items-center">
                    <Link to="/">
                        <div className="flex items-center">
                            <div className="p-[24px]">
                                <img src={logo} alt="Logo" className="w-full" />
                            </div>
                        </div>
                    </Link>
                    <div className="xl:hidden flex mx-6">
                        <button className="text-2xl" onClick={toggleSidebar}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M19 5L5 19M5 5L19 19"
                                    stroke="#141516"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="flex flex-col justify-between h-[90%]">
                    <div className="flex flex-col">
                        {DashBoardLinks.map((items) => (
                            <>
                                <div key={items.key}>
                                    <div
                                        className={`fw-bold py-[9px] hover:bg-orange-100 hover:text-red ${
                                            active === items.path
                                                ? "text-red bg-orange-100"
                                                : "text-dark-gray"
                                        }`}
                                    >
                                        <NavLink
                                            to={
                                                !setupCompleted
                                                    ? "/dashboard"
                                                    : items.path
                                            }
                                            onClick={() => {
                                                if (!setupCompleted)
                                                    toast.info(
                                                        "complete store setup on the dashboard"
                                                    );
                                                toggleSidebar();
                                            }}
                                        >
                                            <div className="h-full flex items-center gap-[12px] px-[24px] py-2">
                                                <div>
                                                    <h2>{items.icon}</h2>
                                                </div>
                                                <div className="sm:flex">
                                                    <h2>{items.Label}</h2>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </>
                        ))}

                        <button
                            onClick={() => {
                                toast.info("processing logout");
                                const check = logout();
                                if (check === "completed") {
                                    toast.info("logout successult");
                                    navigate("/");
                                }
                            }}
                            className="mx-[25px] mt-[25px]"
                        >
                            <h2 className="text-dark-gray fw-bold flex items-center gap-[10px]">
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.4165 6.29922C7.67484 3.29922 9.2165 2.07422 12.5915 2.07422H12.6998C16.4248 2.07422 17.9165 3.56589 17.9165 7.29089V12.7242C17.9165 16.4492 16.4248 17.9409 12.6998 17.9409H12.5915C9.2415 17.9409 7.69984 16.7326 7.42484 13.7826"
                                        stroke="#8C8D8E"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M12.4999 10H3.0166"
                                        stroke="#8C8D8E"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M4.87516 7.20703L2.0835 9.9987L4.87516 12.7904"
                                        stroke="#8C8D8E"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <span className="block">Logout</span>
                            </h2>
                        </button>

                        {!setupCompleted ? (
                            <p className="text-sm md:text-base p-[9px] text-red fw-bold">
                                <Link to="/dashboard" className="underline">
                                    Click here
                                </Link>{" "}
                                to complete your store setup on dashboard to
                                access merchant feature
                            </p>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="mx-[24px] flex flex-col gap-[35px] pb-[50px]">
                        <div className="rounded-xl w-[100%] bg-orange-100 p-[13px] fw-bold">
                            <h2 className="text-red text-[13px]">
                                <MdHeadset className="inline-block" />
                                <span className="mx-1">Need Help?</span>
                            </h2>
                            <p className="text-[13px]">
                                Share your concerns with us <br /> via +234
                                9077883377 or <br /> support@quickshop.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="md:h-screen md:flex-1 md:overflow-auto absolute left-0 top-0 w-full md:static bg-white">
                <div className="md:px-[32px] md:py-[10px] px-[15px] py-[5px] sticky gap-[5px] top-0 bg-white z-40 flex items-center">
                    <button className="md:hidden" onClick={toggleSidebar}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4 8.5L20 8.5"
                                stroke="#141516"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M4 15.5L20 15.5"
                                stroke="#141516"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                    <input
                        type="search"
                        placeholder="Search for any product"
                        className="w-full font-light border-gray border outline-none focus:outline-none bg-transparent md:rounded-[12px] rounded-[8px] md:w-[400px] md:p-4 p-2"
                    />
                </div>
                {children}
            </div>
        </div>
    );
};

export default SideBar;

// const OldBar = () => (
//     <>
//         <div
//             className={`shrink-0 h-screen sticky top-0 border-r-1 border-r-gray  inset-y-0  transform  md:blocks transition-transform duration-300 ease-in-out md:transform-none bg-white w-64 md:w-[300px]  z-50  overflow-y-auto  // ${
//                 isOpen ? "translate-x-0" : "-translate-x-full"
//             }`}
//         >
//             <div className="flex items-center">
//                 <Link to="/">
//                     <div className="flex items-center">
//                         <div className="p-[24px]">
//                             <img src={logo} alt="" className="w-full" />
//                         </div>
//                     </div>
//                 </Link>
//                 <div className="xl:hidden flex mx-6">
//                     <h2 className="text-2xl">
//                         <GrClose onClick={toggleSidebar} />
//                     </h2>
//                 </div>
//             </div>

//             <div className="flex flex-col justify-between h-[90%]">
//                 <div className="flex flex-col">
//                     {DashBoardLinks.map((items) => (
//                         <div key={items.key} className="">
//                             <div
//                                 className={`fw-bold py-[9px] hover:bg-orange-100 hover:text-red ${
//                                     active === items.path
//                                         ? "text-red bg-orange-100"
//                                         : "text-dark-gray"
//                                 }`}
//                             >
//                                 <NavLink to={items.path}>
//                                     <div className="h-full flex items-center gap-[12px] px-[24px] py-2">
//                                         <div>
//                                             <h2 className="">{items.icon}</h2>
//                                         </div>
//                                         <div className="sm:flex">
//                                             <h2>{items.Label}</h2>
//                                         </div>
//                                     </div>
//                                 </NavLink>
//                             </div>
//                         </div>
//                     ))}
//                 </div>

//                 <div className="mx-[24px] flex flex-col gap-[35px] pb-[50px]">
//                     <div className="rounded-xl w-[100%] bg-orange-100 p-[13px] fw-bold">
//                         <h2 className="text-red text-[13px]">
//                             <MdHeadset className="inline-block" />
//                             <span className="mx-1 ">Need Help?</span>
//                         </h2>
//                         <p className="text-[13px]">
//                             Share your concerns with us <br /> via +234 9077883377 or <br />{" "}
//                             support@quickshop.com
//                         </p>
//                     </div>
//                     <button
//                         className=""
//                         onClick={() => {
//                             logout();
//                             navigate("/");
//                         }}
//                     >
//                         <h2 className="text-dark-gray fw-bold flex items-center gap-[10px]">
//                             <svg
//                                 width="20"
//                                 height="20"
//                                 viewBox="0 0 20 20"
//                                 fill="none"
//                                 xmlns="http://www.w3.org/2000/svg"
//                             >
//                                 <path
//                                     d="M7.4165 6.29922C7.67484 3.29922 9.2165 2.07422 12.5915 2.07422H12.6998C16.4248 2.07422 17.9165 3.56589 17.9165 7.29089V12.7242C17.9165 16.4492 16.4248 17.9409 12.6998 17.9409H12.5915C9.2415 17.9409 7.69984 16.7326 7.42484 13.7826"
//                                     stroke="#8C8D8E"
//                                     strokeWidth="1.5"
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                 />
//                                 <path
//                                     d="M12.4999 10H3.0166"
//                                     stroke="#8C8D8E"
//                                     strokeWidth="1.5"
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                 />
//                                 <path
//                                     d="M4.87516 7.20703L2.0835 9.9987L4.87516 12.7904"
//                                     stroke="#8C8D8E"
//                                     strokeWidth="1.5"
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                 />
//                             </svg>
//                             <span className="block">Logout</span>
//                         </h2>
//                     </button>
//                 </div>
//             </div>
//         </div>

//         <div className="h-screen flex-1 overflow-auto ">
//             <div className="px-[32px] py-[10px]">
//                 <input
//                     type="search"
//                     placeholder="Search for any product"
//                     className="w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px] md:w-[400px] p-4"
//                 />
//             </div>

//             {children}
//         </div>
//     </>
// );
