import React from "react";
import { useState } from "react";
import Profile from "../Profile/Profile";
import ShippingDetails from "../ShippingDetails/ShippingDetails";
import PaymentInfo from "../PaymentInfo/PaymentInfo";
import StoreDetails from "../StoreDetails/StoreDetails";
import { Link } from "react-router-dom";

const Settings = () => {
    const [selected, setSelected] = useState(1);
    // const navigate = useNavigate();
    const navs = [
        { name: "Profile", index: 1 },
        { name: "Shipping", index: 2 },
        { name: "Store Details", index: 3 },
        { name: "Payment Information", index: 4 },
    ];

    return (
        <div className="h-[95rem] md:[30rem] md:text-base text-sm">
            <div className="py-[48px] px-[40px] pb-4 text-2xl">
                <h2 className="fw-bold">Settings</h2>
            </div>
            <div className="md:mx-10 border-b-1 border-gray flex justify-between list-none px-[20px] md:px-0">
                <div className=" overflow-auto">
                    <ul className="flex cursor-pointer">
                        {navs.map((e) => (
                            <li
                                className={`whitespace-nowrap p-[16px] fw-bold  ${
                                    selected === e.index
                                        ? "text-red border-b-2 border-red"
                                        : "text-dark-gray border-b-2 border-b-transparent"
                                }`}
                                onClick={() => {
                                    setSelected(e.index);
                                }}
                            >
                                {e.name}
                            </li>
                        ))}
                    </ul>
                </div>
                {selected === 4 ? (
                    <Link
                        to="/payment"
                        className="bg-gradient-to-r from-red to-orange md:px-[16px] md:py-[8px]  w-[150px] flex items-center justify-center  text-white rounded-lg ml-[10px] md:ml-0 mb-[5px]"
                    >
                        Add New
                    </Link>
                ) : (
                    ""
                )}
            </div>
            <div className="md:px-[40px] px-[20px] py-[32px]">
                {selected === 1 && <Profile />}
                {selected === 2 && <ShippingDetails />}
                {selected === 3 && <StoreDetails />}
                {selected === 4 && <PaymentInfo />}
            </div>
        </div>
    );
};

export default Settings;
