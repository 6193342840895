/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useContext } from "react";
import { FaTimes } from "react-icons/fa";
import upload from "../Images/document-upload.png";
import upload2 from "../Images/Property 316.png";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context/Context";

const UploadRegister = () => {
    const navigate = useNavigate();
    const { userType: user } = useContext(Context);
    const [formData, setFormData] = useState({
        NIN: "",
        certificate_of_incorporation: null,
        store_logo: null,
        store_banner: null,
    });
    const merchant_id = user?.merchant_id?.id || "";

    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const logoInputRef = useRef(null);
    const storeBannerInputRef = useRef(null);
    const certificateInputRef = useRef(null);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (files) {
            setFormData((prevState) => ({
                ...prevState,
                [name]: files[0],
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    useEffect(() => {
        if (!merchant_id) {
            navigate("/login");
            return;
        }
        const checkFields = () => {
            for (let key in formData) {
                if (formData[key] === "" || formData[key] === null) {
                    setAllFieldsFilled(false);
                    return;
                }
            }
            setAllFieldsFilled(true);
        };
        checkFields();
    }, [formData]);

    const handleRemoveImage = (type) => {
        setFormData((prevState) => ({
            ...prevState,
            [type]: null,
        }));
        if (type === "store_logo" && logoInputRef.current) {
            logoInputRef.current.value = "";
        } else if (type === "store_banner" && storeBannerInputRef.current) {
            storeBannerInputRef.current.value = "";
        } else if (
            type === "certificate_of_incorporation" &&
            certificateInputRef.current
        ) {
            certificateInputRef.current.value = "";
        }
    };

    const validate = () => {
        let proceed = true;
        let errorMessage = "Please enter the value in ";

        if (formData.NIN.trim() === "") {
            proceed = false;
            errorMessage += "NIN ";
        }

        if (!proceed) {
            toast.warning(errorMessage);
        }

        return proceed;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");

        if (validate()) {
            try {
                const data = new FormData();
                Object.keys(formData).forEach((key) => {
                    data.append(key, formData[key]);
                });

                data.append("merchant_id", merchant_id);

                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/merchant/documents",
                    {
                        method: "POST",
                        headers: {
                            Accept: "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: data,
                    }
                );

                const result = await response.json();

                if (response.ok) {
                    toast.success("Document updated successfully");
                    navigate("/paymentInfo");
                } else if (response.status === 422) {
                    let errorMessage = Object.values(result.message)
                        .flat()
                        .join(", ");
                    toast.error(errorMessage);
                } else {
                    toast.error(result.message || "Document update failed");
                }
            } catch (error) {
                toast.error("Document update failed: " + error.message);
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            }
        }
    };

    return (
        <div className="md:flex-row flex flex-col justify-between">
            <div>
                <div className="2xl:mx-20 pt-20 pb-8 mx-4 text-center">
                    <h2 className="text-2xl fw-bold">Document Upload</h2>
                    <h3>Fill in these details to set up your store.</h3>
                </div>
                <div className="flex justify-between">
                    <form
                        className="2xl:mx-20 w-full mx-4 mb-20"
                        onSubmit={handleSubmit}
                    >
                        <div>
                            <div className="flex mt-6">
                                <div className="flex flex-col items-center  whitespace-nowrap w-[25%] ">
                                    <div className="flex">
                                        <h3 className="pb-2 text-sm fw-bold">
                                            Store logo
                                        </h3>
                                    </div>
                                    <div className="bg-skyBlue flex justify-center w-10 h-10 text-sm fw-bold rounded-full">
                                        {formData.store_logo ? (
                                            <img
                                                src={URL.createObjectURL(
                                                    formData.store_logo
                                                )}
                                                alt="Store Logo"
                                                className="object-cover w-full h-full rounded-full"
                                            />
                                        ) : (
                                            <h2 className="py-2 text-sm">LO</h2>
                                        )}
                                    </div>
                                </div>
                                <div className="border-1 border-gray w-full mx-2 rounded-lg">
                                    {formData.store_logo ? (
                                        <div className="flex items-center justify-between p-2">
                                            <span>
                                                {formData.store_logo.name}
                                            </span>
                                            <div className=" bg-[#EBEDEF] p-[3px] rounded-full">
                                                <FaTimes
                                                    className="text-md cursor-pointer"
                                                    onClick={() =>
                                                        handleRemoveImage(
                                                            "store_logo"
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="w-full">
                                            <div className="flex justify-center w-full pt-6">
                                                <label
                                                    htmlFor="store_logo"
                                                    className="cursor-pointer flex flex-col items-center"
                                                >
                                                    <img
                                                        src={upload}
                                                        alt=""
                                                        className="bg-[#FFF0EA] py-2 rounded-full px-2"
                                                    />
                                                    <input
                                                        type="file"
                                                        id="store_logo"
                                                        name="store_logo"
                                                        ref={logoInputRef}
                                                        className="hidden"
                                                        onChange={handleChange}
                                                    />
                                                    <h3 className="px-16 pb-6 text-center">
                                                        <span className="text-red">
                                                            Click to upload{" "}
                                                        </span>{" "}
                                                        or drag and drop Minimum
                                                        of 2MB required
                                                    </h3>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="flex mt-6">
                                <div className="flex flex-col items-center w-[25%] ">
                                    <div className=" flex w-full">
                                        <h3 className="whitespace-nowrap pb-2 text-sm fw-bold">
                                            Store Banner
                                        </h3>
                                    </div>
                                    <div className="bg-skyBlue flex justify-center w-10 h-10 text-sm fw-bold rounded-full">
                                        {formData.store_banner ? (
                                            <img
                                                src={URL.createObjectURL(
                                                    formData.store_banner
                                                )}
                                                alt="Store Banner"
                                                className="object-cover w-full h-full rounded-full"
                                            />
                                        ) : (
                                            <h2 className="py-2 text-sm">LO</h2>
                                        )}
                                    </div>
                                </div>
                                <div className="border-1 border-gray w-full mx-2 rounded-lg">
                                    {formData.store_banner ? (
                                        <div className="flex items-center justify-between p-2">
                                            <span>
                                                {formData.store_banner.name}
                                            </span>
                                            <div className=" bg-[#EBEDEF] p-[3px] rounded-full">
                                                <FaTimes
                                                    className="text-md cursor-pointer"
                                                    onClick={() =>
                                                        handleRemoveImage(
                                                            "store_banner"
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="w-full">
                                            <div className="flex justify-center w-full pt-6">
                                                <label
                                                    htmlFor="store_banner"
                                                    className="cursor-pointer"
                                                >
                                                    <img
                                                        src={upload}
                                                        alt=""
                                                        className="bg-[#FFF0EA] py-2 rounded-full px-2 flex flex-col items-center"
                                                    />
                                                    <input
                                                        type="file"
                                                        id="store_banner"
                                                        name="store_banner"
                                                        ref={
                                                            storeBannerInputRef
                                                        }
                                                        className="hidden"
                                                        onChange={handleChange}
                                                    />
                                                    <h3 className="px-16 pb-6 text-center">
                                                        <span className="text-red">
                                                            Click to upload{" "}
                                                        </span>{" "}
                                                        or drag and drop Minimum
                                                        of 2MB required
                                                    </h3>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mt-4">
                                <label htmlFor="NIN">
                                    National Identification Number (NIN)
                                </label>
                                <br />
                                <input
                                    type="text"
                                    className="border-1 border-gray h-22 w-full px-2 mt-4 mb-6 rounded-lg"
                                    name="NIN"
                                    value={formData.NIN}
                                    onChange={handleChange}
                                    placeholder="NIN"
                                />
                            </div>
                            <div className="mt-4">
                                <label htmlFor="certificate_of_incorporation">
                                    Upload Certificate of Incorporation
                                </label>
                                <br />
                                <div className="border-1 border-gray relative w-full px-2 mt-4 mb-6 rounded-lg">
                                    {formData.certificate_of_incorporation ? (
                                        <div className="flex items-center justify-between p-4 h-[5rem]">
                                            <span>
                                                {
                                                    formData
                                                        .certificate_of_incorporation
                                                        .name
                                                }
                                            </span>
                                            <div className=" bg-[#EBEDEF] p-[3px] rounded-full">
                                                <FaTimes
                                                    className="text-md cursor-pointer"
                                                    onClick={() =>
                                                        handleRemoveImage(
                                                            "certificate_of_incorporation"
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex justify-end pt-2">
                                            <label
                                                htmlFor="certificate_of_incorporation"
                                                className="cursor-pointer"
                                            >
                                                <img
                                                    src={upload2}
                                                    alt=""
                                                    className="px-2 py-2"
                                                />
                                                <input
                                                    type="file"
                                                    id="certificate_of_incorporation"
                                                    name="certificate_of_incorporation"
                                                    ref={certificateInputRef}
                                                    className="hidden"
                                                    onChange={handleChange}
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <button
                                    type="submit"
                                    className={`bg-darkBlue fw-bold py-3 h-22 rounded-lg text-white w-full ${
                                        allFieldsFilled
                                            ? "bg-gradient-to-r from-red to-orange"
                                            : "bg-orange-300"
                                    }`}
                                    disabled={!allFieldsFilled}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UploadRegister;
