/* eslint-disable react-hooks/exhaustive-deps */
import Navbar from "../Navbar/Navbar";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ProductCard from "../ProductCard";
import axios from "axios";
import { toast } from "react-toastify";

export default function MerchantStore() {
    const { storeId } = useParams();
    const [storeInfo, setStoreInfo] = useState({});
    const [products, setProducts] = useState([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        nextPageUrl: null,
        prevPageUrl: null,
        perPage: 12,
        hasMorePages: false,
    });

    const fetchStoreByName = async () => {
        try {
            const store = storeId.split("-").join(" ");
            const result = await axios.post(
                "https://getquickshop.online/api/v1/user/buyer/store/store-name",
                { store: store }
            );
            await fetchStoreInfo(result.data.data.id);
        } catch (error) {
            console.log(error);
        }
    };

    function canBeInteger(str) {
        const num = Number(str);
        return !isNaN(num) && Number.isInteger(num);
    }
    const fetchStoreInfo = async (id) => {
        try {
            const response = await axios.get(
                `https://getquickshop.online/api/v1/user/buyer/merchant-store/${id}?page=${pagination.currentPage}`
            );
            const data = response.data;
            setStoreInfo(data.data);
            setProducts(data.data.products);
            setPagination({
                currentPage: data.data.pagination.current_page,
                nextPageUrl: data.data.pagination.next_page_url,
                prevPageUrl: data.data.pagination.prev_page_url,
                perPage: data.data.pagination.per_page,
                hasMorePages: data.data.pagination.has_more_pages,
            });
        } catch (error) {
            toast.error("error fetching store info");
            console.error("Failed to fetch store information:", error);
        }
    };

    useEffect(() => {
        if (canBeInteger(storeId)) fetchStoreInfo(storeId);
        else fetchStoreByName();
    }, [storeId, pagination.currentPage]);

    // Function to handle page change
    const handlePageChange = (newPageUrl) => {
        if (newPageUrl) {
            // Extract the new page number from the URL and update the currentPage in the pagination state
            const url = new URL(newPageUrl);
            const newPage = url.searchParams.get("page");
            setPagination((prev) => ({
                ...prev,
                currentPage: Number(newPage),
            }));
        }
    };

    return (
        <div>
            <Navbar />
            <div>
                <div className="">
                    <img
                        src={storeInfo.store_banner}
                        alt="store banner"
                        className="w-full h-[160px] object-cover"
                    />
                    <img
                        src={storeInfo.store_logo}
                        alt="profile"
                        className="md:w-[80px] md:h-[80px] h-[60px] w-[60px] border-[2px] border-white rounded-full -mt-[40px] md:ml-[100px] ml-[20px] object-cover"
                    />
                </div>
                <div className="pl-[100px] mt-[14px]">
                    <h1 className="font-bold text-[18px] leading-[24.3px] mb-[10px]">
                        {storeInfo.business_name}
                    </h1>
                    <p className="font-normal text-[14px] leading-[18.9px] text-[#3C3D3E]">
                        {storeInfo.description}
                    </p>
                </div>
                <div className="md:px-[100px] px-[20px] py-[100px]">
                    <div className="grid md:grid-cols-4 grid-cols-2 md:gap-[37px] gap-[20px]">
                        {/* Call ProductCard here */}
                        {products.map((product) => {
                            return (
                                <ProductCard
                                    product={product}
                                    storeId={storeId}
                                    businessName={storeInfo.business_name}
                                    productName={product.product_name}
                                    productPrice={product.selling_price}
                                    storeName={storeInfo.business_name}
                                    productImage={
                                        product.product_images[0].images
                                    }
                                />
                            );
                        })}
                    </div>

                    <div className="flex items-center justify-center mt-[100px]">
                        {pagination.prevPageUrl && (
                            <button
                                className="flex gap-[12px] items-center"
                                onClick={() =>
                                    handlePageChange(pagination.prevPageUrl)
                                }
                            >
                                Previous Page
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M3.33203 10L16.6654 10"
                                        stroke="#8C8D8E"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M7.49867 14.1673C7.49867 14.1673 3.33204 11.0986 3.33203 10.0006C3.33202 8.90262 7.4987 5.83398 7.4987 5.83398"
                                        stroke="#8C8D8E"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        )}
                        {pagination.hasMorePages && pagination.nextPageUrl && (
                            <button
                                className="flex gap-[12px] items-center justify-end"
                                onClick={() =>
                                    handlePageChange(pagination.nextPageUrl)
                                }
                            >
                                Next Page
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M16.6654 10L3.33203 10"
                                        stroke="#141B34"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M12.5 14.1673C12.5 14.1673 16.6667 11.0986 16.6667 10.0006C16.6667 8.90262 12.5 5.83398 12.5 5.83398"
                                        stroke="#141B34"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
