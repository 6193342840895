import React from "react";
import verify from "../Images/verify.png";
import logo from "../Images/cN1g46Vw_400x400.png";
import { Link } from "react-router-dom";

const AccountCreated = () => {
  return (
    <div>
      <div className="flex items-center md:px-10 pt-4 px-2">
        <div>
          <img src={logo} alt="" className="w-12" />
        </div>
        <div>
          <h2 className="inline-block text-2xl font-bold">
            Quick<span className="text-red">Shop</span>
          </h2>
        </div>
      </div>
      <div className="mx-4">
        <div className="">
          <div className="flex justify-center pt-[3rem]">
            <img src={verify} alt="" />
          </div>
          <div className="text-center">
            <h2 className="py-4 text-2xl fw-bold">Hey,theshoeshop</h2>
            <h2 className="pb-6">
              Congratulations, your store has been successfully <br /> created.
              It’s time to expand your reach and make <br />
              more sales with Quickshop.
            </h2>
          </div>
        </div>
        <div className=" flex justify-center">
          <Link to="/uploadInfo">
            <button className="bg-gradient-to-r from-red to-orange py-8 px-24 mb-[1rem] text-white whitespace-nowrap rounded-lg ">
              Continue with Verification
            </button>
          </Link>
        </div>
        <div className="flex justify-center">
          <Link to="/dashboard">
            <button className="text-red px-32 py-8 bg-orange-100 rounded-lg whitespace-nowrap">
              skip this for later
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AccountCreated;
