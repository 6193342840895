import React, { useContext } from "react";
import { useState, useEffect, useCallback } from "react";
// import logo from "../Images/cN1g46Vw_400x400.png";
import logo from "../Images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context } from "../Context/Context";

const Login = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { loggedIn, userProfile } = useContext(Context);
    if (loggedIn) {
        navigate("/");
    }
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [isFormValid, setIsFormValid] = useState("");
    const { setLoggedIn } = useContext(Context);

    const valid = useCallback(() => {
        return email.trim() !== "" && password.trim() !== "";
    }, [email, password]);

    useEffect(() => {
        setIsFormValid(valid());
    }, [valid]);

    const proceedLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        let data = { email, password };
        if (validate()) {
            try {
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/login",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                        },
                        body: JSON.stringify(data),
                    }
                );

                const result = await response.json();
                if (response.ok) {
                    localStorage.clear();
                    setLoggedIn(true);
                    localStorage.setItem("authToken", result.data.access_token);
                    //   localStorage.setItem("user", JSON.stringify(result.data.user));
                    localStorage.setItem(
                        "firstName",
                        result.data.user.first_name
                    );
                    userProfile(result.data.user);
                    const userType = result.data.user.user_type;
                    if (typeof userType !== "string") {
                        navigate("/userType");
                        return;
                    }
                    if (userType === "buyer") navigate("/allProduct");
                    else navigate("/dashboard");
                    toast.success("Logged in successfully");
                } else if (response.status === 422) {
                    let errorMessage = Object.values(result.message)
                        .flat()
                        .join("");
                    toast.error(errorMessage);
                } else {
                    toast.error(result.message || "Login failed");
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast.error("Login failed");
                console.error("There was a problem with the fetch operation");
            }
        }
    };

    const validate = () => {
        let result = true;
        if (email === "" || email === null) {
            result = false;
            toast.warning("Please enter Email Address");
        }
        if (password === "" || password === null) {
            result = false;
            toast.warning("Please enter Password");
        }
        return result;
    };

    const [type, setType] = useState("password");
    const [icon, setIcon] = useState("Show");

    const handleToggle = () => {
        if (type === "password") {
            setIcon("Hide");
            setType("text");
        } else {
            setIcon("Show");
            setType("password");
        }
    };

    return (
        <div>
            <div className="md:px-[100px] px-[20px] flex items-center justify-between py-[20px]">
                <Link to="/">
                    <img src={logo} alt="" className="md:w-[200px] w-[100px]" />
                </Link>

                <div>
                    <Link
                        to="/signUp"
                        className="px-[23px] py-[11px] md:px-[37px] md:py-[11px] bg-[#FFF0EA] rounded-[12px] text-[#F90013] fw-bold block"
                    >
                        Create a Store
                    </Link>
                </div>
            </div>
            <div className="py-[120px] px-[20px] md:px-0 flex flex-col items-center mx-auto">
                <h2 className="mb-[60px] text-[24px] fw-bold">
                    Login in to your account{" "}
                </h2>
                <form onSubmit={proceedLogin} className="md:w-[400px]">
                    <div className="flex flex-col">
                        <div className="mb-[28px]">
                            <label
                                htmlFor="email"
                                className="block mb-3 fw-bold"
                            >
                                Your Email Address
                            </label>
                            <input
                                type="email"
                                name=""
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Your Email Address"
                                className="w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px]  p-4"
                            />
                        </div>
                        <div>
                            <div>
                                <div>
                                    <label
                                        htmlFor="Password"
                                        className="block mb-3 fw-bold"
                                    >
                                        Password
                                    </label>
                                    <input
                                        type={type}
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        name=""
                                        id=""
                                        placeholder="Your Password"
                                        className="w-full font-light  border-gray border outline-none focus:outline-none bg-transparent rounded-[12px] p-4"
                                    />
                                    <div className=" mr-14 flex float-right -mt-10 cursor-pointer">
                                        <span
                                            onClick={handleToggle}
                                            className="text-red absolute underline"
                                        >
                                            {icon}
                                        </span>
                                    </div>
                                </div>

                                <div className="flex justify-end gap-[5px] fw-bold text-[12px] mt-[16px] mb-[70px]">
                                    <Link to="/forgot">
                                        Forgot Password?{" "}
                                        <span className="text-red underline">
                                            Reset it Here
                                        </span>
                                    </Link>
                                </div>
                            </div>

                            <button
                                type="submit"
                                className={`fw-bold  w-full p-4 rounded-[12px] text-white ${
                                    isFormValid || !loading
                                        ? "bg-gradient-to-r from-red to-orange"
                                        : "bg-orange-300"
                                }`}
                                disabled={!isFormValid || loading}
                            >
                                {!loading ? "Log In" : "Processing..."}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
