/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useContext } from "react";
import { PiLineVertical } from "react-icons/pi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { Context } from "../Context/Context";
import useAuth from "../../hooks/useAuth";

const ProfileBuyer = () => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [image1, setImage1] = useState("");
    const imageRef1 = useRef();
    const { userType } = useContext(Context);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const { unauntheticated } = useAuth();
    const [formData, setFormData] = useState({
        first_name: userType.first_name || "",
        email: userType.email || "",
        phone_number: userType?.phone_number || "",
        last_name: userType?.last_name || "",
        profile_image: "",
        country_code: "+234",
    });

    useEffect(() => {
        const formValues = Object.values(formData);
        setIsFormComplete(formValues.every((value) => value.trim() !== ""));
    }, [formData]);

    const handleImage1 = (e) => {
        const file = e.target.files[0];
        setFormData((prevFormData) => ({
            ...prevFormData,
            profile_image: file,
        }));
        setImage1(URL.createObjectURL(file));
    };

    const resetImage1 = () => {
        setImage1("");
        setFormData((prevFormData) => ({
            ...prevFormData,
            profile_image: "",
        }));
        if (imageRef1.current) {
            imageRef1.current.value = null;
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePhoneChange = (phone_number, country_data) => {
        setFormData({
            ...formData,
            phone_number: phone_number,
            country_code: `+${country_data.dialCode}`,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");

        if (validate()) {
            try {
                const data = new FormData();
                Object.keys(formData).forEach((key) => {
                    data.append(key, formData[key]);
                });

                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/buyer/profile-update/28",
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        body: data,
                    }
                );
                const result = await response.json();
                unauntheticated(result);
                if (response.ok) {
                    toast.success("Profile updated successfully");
                    setPhoneNumbers([...phoneNumbers, formData.phone_number]);
                    resetForm();
                    resetImage1();
                    setIsDataFetched(true);
                } else if (!response.ok) {
                    let errorMessage = Object.values(response.message)
                        .flat()
                        .join(", ");
                    toast.error(errorMessage);
                } else {
                    toast.error(response.message || "Profile update failed");
                }
            } catch (error) {
                toast.error("Profile update failed: " + error.message);
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            }
        }
    };

    const validate = () => {
        let proceed = true;
        let errorMessage = "Please enter the value in ";

        if (formData.first_name.trim() === "") {
            proceed = false;
            errorMessage += "Your First Name ";
        }
        if (formData.last_name.trim() === "") {
            proceed = false;
            errorMessage += "Your Last Name ";
        }
        if (formData.email.trim() === "") {
            proceed = false;
            errorMessage += "Your Email Address ";
        }
        if (
            formData.phone_number === "" ||
            formData.phone_number.startsWith(formData.country_code)
        ) {
            proceed = false;
            errorMessage += "Your Phone number ";
            // } else if (phoneNumbers.includes(formData.phone_number)) {
            //     proceed = false;
            //     errorMessage += "Phone number already exists ";
        } else {
            setPhoneNumbers([...phoneNumbers, formData.phone_number]);
        }

        if (!proceed) {
            toast.warning(errorMessage);
        } else {
            if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
            ) {
                proceed = false;
                toast.warning("Please enter a valid email address");
            }
        }

        return proceed;
    };

    const resetForm = () => {
        setFormData({
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            profile_image: "",
            country_code: "+234",
        });
        setIsFormValid(false);
    };

    return (
        <div>
            <div className="md:mx-4">
                <div className="flex flex-col md:flex-row md:w-[50%] bg-white md:rounded-[12px] gap-[20px] md:p-[20px] p-[10px]">
                    <div className="border-1 w-full  border-gray md:rounded-[4px]">
                        <div className="border-b-1 border-gray md:p-[20px] p-[10px]">
                            <h2 className="fw-bold">Account Information</h2>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="w-full">
                                <div className="md:flex-row flex flex-col justify-center md:py-[16px] py-[8px]">
                                    <div
                                        onClick={() =>
                                            imageRef1.current.click()
                                        }
                                    >
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImage1}
                                            ref={imageRef1}
                                            hidden
                                        />
                                        {image1 ? (
                                            <div className=" w-full rounded-full">
                                                <img
                                                    src={image1}
                                                    alt="Profile"
                                                    className="w-10 h-16 rounded-full"
                                                />
                                            </div>
                                        ) : (
                                            ""
                                            // <div className=" flex flex-col items-center">
                                            //   <div className=" bg-skyBlue flex justify-center w-10 h-16 py-2 text-sm fw-bold rounded-full">
                                            //     <h2 className="bg-skyBlue rounded-full">LO</h2>
                                            //   </div>
                                            //   <div>
                                            //     <h2 className="text-red">Tap to change a photo</h2>
                                            //   </div>
                                            // </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="md:px-[20px] px-[10px] flex flex-col md:gap-[20px]">
                                <div>
                                    <label
                                        htmlFor="first_name"
                                        className="px-2  fw-bold inline-block mb-2"
                                    >
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleInputChange}
                                        placeholder=""
                                        className="w-[100%] h-22  border-1 border-gray mb-3 md:rounded-lg outline-none px-4"
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="last_name"
                                        className="px-2 fw-bold inline-block mb-2"
                                    >
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleInputChange}
                                        placeholder=""
                                        className="w-[100%] h-22 mb-3 md:rounded-lg border-1 border-gray outline-none px-4"
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="px-2 fw-bold inline-block mb-2"
                                    >
                                        Email Address
                                    </label>
                                    <input
                                        type="text"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder=""
                                        className="w-[100%] h-22 mb-3 md:rounded-lg border-1 border-gray outline-none px-4"
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="phone_number"
                                        name="phone_number"
                                        className="px-2 fw-bold inline-block mb-2"
                                    >
                                        Phone Number
                                    </label>
                                    <PhoneInput
                                        country={"ng"}
                                        containerStyle={{ height: "50px" }}
                                        placeholder=""
                                        value={formData.phone_number}
                                        onChange={handlePhoneChange}
                                        inputStyle={{
                                            height: "50px",
                                            width: "100%",
                                        }}
                                        inputClass="mb-3 md:rounded-lg border-gray bg-gray"
                                    />
                                </div>
                                <div className="w-full text-right py-[20px]">
                                    <button
                                        type="submit"
                                        className={`py-8 px-6 fw-bold inline text-white fw-bold rounded-xl ${
                                            isFormComplete || !isDataFetched
                                                ? "bg-gradient-to-r from-red to-orange"
                                                : "bg-orange-300"
                                        }`}
                                        // disabled={!isFormComplete }
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    {/* <div className="border-gray border-1 bg-white md:w-full md:rounded-[4px]">
                        <div className="border-b-1 border-gray md:p-[20px] p-[10px]">
                            <h2 className="fw-bold">Shipping Address</h2>
                        </div>
                        <>
                            {[1, 2].map((e, i) => (
                                <div
                                    className={`md:m-[20px] m-[10px] pb-[25px] ${
                                        i < 1 ? "border-b-1  border-b-gray" : ""
                                    } `}
                                >
                                    <h2 className="fw-bold">Olutade Adeboye</h2>
                                    <h3 className="leading-8">
                                        1a, abraham adesanya estate, ajah, eti-osa, lekki, lagos
                                    </h3>
                                    <h4>09034023722</h4>
                                    <div className="flex items-center mt-3 gap-[13px]">
                                        <button>Edit</button>
                                        <PiLineVertical className="text-gray fw-bold" />
                                        <button className="text-red">Delete</button>
                                    </div>
                                </div>
                            ))}
                        </>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ProfileBuyer;
