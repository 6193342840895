import axios from "axios";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Context } from "./Context/Context";
import { toast } from "react-toastify";

export default function ProductCard({
    productImage,
    productName,
    productPrice,
    storeName,
    storeId,
    product,
    businessName,
}) {
    const { header } = useContext(Context);
    const addToCart = async (id) => {
        const url = "https://getquickshop.online/api/v1/user/buyer/wishlist";
        try {
            await axios.post(
                url,
                { product_id: id.toString() },
                { headers: header }
            );
            toast.success("product added to wishlist");
        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response);
        }
    };

    return (
        <div className="flex flex-col ">
            <Link
                to={`/product/${productName.split(" ").join("-")}`}
                state={{ product, storeId, businessName }}
            >
                <div className="md:h-[260px] h-[100px] md:mb-[34px] mb-[15px]">
                    <img
                        src={productImage}
                        alt=""
                        className="w-full object-cover h-full  rounded-xl"
                    />
                </div>

                <h2 className="capitalize">{productName}</h2>
                <h2 className="fw-bold text-[14px] md:text-[20px] leading-10">
                    ₦{productPrice}
                </h2>
            </Link>
            <div className="mt-[15px] flex gap-[15px] items-center">
                {storeId && (
                    <h2>
                        By{" "}
                        <Link
                            state={{ id: storeId }}
                            to={`/merchant-store/${storeName
                                .split(" ")
                                .join("-")}`}
                        >
                            {storeName}
                        </Link>
                    </h2>
                )}
                {storeId && (
                    <div
                        className=" bg-orange-50 flex items-center rounded-full"
                        role="button"
                        onClick={() => addToCart(product.id)}
                    >
                        <div>
                            <h2 className="text-red">
                                <svg
                                    width="14"
                                    height="15"
                                    viewBox="0 0 14 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clip-rule="evenodd"
                                        d="M8.50333 2.20254C7.80934 1.09915 6.19018 1.09915 5.49619 2.20254L4.37101 3.99148C4.28871 4.12233 4.15672 4.21802 4.00174 4.25586L1.93292 4.76088C0.662571 5.07099 0.148832 6.6027 1.00331 7.60477L2.37672 9.21542C2.47753 9.33363 2.52672 9.48478 2.51563 9.63663L2.36221 11.7377C2.26651 13.0483 3.58966 13.9796 4.79543 13.4949L6.76942 12.7014C6.91699 12.6421 7.08253 12.6421 7.23009 12.7014L9.20409 13.4949C10.4099 13.9796 11.733 13.0483 11.6373 11.7377L11.4839 9.63663C11.4728 9.48478 11.522 9.33363 11.6228 9.21542L12.9962 7.60477C13.8507 6.6027 13.3369 5.07099 12.0666 4.76088L9.99778 4.25586C9.8428 4.21802 9.71081 4.12233 9.62851 3.99148L8.50333 2.20254ZM6.48375 2.82369C6.72026 2.44766 7.27925 2.44766 7.51576 2.82369L8.64094 4.61262C8.88603 5.00229 9.27413 5.28013 9.72111 5.38924L11.7899 5.89427C12.2304 6.0018 12.3898 6.51788 12.1085 6.84778L10.7351 8.45843C10.4362 8.80886 10.2868 9.26211 10.3203 9.7216L10.4737 11.8227C10.5049 12.2496 10.0659 12.5839 9.63922 12.4124L7.66523 11.6189C7.23843 11.4473 6.76109 11.4473 6.33429 11.6189L4.3603 12.4124C3.93361 12.5839 3.4946 12.2496 3.52578 11.8227L3.6792 9.7216C3.71275 9.26211 3.56328 8.80886 3.26446 8.45843L1.89105 6.84778C1.60974 6.51788 1.76908 6.0018 2.2096 5.89427L4.27841 5.38924C4.72539 5.28013 5.11349 5.00229 5.35858 4.61262L6.48375 2.82369Z"
                                        fill="#F90013"
                                    />
                                </svg>
                            </h2>
                        </div>
                        <h2>(0)</h2>
                    </div>
                )}
            </div>
        </div>
    );
}
