/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useCallback, useState } from "react";
import ProductSummary from "./ProductSummary";
import getUserId from "../api/getUserId";
import { useCheckout } from "../CheckoutContext";

export default function OrderSummary({ handlePayment, loading = false }) {
    const token = localStorage.getItem("authToken");
    const { items, totalPrice } = useCheckout();
    const subTotal = items.reduce(
        (acc, currentItem) =>
            acc + currentItem.selling_price * currentItem.quantity_sold,
        0
    );

    const applyCoupon = useCallback(async () => {
        try {
            const payload = {
                cart: items.map((item) => ({
                    product_id: item.id,
                    quantity: item.quantity_sold,
                    price: item.selling_price,
                })),
                "coupon-code": "coupon",
            };

            const response = await fetch(
                "https://getquickshop.online/api/v1/user/buyer/coupon-apply",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/vnd.api+json",
                        "Content-Type": "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            );
            const data = await response.json();
            return data;
        } catch (error) {
            console.log(error);
        }
    }, [items, token]);

    return (
        <div className="w-[350px] bg-white rounded-xl border border-gray p-[12px]">
            <div className="px-6 py-4 fw-bold">
                <h2>Order Summary</h2>
            </div>
            <div className="border-b-1 border-gray">
                {(() => {
                    const productSummaries = items.map((product) => {
                        const quantity = product.quantity_sold;
                        const productPrice =
                            Number(product.selling_price) * Number(quantity);
                        return (
                            <ProductSummary
                                key={product.id}
                                productName={product.product_name}
                                quantity={quantity}
                                productPrice={productPrice}
                                productImage={product.product_images[0].images}
                            />
                        );
                    });
                    return (
                        <>
                            {productSummaries}
                            <div className="flex justify-between px-6">
                                <div>
                                    <h2>Total</h2>
                                </div>
                                <div>
                                    <h2>&#8358;{subTotal}</h2>{" "}
                                    {/* Step 4: Use totalPrice */}
                                </div>
                            </div>
                            <div className="flex justify-between px-6 py-4">
                                <div>
                                    <h2>Total</h2>
                                </div>
                                <div>&#8358;{totalPrice}</div>
                            </div>
                        </>
                    );
                })()}
                {/* <div className="flex justify-between px-6 py-4">
          <div>
            <h2>Tax</h2>
          </div>
          <div>&#8358;{(getTotalPrice() * 0.05).toFixed(2)}</div>
        </div> */}
            </div>

            <div className="flex justify-center mt-6">
                <button
                    onClick={() => handlePayment()}
                    className="bg-gradient-to-r from-red to-orange text-white w-full py-8 mx-4 fw-bold rounded-lg"
                >
                    {loading ? "Loading payment modal" : "Complete Order"}
                </button>
            </div>
        </div>
    );
}
