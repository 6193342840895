/* eslint-disable no-unused-vars */
import axios from "axios";
import { useEffect, useState } from "react";

const useCountry = () => {
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [isFetched, setIsFetched] = useState(false);
    const [message, setMessage] = useState("");
    const getCountry = async () => {
        const getSession = sessionStorage.getItem("states");
        if (getSession) {
            setStates(JSON.parse(getSession));
            return;
        }

        const stateUrl = "https://countriesnow.space/api/v0.1/countries/states";
        const result = await axios.post(stateUrl, { country: "Nigeria" });
        if (!result.data.error) {
            sessionStorage.setItem(
                "states",
                JSON.stringify(result.data.data.states)
            );
            setStates(result.data.data);
            setIsFetched(true);
            return;
        }
        setMessage(result.data.msg);
    };

    const getCitites = async (state) => {
        const url =
            "https://countriesnow.space/api/v0.1/countries/state/cities";
        // const realState = state.split(" ")[0];
        const result = await axios.post(url, {
            country: "Nigeria",
            state: state,
        });
        if (!result.data.error) {
            setCities(result.data.data);
            return;
        }
    };

    useEffect(() => {
        getCountry();
        return () => getCountry();
    }, []);
    return { getCountry, states, message, cities, getCitites };
};
export default useCountry;
