/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { BiStoreAlt } from "react-icons/bi";
import { IoBagOutline, IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context/Context";

const MerchantDashBoard = ({ document, payment, store }) => {
    const businessType = localStorage.getItem("businessType");
    const navigate = useNavigate();
    const { userType: user } = useContext(Context);

    const handleStoreDetailsClick = () => {
        if (businessType === "registered") {
            navigate("/storeInfo");
        } else if (businessType === "non-registered") {
            navigate("/storeInfo2");
        }
    };
    const handlePaymentDetailsClick = () => {
        if (businessType === "registered") {
            navigate("/paymentInfo");
        } else if (businessType === "non-registered") {
            navigate("/paymentInfo2");
        }
    };
    const handleDocumentDetailsClick = () => {
        if (businessType === "registered") {
            navigate("/uploadInfo");
        } else if (businessType === "non-registered") {
            navigate("/uploadInfo2");
        }
    };

    return (
        <div className="bg-grey md:h-[60rem] h-[90rem]">
            <div className="w-full">
                <div className="">
                    <div className="2xl:mx-10 md:flex-row flex flex-col items-center justify-between py-10">
                        <div className="">
                            <h2 className="text-xl font-medium">
                                Let’s set up your store,{" "}
                            </h2>
                            <p>
                                Here are some things you need to do before you
                                can sell on Quickshop
                            </p>
                        </div>
                    </div>
                    <div className="2xl:mx-4 md:flex-row flex flex-col">
                        {document ? (
                            <div
                                onClick={handleDocumentDetailsClick}
                                className="rounded-2xl md:mx-4 mx-4 mt-4 bg-white cursor-pointer"
                            >
                                <div className=" p-4">
                                    <h2 className="bg-skyBlue pt-7 text-blue w-10 h-16 pl-5 text-lg rounded-full">
                                        <BiStoreAlt className="" />
                                    </h2>
                                    <h2 className="py-2 font-medium">
                                        Store Documents
                                    </h2>
                                    <p className="pb-4 text-sm">
                                        Update your store information so you can
                                        start selling your products.
                                    </p>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        {payment ? (
                            <div
                                onClick={handlePaymentDetailsClick}
                                className="cursor-pointer"
                            >
                                <div className="rounded-2xl md:mx-4 mx-4 mt-4 bg-white">
                                    <div className="p-4">
                                        <h2 className="bg-lightGreen pt-7 text-darkGreen w-10 h-16 pl-5 text-lg rounded-full">
                                            <IoBagOutline className="" />
                                        </h2>
                                        <h2 className="py-2 font-medium">
                                            Add Payment Information
                                        </h2>
                                        <p className="pb-4 text-sm">
                                            Verify your BVN and set up the
                                            account you will like to receive
                                            payments.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        {store ? (
                            <div>
                                <div
                                    className="rounded-2xl md:mx-4 mx-4 mt-4 bg-white cursor-pointer"
                                    onClick={handleStoreDetailsClick}
                                >
                                    <div className="p-4">
                                        <h2 className=" pt-7 text-red w-10 h-16 pl-5 text-lg bg-orange-100 rounded-full">
                                            <IoSettingsOutline className="" />
                                        </h2>
                                        <h2 className="py-2 font-medium">
                                            Store Setup
                                        </h2>
                                        <p className="pb-4 text-sm">
                                            Update your return policy and add
                                            social media <br />
                                            presence.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="2xl:mx-10 pb-4 mx-4 mt-6">
                        <h2 className="text-xl font-medium">
                            Recent Transactions
                        </h2>
                        <p className="pt-2">
                            Your store overview will appear here once you start
                            selling.
                        </p>
                    </div>
                    <div>
                        <div className="">
                            <div className=" h-[30rem] 2xl:mx-6 mx-4 rounded-md bg-white"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MerchantDashBoard;
