/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { RxSlash } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
// import image from "../Images/images.jpg";
import { GoCheckCircleFill } from "react-icons/go";
import axios from "axios";

const OrderView = (props) => {
    const { id } = useParams();
    const token = localStorage.getItem("authToken");
    const [orderDetail, setOrderDetail] = useState({});
    const header = {
        Accept: "application/vnd.api+json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
    };

    const getOrder = useCallback(async () => {
        const url = `https://getquickshop.online/api/v1/user/merchant/ords/${id}`;
        const res = await axios.get(url, { headers: header });
        setOrderDetail(res.data.data);
    }, [id]);

    const getOrderTimeLine = useCallback(async () => {
        const url = `https://getquickshop.online/api/v1/user/merchant/ords/track/${id}`;
        const res = await axios.get(url, { headers: header });
    }, [id]);

    useEffect(() => {
        getOrder();
        getOrderTimeLine();
    }, []);
    return (
        <div className="bg-grey  py-[100px] md:py-[10px] px-[20px] md:px-[100px] h-[90%] overflow-auto md:text-base text-sm">
            <div className="flex items-center md:mt-[24px] ">
                <Link to="/orders">Orders</Link>
                <RxSlash />
                <Link to="/Business" className="text-red">
                    Order {id}
                </Link>
            </div>

            <div>
                <div className="flex items-center justify-between mt-[21px]">
                    <h2 className="text-xl fw-bold">Order {id}</h2>
                    <div>
                        <select
                            name=""
                            id=""
                            className="bg-BlushBlue text-navy rounded-xl px-5 py-8 outline-none"
                        >
                            <option value="">Pending</option>
                            <option value="">Completed</option>
                        </select>
                    </div>
                </div>

                <div className="grid md:grid-cols-2 gap-5 py-[33px]">
                    <div className=" rounded-[12px] bg-white border border-[#EBEDEF] p-[20px] flex flex-col gap-4">
                        <h3 className="fw-bold mb-[12px]">Order Summary</h3>
                        <div className="pb-4 border-b border-b-[#EBEDEF]">
                            {orderDetail.attributes?.items.map((item) => (
                                <div className="flex items-center justify-between mb-[12px]">
                                    <h3 className="fw-bold">
                                        {item.product_name}
                                    </h3>
                                    <p>Quantity : {item.quantity}</p>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-between">
                            <h3>Date Placed</h3>
                            <div>{orderDetail.attributes?.created_at}</div>
                        </div>
                        <div className="flex justify-between">
                            <h2 className=" ">SubTotal</h2>
                            <div>
                                <h2>
                                    &#8358;
                                    {orderDetail.attributes?.subtotal}
                                </h2>
                            </div>
                        </div>
                        <div className="flex justify-between ">
                            <h3 className=" ">Shipping</h3>
                            <h4>
                                &#8358;
                                {orderDetail.attributes?.shipping_fee}
                            </h4>
                        </div>
                        <div className="flex justify-between">
                            <h5>Total</h5>
                            <div>
                                <h6>
                                    &#8358;
                                    {orderDetail.attributes?.total}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className=" rounded-[12px] bg-white border border-[#EBEDEF] p-[20px] flex flex-col gap-4">
                            <h2 className="mb-[12px] fw-bold">
                                Billing Details
                            </h2>
                            <div className="flex justify-between">
                                <h2>Customer Name</h2>
                                <h4>
                                    {
                                        orderDetail.attributes
                                            ?.shipping_information?.name
                                    }
                                </h4>
                            </div>
                            <div className="flex justify-between ">
                                <h3>Contact Number</h3>
                                <>
                                    {
                                        orderDetail.attributes
                                            ?.shipping_information
                                            ?.mobile_number
                                    }
                                </>
                            </div>
                            <div className="flex justify-between">
                                <h5>Address</h5>
                                <>
                                    {
                                        orderDetail.attributes
                                            ?.shipping_information
                                            ?.customer_shipping_address
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white rounded-xl border border-[#EBEDEF] p-[20px] flex flex-col gap-4">
                        <h2 className="fw-bold mb-3">Order Tracking Log</h2>
                        <div className="flex justify-between">
                            <h5>Estimated Delivery Date</h5>
                            <h2>24-Aug-2023</h2>
                        </div>
                        {Array(3)
                            .fill()
                            .map(() => (
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-[10px]">
                                        <GoCheckCircleFill className="text-green-100 inline-block " />
                                        <div>
                                            <h2>Order Received</h2>
                                            <p>Status was set to received</p>
                                        </div>
                                    </div>
                                    <h2>24-Aug-2023</h2>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderView;
