/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { RxSlash } from "react-icons/rx";
import Footer from "../Footer/Footer";
import { Context } from "../Context/Context";
import Navbar from "../Navbar/Navbar";
import { toast } from "react-toastify";
import axios from "axios";
import { PiArrowDownBold } from "react-icons/pi";

const ProductView = () => {
    const { id } = useParams();
    const [variantId, setVariantId] = useState();
    const [qunt, setQuant] = useState(0);
    const { getCartItem, token, header, productId: prd } = useContext(Context);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [product, setProduct] = useState({});

    const changeQuantity = (inc = true) => {
        let newC = qunt;
        if (inc) newC = qunt + 1;
        else newC = qunt - 1;
        if (newC < 1) newC = 0;
        setQuant(newC);
    };

    const [activeImg, setActiveImg] = useState();
    const [prodImage, setProdImage] = useState([]);
    const [prod, setProd] = useState("loading...");
    const getProductById = async (id) => {
        try {
            const res = await axios.get(
                `https://getquickshop.online/api/v1/user/buyer/product-details/${id}`,
                { headers: header }
            );
            const response = res.data.data.relationships.product_variant;
            setActiveImg(
                res?.data?.data?.relationships?.product_images?.[0]?.images
            );
            setProdImage(res?.data?.data?.relationships);
            if (response.length < 1) setProd("no option for this product");
            else setProd(response);
            setLoaded(true);
        } catch (error) {
            console.log(error);
        }
    };

    const getProductByName = async () => {
        try {
            const data = id.split("-").join(" ");
            const url = `https://getquickshop.online/api/v1/user/buyer/store/search/anything?search=${data}`;
            const result = await axios.get(url);
            const resp = result.data.data?.[0].products?.[0];
            console.log(resp);
            setProduct({ ...product, ...resp });
            await getProductById(resp.id);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProductByName();
    }, [id]);

    const handleFormSubmit = async (e) => {
        if (!token) {
            toast.warn("login to add cart");
            navigate("/login");
            return;
        }
        setLoading(true);
        if (qunt < 1) {
            toast.error("quantity is less than 1");
            setLoading(false);
            return;
        }
        const formData = new FormData();
        formData.append("product_id", product.id);
        formData.append("quantity", qunt);
        if (variantId) formData.append("product_variant_id", variantId);

        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/buyer/cart",
                {
                    method: "POST",
                    headers: {
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                }
            );
            await response.json();
            setLoading(false);
            await getCartItem(true);
            toast.success("item added to cart");
        } catch (error) {
            setLoading(false);
            console.error("Error:", error);
        }
        //size should be added but it is not defined
    };

    return (
        <>
            <Navbar />
            <div className="md:py-[40px] py-[100px]">
                <div>
                    <div className="flex px-[20px]  md:px-[100px] md:mx-auto">
                        <Link to="/">
                            Home{" "}
                            <span className="inline-block">
                                <RxSlash />
                            </span>
                        </Link>
                        <div>
                            shop{" "}
                            <span className=" inline-block">
                                <RxSlash />
                            </span>
                        </div>
                        <div className="text-red">{product?.product_name}</div>
                    </div>
                </div>

                <div className="md:px-[183px] px-[20px]  flex flex-col md:flex-row md:gap-[100px] gap-[36px] md:py-[100px] py-[36px]">
                    <div className="md:w-[40%] shrink-0">
                        <div className="main md:rounded-[20px] rounded-[12px] md:max-h-[500px] max-[200px] w-full overflow-hidden">
                            <img
                                src={activeImg}
                                alt=""
                                className="w-full object-cover h-full"
                            />
                        </div>

                        <div className="thumbnail flex md:gap-[25px] gap-[18px] md:py-[40px] py-[20px] flex-nowrap overflow-auto">
                            {prodImage?.product_images?.map((e) => (
                                <div
                                    className={`rounded-[12px] ${
                                        activeImg === e.images
                                            ? "border-orange "
                                            : "border-transparent"
                                    }   md:w-[120px] md:h-[120px] w-[80px] h-[80px] overflow-hidden border-2`}
                                    role="button"
                                >
                                    <img
                                        src={e.images}
                                        alt=""
                                        className="w-full object-cover h-full"
                                        onClick={() => setActiveImg(e.images)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col md:gap-[30px] gap-[20px] flex-1">
                        <p>
                            sold by{" "}
                            <Link
                                to={`/merchant-store/${prodImage?.seller?.business_name
                                    .split(" ")
                                    .join("-")}`}
                            >
                                <span className="text-red underline">
                                    {prodImage?.seller?.business_name}
                                </span>
                            </Link>
                        </p>
                        <div>
                            <p className="md:text-[24px] capitalize">
                                {product?.product_name}
                            </p>
                            <h4 className="fw-bold md:text-[24px] md:mt-[5px]">
                                &#8358;
                                {product?.selling_price?.toLocaleString()}
                            </h4>
                        </div>
                        <div className="md:py-[24px] py-[20px] border-y border-y-gray md:text-base  text-sm">
                            <p className="md:mb-[12px] mb-[8px]">Quantity</p>
                            <div className="flex items-center md:gap-[40px] gap-[20px]">
                                <div className="border border-gray rounded-[40px] p-[20px] grid grid-cols-3 text-center w-[150px] fw-bold">
                                    <span
                                        role="button"
                                        onClick={() => {
                                            changeQuantity(false);
                                        }}
                                    >
                                        -
                                    </span>
                                    <span>{qunt}</span>
                                    <span
                                        className="text-red"
                                        role="button"
                                        onClick={() => {
                                            changeQuantity();
                                        }}
                                    >
                                        +
                                    </span>
                                </div>
                                <p className="md:text-base text-sm">
                                    Only{" "}
                                    <span className="text-red">
                                        {product?.in_stock} item(s)
                                    </span>{" "}
                                    left <br /> Don't miss it
                                </p>
                            </div>
                        </div>
                        {loaded ? (
                            typeof prod == "string" ? (
                                "No additional option for this product"
                            ) : (
                                <div className="flex gap-[15px] items-center justify-between md:text-base  text-sm">
                                    <div className="w-full">
                                        <label
                                            htmlFor=""
                                            className="fw-bold mb-3 inline-block"
                                        >
                                            Color
                                        </label>
                                        <select
                                            className="p-[14px] border border-gray rounded-[12px] w-full max-w-xs"
                                            onChange={(e) =>
                                                setVariantId(e.target.value)
                                            }
                                        >
                                            <option>Color</option>
                                            {prod.map((e) =>
                                                e.product_attribute.map((k) =>
                                                    k.attribute_name !==
                                                    "Size" ? (
                                                        <option value={e.id}>
                                                            {k.value}
                                                        </option>
                                                    ) : (
                                                        ""
                                                    )
                                                )
                                            )}
                                        </select>
                                    </div>

                                    <div className="w-full">
                                        <label
                                            htmlFor=""
                                            className="fw-bold mb-3 inline-block"
                                        >
                                            Size
                                        </label>
                                        <select
                                            className="p-[14px] border border-gray rounded-[12px]  w-full max-w-xs"
                                            onChange={(e) =>
                                                setVariantId(e.target.value)
                                            }
                                        >
                                            <option>Size</option>
                                            {prod.map((e) =>
                                                e.product_attribute.map((k) =>
                                                    k.attribute_name ===
                                                    "Size" ? (
                                                        <option value={e.id}>
                                                            {k.value}
                                                        </option>
                                                    ) : (
                                                        ""
                                                    )
                                                )
                                            )}
                                        </select>
                                    </div>
                                </div>
                            )
                        ) : (
                            "Loading product option..."
                        )}
                        {loaded ? (
                            <button
                                className={`fw-bold  text-white p-[15px] rounded-[12px] ${
                                    loading ? "bg-orange-50" : "bg-red"
                                }`}
                                onClick={handleFormSubmit}
                                disabled={loading}
                            >
                                {loading ? "Processing..." : "Add to Cart"}
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};

export default ProductView;
