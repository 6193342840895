import React, { useContext, useEffect, useState } from "react";
import { HiMiniTrash } from "react-icons/hi2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Context } from "../Context/Context";

const WishList = ({ title }) => {
    const [wishList, setWishList] = useState([]);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState("WishList");
    const [loading, setLoading] = useState(false);
    const { getCartItem } = useContext(Context);

    const tabs = ["WishList"];

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        if (tab === "WishList") {
            fetchWishList();
        }
    };

    const fetchWishList = async () => {
        const token = localStorage.getItem("authToken");
        setLoading(true);

        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/buyer/wishlist",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            const data = responseData.data;

            if (Array.isArray(data)) {
                setWishList(data);
            } else {
                console.error("Expected an array but got:", data);
                setWishList([]);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    const moveToCart = async (orderId) => {
        const token = localStorage.getItem("authToken");
        try {
            const response = await fetch(
                `https://getquickshop.online/api/v1/user/buyer/wish_list/move-to-cart/${orderId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.ok) {
                toast.success("Moved to cart Sucessfully");
                getCartItem();
            } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            setWishList((prevWishList) =>
                prevWishList.filter((order) => order.id !== orderId)
            );
        } catch (error) {
            setError(error.message);
        }
    };

    const deleteWishlistItem = async (orderId) => {
        const token = localStorage.getItem("authToken");

        try {
            const response = await fetch(
                `https://getquickshop.online/api/v1/user/buyer/wishlist/${orderId}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.ok) {
                toast.success("Deleted from wishlist Successfully");
            } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            setWishList((prevWishList) =>
                prevWishList.filter((order) => order.id !== orderId)
            );
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchWishList();
        return () => fetchWishList();
    }, []);
    return (
        <div>
            <div className="w-full">
                <h2>{title}</h2>
                <div className="bg-white rounded-lg mx-4">
                    <div
                        role="tablist"
                        className=" flex border-b-1 border-b-gray tabs tabs-bordered"
                    >
                        {tabs.map((tab) => (
                            <button
                                key={tab}
                                type="button"
                                role="tab"
                                onClick={() => handleTabClick(tab)}
                                className={`p-[16px] fw-bold text-base border-b-2 border-red text-[#6D6D6D]`}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>

                    <div className="mx-3 py-5">
                        {activeTab === "WishList" && (
                            <>
                                {loading && <div>loading...</div>}
                                {error && <div>Error: {error}</div>}
                                {!loading && wishList.length === 0 ? (
                                    <div>No items in your wishlist.</div>
                                ) : (
                                    wishList.map((order) => (
                                        <div
                                            key={order.id}
                                            className="border-1 border-gray flex items-center justify-between p-2 my-2 bg-white rounded-lg"
                                        >
                                            <div className="flex items-center">
                                                <div className="">
                                                    <img
                                                        src={
                                                            order.relationships
                                                                .selected_product
                                                                .product_image
                                                                .images
                                                        }
                                                        alt=""
                                                        className="xl:w-24 2xl:h-[5rem] w-10 h-10 rounded-lg"
                                                    />
                                                </div>
                                                <div className="md:ml-4 whitespace-nowrap px-2">
                                                    <h2>store name</h2>
                                                    <p className="">
                                                        {
                                                            order.relationships
                                                                .selected_product
                                                                .product_name
                                                        }
                                                    </p>
                                                    <p className="">
                                                        {order.relationships[2]}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="md:px-[8rem]">
                                                <h2>
                                                    &#8358;
                                                    {
                                                        order.relationships
                                                            .selected_product
                                                            .product_price
                                                    }
                                                </h2>
                                            </div>
                                            <div className="md:pl-[6rem]">
                                                <button
                                                    className="text-red rounded-2xl whitespace-nowrap p-2 bg-orange-100"
                                                    onClick={() =>
                                                        moveToCart(order.id)
                                                    }
                                                >
                                                    Move to Cart
                                                </button>
                                                <div className="ml-[5rem] pt-2">
                                                    <h2>
                                                        <HiMiniTrash
                                                            onClick={() =>
                                                                deleteWishlistItem(
                                                                    order.id
                                                                )
                                                            }
                                                        />
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WishList;
