import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const Profile = () => {
    const [categories, setCategories] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isPasswordFormValid, setIsPasswordFormValid] = useState(false);
    const [business_name, setBusinessName] = useState("");
    const [category_id, setCategoryId] = useState("");
    const [email, setEmail] = useState("");
    const [phone_number, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const valid = useCallback(() => {
        return (
            business_name.trim() !== "" &&
            category_id.trim() !== "" &&
            email.trim() !== "" &&
            phone_number.trim() !== ""
        );
    }, [business_name, category_id, email, phone_number]);

    const validPasswordForm = useCallback(() => {
        return password.trim() !== "" && newPassword.trim() !== "";
    }, [password, newPassword]);

    useEffect(() => {
        setIsFormValid(valid());
    }, [valid]);

    useEffect(() => {
        setIsPasswordFormValid(validPasswordForm());
    }, [validPasswordForm]);

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        const category = sessionStorage.getItem("category");
        if (category) {
            setCategories(JSON.parse(category));
            return;
        }
        try {
            const response = await fetch("https://getquickshop.online/api/v1/admin/categories", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/vnd.api+json",
                },
            });

            const result = await response.json();
            if (response.ok) {
                sessionStorage.setItem("category", JSON.stringify(result.data));
                setCategories(result.data);
            } else {
                toast.error("Failed to fetch categories");
            }
        } catch (error) {
            toast.error("Failed to fetch categories");
            console.error("There was a problem with the fetch operation:", error);
        }
    };

    const validated = () => {
        let proceed = true;
        let errorMessage = "Please enter the value in ";

        if (password == null || password === "") {
            proceed = false;
            errorMessage += "Please enter your current password";
        }
        if (newPassword == null || newPassword === "") {
            proceed = false;
            errorMessage += "Please enter your new password";
        }

        if (!proceed) {
            toast.warning(errorMessage);
        }
        return proceed;
    };

    const handleForgot = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");
        const data = { password, newPassword };

        if (validated()) {
            try {
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/buyer/change_password",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(data),
                    },
                );

                const result = await response.json();

                if (response.ok) {
                    toast.success("Password updated successfully");
                    setPassword("");
                    setNewPassword("");
                } else if (response.status === 422) {
                    let errorMessage = Object.values(result.message).flat().join("");
                    toast.error(errorMessage);
                } else {
                    toast.error(result.message || "Password update failed");
                }
            } catch (error) {
                toast.error("Password update failed");
                console.error("There was a problem with the fetch operation:", error);
            }
        }
    };

    const validate = () => {
        let proceed = true;
        let errorMessage = "Please enter the value in ";

        if (business_name.trim() === "") {
            proceed = false;
            errorMessage += "Your business Name ";
        }
        if (category_id.trim() === "") {
            proceed = false;
            errorMessage += "Your Store Category ";
        }
        if (email.trim() === "") {
            proceed = false;
            errorMessage += "Your Email Address ";
        }
        if (phone_number.trim() === "") {
            proceed = false;
            errorMessage += "Your Phone Number";
        }
        if (!proceed) {
            toast.warning(errorMessage);
        }

        return proceed;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");
        const data = {
            business_name,
            category_id,
            email,
            phone_number,
        };

        if (validate()) {
            try {
                const response = await fetch(
                    "https://getquickshop.online/api/v1/user/merchant/profile",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                            Accept: "application/vnd.api+json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(data),
                    },
                );

                const result = await response.json();

                if (response.ok) {
                    toast.success("Profile updated successfully");
                    setBusinessName("");
                    setCategoryId("");
                    setEmail("");
                    setPhoneNumber("");
                } else if (response.status === 422) {
                    let errorMessage = Object.values(result.message).flat().join("");
                    toast.error(errorMessage);
                } else {
                    toast.error(result.message || "Profile update failed");
                }
            } catch (error) {
                toast.error("Profile update failed");
                console.error("There was a problem with the fetch operation:", error);
            }
        }
    };
    const handlePhoneChange = (value) => {
        setPhoneNumber("+" + value);
    };
    const toggleShowPassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    const toggleShowNewPassword = () => {
        setShowNewPassword((prevState) => !prevState);
    };

    return (
        <div className="">
            <div className="h-[80rem]">
                <div className="md:flex-row flex flex-col">
                    <div className="bg-white rounded-lg mb-[3rem] w-full">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="bg-white rounded-lg">
                                <div className="py-4">
                                    <h3 className="fw-bold mx-4">Basic Information</h3>
                                </div>
                            </div>
                            <div className="m-4">
                                <label htmlFor="business_name" className="fw-bold">
                                    Business Name
                                </label>
                                <br />
                                <input
                                    type="text"
                                    id="business_name"
                                    name="business_name"
                                    value={business_name}
                                    onChange={(e) => setBusinessName(e.target.value)}
                                    placeholder="Business Name"
                                    className="h-22 border-1 border-gray w-100 px-4 mt-2 rounded-lg outline-none"
                                />
                                <br />
                            </div>
                            <div className="m-4">
                                <div className="w-full">
                                    <label htmlFor="category_id" className="fw-bold">
                                        Store Category
                                    </label>
                                    <br />
                                    <select
                                        id="category_id"
                                        value={category_id}
                                        onChange={(e) => setCategoryId(e.target.value)}
                                        className="h-22 border-1 border-gray select w-full max-w-lg px-4 mt-2 mb-4 overflow-hidden rounded-md outline-none"
                                    >
                                        <option value="">Select a category</option>
                                        {categories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.cat_name}
                                            </option>
                                        ))}
                                    </select>
                                    <br />
                                </div>
                            </div>
                            <div className="m-4">
                                <label htmlFor="email" className="fw-bold">
                                    Email Address
                                </label>
                                <br />
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email Address"
                                    className="h-22 border-1 border-gray w-100 px-4 mt-2 rounded-lg outline-none"
                                />
                                <br />
                            </div>
                            <div className="m-4">
                                <label htmlFor="phone_number" className="fw-bold">
                                    Phone Number
                                </label>
                                <br />
                                <PhoneInput
                                    country={"ng"}
                                    containerStyle={{
                                        height: "50px",
                                        width: "100%",
                                        border: "1px solid red",
                                        overflow: "hidden",
                                        borderRadius: "9px",
                                        borderColor: "#DCDDDE",
                                    }}
                                    placeholder="Enter Phone Number"
                                    onChange={handlePhoneChange}
                                    value={phone_number}
                                    inputStyle={{
                                        height: "50px",
                                        width: "100%",
                                        border: "none",
                                    }}
                                />
                                <br />
                            </div>

                            <div className="fw-bold float-right mx-4 my-4 text-sm">
                                <button
                                    type="submit"
                                    className={`px-6 py-8 mt-4 mb-10 text-white bg-orange-300 rounded-lg ${
                                        isFormValid
                                            ? "bg-gradient-to-r from-red to-orange"
                                            : "bg-orange-300"
                                    }`}
                                    disabled={!isFormValid}
                                >
                                    {categories.length < 1 ? "Loading category" : "Save Changes"}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="w-full">
                        <div className="h-35 md:ml-4 bg-white rounded-lg">
                            <form action="" onSubmit={handleForgot}>
                                <h2 className="fw-bold py-2 mx-4">Password</h2>
                                <div className="mx-4">
                                    <label htmlFor="current_password" className="fw-bold">
                                        Current Password
                                    </label>
                                    <br />
                                    <div className="relative">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            id="current_password"
                                            name="current_password"
                                            placeholder="Current Password"
                                            className="h-22 border-1 border-gray w-100 px-2 my-2 rounded-lg outline-none"
                                        />
                                        <button
                                            type="button"
                                            onClick={toggleShowPassword}
                                            className="absolute inset-y-0 right-0 flex items-center px-4 text-red underline"
                                        >
                                            {showPassword ? "Hide" : "Show"}
                                        </button>
                                    </div>

                                    <br />
                                </div>
                                <div className="mx-4">
                                    <label htmlFor="new_password" className="fw-bold">
                                        New Password
                                    </label>
                                    <br />
                                    <div className="relative">
                                        <input
                                            type={showNewPassword ? "text" : "password"}
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            id="new_password"
                                            name="new_password"
                                            placeholder="New Password"
                                            className="h-22 border-1 border-gray w-100 px-2 mt-2 rounded-lg outline-none"
                                        />
                                        <button
                                            type="button"
                                            onClick={toggleShowNewPassword}
                                            className="absolute inset-y-0 right-0 flex items-center px-4 text-red underline"
                                        >
                                            {showNewPassword ? "Hide" : "Show"}
                                        </button>
                                    </div>

                                    <br />
                                </div>
                                <div className="float-right mx-4 mt-4 pb-[2rem]">
                                    <button
                                        type="submit"
                                        className={`${
                                            isPasswordFormValid
                                                ? "bg-gradient-to-r from-red to-orange"
                                                : "bg-orange-300"
                                        } fw-bold px-10 py-8 mb-10 text-white bg-orange-300 rounded-lg`}
                                        disabled={!isPasswordFormValid}
                                    >
                                        Update Password
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
