import { useState } from "react";

const useCategory = () => {
    const [categories, setCategories] = useState([]);
    const getCategories = async () => {
        const category = sessionStorage.getItem("category");
        if (category) {
            setCategories(JSON.parse(category));
            return;
        }
        try {
            const response = await fetch(
                `https://getquickshop.online/api/v1/admin/categories`
            );
            const data = await response.json();
            sessionStorage.setItem("category", JSON.stringify(data.data));
            setCategories(data.data);
        } catch (error) {
            console.log(error);
        }
    };

    return { categories, getCategories };
};

export default useCategory;
