export default function AddressCard({address}) {
  return (
    <div className="flex gap-[12px] w-full py-[14.5px] px-[16px] rounded-xl bg-[#FFF0EA] items-center">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="vuesax/linear/location">
          <g id="location">
            <path
              id="Vector"
              d="M8.00187 8.95297C9.15063 8.95297 10.0819 8.02172 10.0819 6.87297C10.0819 5.72422 9.15063 4.79297 8.00187 4.79297C6.85312 4.79297 5.92188 5.72422 5.92188 6.87297C5.92188 8.02172 6.85312 8.95297 8.00187 8.95297Z"
              stroke="#F90013"
            />
            <path
              id="Vector_2"
              d="M2.41184 5.66065C3.72517 -0.112681 12.2785 -0.106014 13.5852 5.66732C14.3518 9.05399 12.2452 11.9207 10.3985 13.694C9.0585 14.9873 6.9385 14.9873 5.59184 13.694C3.75184 11.9207 1.64517 9.04732 2.41184 5.66065Z"
              stroke="#F90013"
            />
          </g>
        </g>
      </svg>
      <h2 className="font-normal text-[14px] leading=[18.9px] text-[#B81B27]">{ address || "No address in use."}</h2>
    </div>
  );
}
