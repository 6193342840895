/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import logo from "../Images/cN1g46Vw_400x400.png";
import registered from "../Images/registered business.png";
import nonRegistered from "../Images/non registered business.png";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Business = () => {
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState("");

    const schema = yup.object().shape({
        BusinessType: yup.string().required("Business type is required"),
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data, e) => {
        e.preventDefault();

        const merchant_id = localStorage.getItem("merchant_id");

        const token = localStorage.getItem("authToken");

        const dataObj = { business_type: data.BusinessType };

        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/merchant",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(dataObj),
                }
            );

            if (!response.ok) {
                const errorMessage = await response.json();
            }

            const result = await response.json();

            if (data.BusinessType === "registered") {
                localStorage.setItem("merchant_id", result.data.id);
                toast.success("Business Type Selected successfully");
                navigate("/storeInfo");
            } else if (data.BusinessType === "non-registered") {
                localStorage.setItem("merchant_id", result.data.id);
                toast.success("Business Type Selected successfully");
                navigate("/storeInfo2");
            }
        } catch (error) {
            console.error("Error fetching business data:", error);
        }
    };
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div>
            <div>
                <div className="flex items-center justify-between 2xl:mx-24 mt-4 mx-2">
                    <Link to="/">
                        <div className="flex items-center">
                            <div>
                                <img src={logo} alt="" className="w-12" />
                            </div>
                            <div>
                                <h2 className="inline-block text-2xl font-bold">
                                    Quick<span className="text-red">Shop</span>
                                </h2>
                            </div>
                        </div>
                    </Link>

                    <div>
                        <Link to="/SignUp">
                            <button className="text-red  px-10 py-2 fw-bold bg-orange-100 rounded-lg">
                                Login
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className=" pt-10 text-center">
                <h2 className="text-2xl fw-bold">Select your business type</h2>
                <p className="pt-4">
                    Pick a business type and we will curate a great <br />{" "}
                    experience for your online store.
                </p>
            </div>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex justify-center mt-24 flex-col md:flex-row mx-4">
                    <div
                        className={`border-coolGray border-1 rounded-xl h-15 xl:w-40 2xl:w-34 w-100 md:w-80 2xl:mx-4 ${
                            selectedOption === "registered"
                                ? "border-1 border-red"
                                : "border-1 border-gray"
                        }`}
                    >
                        <div className="flex ml-6">
                            <div className="w-100 relative flex justify-center ">
                                <img
                                    src={registered}
                                    alt=""
                                    className="bg-lightGreen h-[100px] relative mt-8 rounded-full"
                                />
                            </div>
                            <div className="mt-10 ">
                                <input
                                    type="radio"
                                    name="radio-8"
                                    value="registered"
                                    className={` radio radio-error radio-sm mx-2 w-4 h-4 mr-4 ${
                                        selectedOption === "registered"
                                            ? "text-red"
                                            : ""
                                    }`}
                                    {...register("BusinessType")}
                                    onChange={handleOptionChange}
                                />
                            </div>
                        </div>

                        <div className="mt-4 text-center">
                            <h3 className="pt-2 text-lg fw-bold">
                                A Registered Business
                            </h3>
                            <h4>
                                This covers businesses with verified <br />{" "}
                                registration documents.
                            </h4>
                        </div>
                    </div>

                    <div
                        className={`border-coolGray border-1 rounded-xl xl:w-40 2xl:w-34 h-15 md:w-80 w-100 md:mx-4 mt-6 md:mt-0 ${
                            selectedOption === "non-registered"
                                ? "border-1 border-red"
                                : "border-1 border-gray"
                        }`}
                    >
                        <div className="flex ml-6">
                            <div className="w-100 relative flex justify-center">
                                <img
                                    src={nonRegistered}
                                    alt=""
                                    className=" bg-skyBlue mt-8 rounded-full h-[100px]"
                                />
                            </div>
                            <div className="mt-10 ">
                                <input
                                    type="radio"
                                    name="radio-8"
                                    value="non-registered"
                                    className={` radio radio-error radio-sm mx-2 w-4 h-4 mr-4 ${
                                        selectedOption === "non-registered"
                                            ? "text-red"
                                            : ""
                                    }`}
                                    {...register("BusinessType")}
                                    onChange={handleOptionChange}
                                />
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <h3 className="pt-2 text-lg fw-bold">
                                A Non-Registered Business
                            </h3>
                            <h4>
                                This covers sole proprietors, freelancers <br />{" "}
                                or Non-registered businesses.
                            </h4>
                        </div>
                    </div>
                    <div className="text-center text-red">
                        {errors.BusinessType && (
                            <span>{errors.BusinessType.message}</span>
                        )}
                    </div>
                </div>
                <div className="flex justify-center ">
                    <button
                        type="submit"
                        className={`2xl:w-40 xl:w-99 fw-bold md:w-60 text-white bg-orange-300 rounded-lg py-8 mx-4 mt-10 mb-48 w-full ${
                            selectedOption
                                ? "bg-gradient-to-r from-red to-orange"
                                : "bg-orange-300"
                        }`}
                        disabled={!selectedOption}
                    >
                        Continue
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Business;
