/* eslint-disable no-unused-vars */
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { useRef, useState, useEffect, useCallback } from "react";
import AttributeCard from "../AttributeCard";
import axios from "axios";
import { toast } from "react-toastify";

const AddToProduct = () => {
    const token = localStorage.getItem("authToken");
    const imageInputRef1 = useRef();
    const imageInputRef2 = useRef();
    const imageInputRef3 = useRef();
    const [imageUrl1, setImageUrl1] = useState("");
    const [imageUrl2, setImageUrl2] = useState("");
    const [imageUrl3, setImageUrl3] = useState("");
    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [isChecked, setIsChecked] = useState(0);
    const [inputDt, setInputDt] = useState("");
    const [firstFormSubmitted, setFirstFormSubmitted] = useState(false);
    const [variantId, setVariantId] = useState({});
    const [vartId, setVarId] = useState();

    const handleCategoryChange = (event) => {
        const categoryId = event.target.value;
        setSelectedCategoryId(categoryId);
        const selectedCategory = categories.find(
            (category) => category.id === categoryId
        );
        // Update subcategories based on the selected category
        if (selectedCategory) {
            setSubCategories(selectedCategory.relationship.sub_category);
        } else {
            setSubCategories([]);
        }
    };
    // Pickup Checkbox
    const handleCheckboxChange = (event) => {
        if (event.target.checked) setIsChecked(1);
        else setIsChecked(0);
    };

    const handleImageChange1 = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageUrl1(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageChange2 = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageUrl2(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageChange3 = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageUrl3(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const fetchCategory = useCallback(async () => {
        const response = await fetch(
            "https://getquickshop.online/api/v1/user/merchant/seller/prod-category",
            {
                method: "GET",
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();
        return data;
    }, [token]);

    useEffect(() => {
        fetchCategory()
            .then((data) => {
                setCategories(data?.data || []);
            })
            .catch((error) => console.error("Error:", error));
    }, [fetchCategory]);

    const [productId, setProductId] = useState("");
    const [loading, setLoading] = useState();
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData();
        const img1 = imageInputRef1.current.files[0];
        const img2 = imageInputRef1.current.files[0];
        const img3 = imageInputRef1.current.files[0];
        if (!img1 || !img2 || !img3) {
            toast.warn("upload product image");
            setLoading(false);
            return;
        }
        formData.append("images[]", img1);
        formData.append("images[]", img2);
        formData.append("images[]", img3);
        formData.append("product_name", event.target.product_name.value);
        formData.append("selling_price", event.target.selling_price.value);
        formData.append("discount_price", event.target.discount_price.value);
        formData.append("in_stock", event.target.in_stock.value);
        formData.append(
            "product_description",
            event.target.product_description.value
        );
        formData.append("category_id", event.target.category_id.value);
        formData.append("sub_category_id", event.target.sub_category_id.value);
        formData.append("enable_pickup", isChecked);
        formData.append("make_product_offline", 1);
        formData.append("tags", "a tag"); // to be updated
        if (event.target.brand.value)
            formData.append("brand_id", event.target.brand.value);

        try {
            setLoading(true);
            const result = await axios.post(
                "https://getquickshop.online/api/v1/user/merchant/seller/products",
                formData,
                {
                    headers: {
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success("product added successfully");
            setFirstFormSubmitted(true);
            setProductId(result.data.data.id);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const changeVariants = (e) => {
        setVariantId({ ...variantId, [e.target.name]: e.target.value });
    };

    const [stock, setStock] = useState({});
    const changeStock = (e) => {
        setStock({ ...stock, [e.target.name]: e.target.value });
    };

    const addVariant = async (event) => {
        event.preventDefault();
        try {
            setLoading(true);
            const attributes = Object.keys(variantId).map((key) => ({
                id: key,
                value: variantId[key],
            }));

            const result = await axios.post(
                "https://getquickshop.online/api/v1/user/merchant/prod/variant",
                {
                    prod_id: productId,
                    attribute: attributes,
                },
                {
                    headers: {
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/vnd.api+json",
                    },
                }
            );

            const inputs = document.querySelectorAll("input");
            inputs.forEach((input) => (input.value = ""));
            await addPrice(result.data.data.id);
            toast.success("success, add more variant");
        } catch (error) {
            console.log(error);
            toast.error(error);
        } finally {
            setLoading(false);
        }
    };

    const addPrice = async (id) => {
        const url = `https://getquickshop.online/api/v1/user/merchant/prod/variant/${id}`;
        try {
            await axios.put(url, stock, {
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/vnd.api+json",
                },
            });
        } catch (error) {
            console.log(error.response);
        }
    };

    const getAttributes = async () => {
        const attributeResponse = await fetch(
            "https://getquickshop.online/api/v1/user/merchant/seller/prod-attribute",
            {
                method: "GET",
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/vnd.api+json",
                },
            }
        );

        const attributeData = await attributeResponse.json();
        return attributeData;
    };

    const [attributes, setAttributes] = useState([]);
    const [brands, setBrands] = useState([]);

    const getBrand = async () => {
        const response = await axios.get(
            "https://getquickshop.online/api/v1/user/merchant/seller/prod-brand",
            {
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        setBrands(response?.data?.data || []);
    };

    useEffect(() => {
        getBrand();
        getAttributes()
            .then((data) => {
                setAttributes(data.data.attributes);
            })
            .catch((error) => console.error("Error:", error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="bg-grey px-[20px] md:px-[100px] md:py-10 py-10"
            id="AddToProduct"
        >
            <div className="text-dark-gray flex items-center">
                <div className="hover:text-red ">
                    <Link to="/Products">Products</Link>
                </div>
                {/* <div className=''> */}
                <span className="inline-block">
                    <IoIosArrowForward />
                </span>
                <div className="hover:text-red inline-block">
                    <Link to="/Business">Add a Product</Link>
                </div>
            </div>
            <div className="py-5  text-lg fw-bold">
                <h2>
                    {" "}
                    {firstFormSubmitted ? "Add Product Variant" : "Add Product"}
                </h2>
            </div>
            {firstFormSubmitted ? (
                <></>
            ) : (
                <div className=" bg-white rounded-lg p-5">
                    <h3 className="p-5 ">Upload Product Images</h3>
                    <div className=" flex md:flex-row gap-[10px] flex-col justify-center p-5">
                        <form className="border-gray h-30  border-2 border-dashed rounded-lg flex-1 shrink-0">
                            <input
                                type="file"
                                id="upload1"
                                accept="image/*"
                                className="input-field"
                                hidden
                                onChange={handleImageChange1}
                                ref={imageInputRef1}
                            />
                            {/* {show image if an image is selected} */}
                            {imageUrl1 && (
                                <img
                                    src={imageUrl1}
                                    alt="preview"
                                    className="object-cover w-full h-full"
                                />
                            )}
                            {/* {show input if no image is selected} */}
                            {!imageUrl1 && (
                                <>
                                    <div className=" flex justify-center mt-20">
                                        <div className="flex py-2 rounded-full">
                                            <span>
                                                <svg
                                                    width="36"
                                                    height="36"
                                                    viewBox="0 0 36 36"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="18"
                                                        cy="18"
                                                        r="18"
                                                        fill="#FFF0EA"
                                                    />
                                                    <path
                                                        d="M15.5007 22.168V17.168L13.834 18.8346"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M15.5 17.168L17.1667 18.8346"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M26.3327 16.3346V20.5013C26.3327 24.668 24.666 26.3346 20.4993 26.3346H15.4993C11.3327 26.3346 9.66602 24.668 9.66602 20.5013V15.5013C9.66602 11.3346 11.3327 9.66797 15.4993 9.66797H19.666"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M26.3327 16.3346H22.9993C20.4993 16.3346 19.666 15.5013 19.666 13.0013V9.66797L26.3327 16.3346Z"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <p className=" text-center">
                                        <span className="font-normal leading-[18.9px]">
                                            <label
                                                htmlFor="upload1"
                                                className="text-[#F90013] hover:cursor-pointer"
                                            >
                                                Click to Upload
                                            </label>{" "}
                                            or drag and drop
                                        </span>{" "}
                                    </p>
                                </>
                            )}
                        </form>
                        <form className="border-gray h-30  border-2 border-dashed rounded-lg flex-1 shrink-0">
                            <input
                                type="file"
                                id="upload2"
                                accept="image/*"
                                className="input-field"
                                onChange={handleImageChange2}
                                ref={imageInputRef2}
                                hidden
                            />
                            {/* {show image if an image is selected} */}
                            {imageUrl2 && (
                                <img
                                    src={imageUrl2}
                                    alt="preview"
                                    className="object-cover w-full h-full"
                                />
                            )}

                            {/* {show input if no image is selected} */}
                            {!imageUrl2 && (
                                <>
                                    <div className=" flex justify-center mt-20">
                                        <div className="flex py-2 rounded-full">
                                            <span>
                                                <svg
                                                    width="36"
                                                    height="36"
                                                    viewBox="0 0 36 36"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="18"
                                                        cy="18"
                                                        r="18"
                                                        fill="#FFF0EA"
                                                    />
                                                    <path
                                                        d="M15.5007 22.168V17.168L13.834 18.8346"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M15.5 17.168L17.1667 18.8346"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M26.3327 16.3346V20.5013C26.3327 24.668 24.666 26.3346 20.4993 26.3346H15.4993C11.3327 26.3346 9.66602 24.668 9.66602 20.5013V15.5013C9.66602 11.3346 11.3327 9.66797 15.4993 9.66797H19.666"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M26.3327 16.3346H22.9993C20.4993 16.3346 19.666 15.5013 19.666 13.0013V9.66797L26.3327 16.3346Z"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <p className=" text-center">
                                        <span className="font-normal  leading-[18.9px]">
                                            <label
                                                htmlFor="upload2"
                                                className="text-[#F90013] hover:cursor-pointer"
                                            >
                                                Click to Upload
                                            </label>{" "}
                                            or drag and drop
                                        </span>{" "}
                                    </p>
                                </>
                            )}
                        </form>
                        <form className="border-gray h-30  border-2 border-dashed rounded-lg flex-1 shrink-0">
                            <input
                                type="file"
                                id="upload3"
                                accept="image/*"
                                className="input-field"
                                hidden
                                onChange={handleImageChange3}
                                ref={imageInputRef3}
                            />
                            {/* {show image if an image is selected} */}
                            {imageUrl3 && (
                                <img
                                    src={imageUrl3}
                                    alt="preview"
                                    className="object-cover w-full h-full"
                                />
                            )}
                            {/* {show input if no image is selected} */}
                            {!imageUrl3 && (
                                <>
                                    <div className=" flex justify-center mt-20">
                                        <div className="flex py-2 rounded-full">
                                            <span>
                                                <svg
                                                    width="36"
                                                    height="36"
                                                    viewBox="0 0 36 36"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle
                                                        cx="18"
                                                        cy="18"
                                                        r="18"
                                                        fill="#FFF0EA"
                                                    />
                                                    <path
                                                        d="M15.5007 22.168V17.168L13.834 18.8346"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M15.5 17.168L17.1667 18.8346"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M26.3327 16.3346V20.5013C26.3327 24.668 24.666 26.3346 20.4993 26.3346H15.4993C11.3327 26.3346 9.66602 24.668 9.66602 20.5013V15.5013C9.66602 11.3346 11.3327 9.66797 15.4993 9.66797H19.666"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M26.3327 16.3346H22.9993C20.4993 16.3346 19.666 15.5013 19.666 13.0013V9.66797L26.3327 16.3346Z"
                                                        stroke="#F90013"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <p className=" text-center">
                                        <span className="font-normal  leading-[18.9px]">
                                            <label
                                                htmlFor="upload3"
                                                className="text-[#F90013] hover:cursor-pointer"
                                            >
                                                Click to Upload
                                            </label>{" "}
                                            or drag and drop
                                        </span>{" "}
                                    </p>
                                </>
                            )}
                        </form>
                    </div>
                </div>
            )}

            <div className="md:flex text-sm md:text-base flex-col md:flex-row  md:gap-[20px] gap-[10px] justify-between py-[20px]">
                {firstFormSubmitted ? (
                    <></>
                ) : (
                    <>
                        <div className="bg-white rounded-lg  p-[20px]">
                            <div className="w-full">
                                <h2 className="fw-bold  leading-[18.9px] text-[#3C3D3E] mb-[26px]">
                                    Product Details
                                </h2>
                                <form
                                    action=""
                                    onSubmit={handleSubmit}
                                    className="flex flex-col gap-[28px]"
                                >
                                    <label htmlFor="" className="w-full">
                                        <h2 className="fw-bold  leading-[18.9px] text-[#141516]">
                                            Product Name
                                        </h2>
                                        <input
                                            type="text"
                                            name="product_name"
                                            placeholder="Your Product Name"
                                            required={true}
                                            className="border border-[#DCDDDE] w-full h-[48px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none  focus:border-transparent"
                                        />
                                    </label>

                                    <label htmlFor="" className="w-full">
                                        <h2 className="fw-bold  leading-[18.9px] text-[#141516]">
                                            Product Description
                                        </h2>
                                        <textarea
                                            name="product_description"
                                            placeholder="A short description of your product"
                                            required={true}
                                            className="border border-[#DCDDDE] w-full h-[140px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none  focus:border-transparent"
                                        ></textarea>
                                    </label>

                                    <label htmlFor="" className="w-full">
                                        <h2 className="fw-bold  leading-[18.9px] text-[#141516]">
                                            Product Brand (optional)
                                        </h2>
                                        <select
                                            name="brand"
                                            className="border border-[#DCDDDE] w-full h-[55px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none focus:border-transparent"
                                        >
                                            <option value="">
                                                Select a Brand
                                            </option>
                                            {brands.length ? (
                                                brands.map((brd) => (
                                                    <option
                                                        key={
                                                            brd.attributes.name
                                                        }
                                                        value={brd.id}
                                                    >
                                                        {brd.attributes.name}
                                                    </option>
                                                ))
                                            ) : (
                                                <option></option>
                                            )}
                                        </select>
                                    </label>

                                    <label htmlFor="" className="w-full">
                                        <h2 className="fw-bold  leading-[18.9px] text-[#141516]">
                                            Product Category
                                        </h2>
                                        <select
                                            name="category_id"
                                            required={true}
                                            className="border border-[#DCDDDE] w-full h-[55px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none focus:border-transparent"
                                            onChange={handleCategoryChange}
                                        >
                                            <option value="">
                                                Select a Category
                                            </option>
                                            {categories.length > 0 ? (
                                                categories.map((category) => (
                                                    <option
                                                        key={category.id}
                                                        value={category.id}
                                                    >
                                                        {
                                                            category.attributes
                                                                .cat_name
                                                        }
                                                    </option>
                                                ))
                                            ) : (
                                                <option></option>
                                            )}
                                        </select>
                                    </label>

                                    <label htmlFor="" className="w-full">
                                        <h2 className="fw-bold t leading-[18.9px] text-[#141516]">
                                            Product Sub-Category (Optional)
                                        </h2>
                                        <select
                                            name="sub_category_id"
                                            required={true}
                                            className="border border-[#DCDDDE] w-full h-[55px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none  focus:border-transparent"
                                        >
                                            <option value="">
                                                Select a Sub-Category
                                            </option>
                                            {subCategories.map(
                                                (subCategory) => (
                                                    <option
                                                        key={subCategory.id}
                                                        value={subCategory.id}
                                                    >
                                                        {
                                                            subCategory.sub_category_name
                                                        }
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </label>

                                    <div className="flex justify-between gap-[22px]">
                                        <div>
                                            <label
                                                htmlFor=""
                                                className=" fw-bold"
                                            >
                                                Selling Price
                                            </label>
                                            <div className="flex border-1 outline-none border-[#DCDDDE] rounded-lg items-center w-full mt-[10px] ">
                                                <div className="border-r-1 border-[#DCDDDE] px-4">
                                                    <h2>&#8358;</h2>
                                                </div>
                                                <div>
                                                    <input
                                                        type="number"
                                                        name="selling_price"
                                                        required={true}
                                                        placeholder="Your Selling Price"
                                                        className="outline-none w-full h-[48px] pl-2"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <label
                                                htmlFor=""
                                                className="fw-bold"
                                            >
                                                Discount Price
                                            </label>
                                            <div className="flex border-1 outline-none border-[#DCDDDE] rounded-lg items-center w-full mt-[10px] ">
                                                <div className="border-r-1 border-[#DCDDDE] px-4">
                                                    <h2>&#8358;</h2>
                                                </div>
                                                <div>
                                                    <input
                                                        type="number"
                                                        name="discount_price"
                                                        required={true}
                                                        placeholder="Your Discount Price"
                                                        className="outline-none w-full h-[48px] pl-2"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <label htmlFor="" className="w-full">
                                        <h2 className="fw-bold  leading-[18.9px] text-[#141516]">
                                            Number of items in stock
                                        </h2>
                                        <input
                                            type="number"
                                            name="in_stock"
                                            required={true}
                                            placeholder="How many items do you have?"
                                            className="border border-[#DCDDDE] w-full h-[48px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none  focus:border-transparent"
                                        />
                                    </label>

                                    <div>
                                        <div className="flex justify-between">
                                            <div>
                                                <h1 className="fw-bold  leading-[18.9px] text-[#101928]">
                                                    Enable Pickup
                                                </h1>
                                                <p className="font-normal  leading-[18.9px] text-[#3C3D3E]">
                                                    Buyers will be able to
                                                    pickup from your address
                                                </p>
                                            </div>

                                            <input
                                                type="checkbox"
                                                name="enable_pickup"
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                                className="toggle checked:[--tglbg:#F90013] bg-white [--tglbg:#E4E7EC] border-[#E4E7EC] checked:bg-white checked:border-[#F90013]"
                                            />
                                        </div>
                                    </div>

                                    <button
                                        type="submit"
                                        className={`bg-gradient-to-r from-[#F90013] to-[#FF4800] flex items-center justify-center text-white font-bold leadng-[18.9px] h-[42px] rounded-xl px-[11.5px] py-[24px] `}
                                        disabled={loading}
                                    >
                                        {loading ? (
                                            <>
                                                <span>Adding Product...</span>
                                            </>
                                        ) : (
                                            "Add Product"
                                        )}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </>
                )}

                {firstFormSubmitted ? (
                    <div className="flex flex-col gap-[17px]">
                        <div className=" bg-white rounded-lg  p-[20px]">
                            <form
                                onSubmit={addVariant}
                                className="flex flex-col gap-[20px]"
                            >
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h2>Product Options</h2>
                                        <p>
                                            Use this if your product includes
                                            colors and sizes.
                                        </p>
                                    </div>
                                </div>

                                {/* Select options */}
                                <div className="flex flex-col  gap-4">
                                    <div className="flex flex-col gap-4">
                                        {attributes
                                            ? attributes.map((attribute) => (
                                                  <>
                                                      <div className="flex gap-5 items-center">
                                                          <p className="flex-1 fw-bold">
                                                              {
                                                                  attribute.attribute_name
                                                              }
                                                          </p>
                                                          <input
                                                              type="text"
                                                              name={
                                                                  attribute.id
                                                              }
                                                              onChange={
                                                                  changeVariants
                                                              }
                                                              placeholder={`Add ${attribute.attribute_name}`}
                                                              className="border shrink-0 flex-1 border-[#DCDDDE] w-full h-[48px] rounded-xl placeholder:text-[#8C8D8E] pl-[16px] py-[14px] focus:outline-none focus:border-transparent"
                                                          />
                                                      </div>
                                                  </>
                                              ))
                                            : ""}
                                        <div className="flex gap-5 items-center">
                                            <p className="flex-1 fw-bold">
                                                Total Stock
                                            </p>
                                            <input
                                                type="number"
                                                name="stock_count"
                                                onChange={changeStock}
                                                placeholder="Total Stock"
                                                className="border shrink-0 flex-1 border-[#DCDDDE] w-full h-[48px] rounded-xl placeholder:text-[#8C8D8E] pl-[16px] py-[14px] focus:outline-none focus:border-transparent"
                                            />
                                        </div>
                                        <div className="flex gap-5 items-center">
                                            <p className="flex-1 fw-bold">
                                                Price
                                            </p>
                                            <input
                                                type="text"
                                                name="price"
                                                onChange={changeStock}
                                                placeholder="Add Stock"
                                                className="border shrink-0 flex-1 border-[#DCDDDE] w-full h-[48px] rounded-xl placeholder:text-[#8C8D8E] pl-[16px] py-[14px] focus:outline-none focus:border-transparent"
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn bg-gradient-to-r w-full md:w-auto  from-[#F90013] to-[#FF4800] text-white"
                                            disabled={loading}
                                        >
                                            {loading ? "Processing..." : "Add"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="bg-white rounded-lg"></div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default AddToProduct;
