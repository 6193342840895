import React, { useState, useEffect, useRef, useContext } from "react";
import logo from "../Images/logo.png";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Context } from "../Context/Context";

const Verify = () => {
    const navigate = useNavigate();
    const { loggedIn } = useContext(Context);
    if (loggedIn) {
        navigate("/");
    }
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [isFormFilled, setIsFormFilled] = useState(false);
    const inputRefs = useRef([]);

    useEffect(() => {
        const filled = otp.every((value) => value.trim() !== "");
        setIsFormFilled(filled);
    }, [otp]);

    const fetchProtected = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem("authToken");
        const dataObj = { code: otp.join("") };

        try {
            const response = await fetch(
                "https://getquickshop.online/api/v1/user/verify",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(dataObj),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message || "Invalid code");
                return;
            }
            await response.json();
            toast.success("Verified Successfully");
            navigate("/userType");
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    const handleChange = (e, index) => {
        const newValue = e.target.value.replace(/\D/g, "");
        const newOtp = [
            ...otp.map((data, i) => (i === index ? newValue : data)),
        ];
        setOtp(newOtp);

        if (newValue.length === 0) {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        } else if (index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index]) {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    return (
        <div>
            <div className="px-[20px] md:px-[100px] flex items-center justify-between py-[20px]">
                <Link to="/">
                    <img src={logo} alt="" className="md:w-[200px] w-[100px]" />
                </Link>

                <div>
                    <Link
                        to="/login"
                        className="px-[23px] py-[11px] md:px-[37px] md:py-[11px] bg-[#FFF0EA] rounded-[12px] text-[#F90013] fw-bold block"
                    >
                        Login
                    </Link>
                </div>
            </div>
            <div className="py-[120px] flex flex-col items-center mx-auto">
                <h2 className="mb-[60px] text-center">
                    <span className="text-[24px] fw-bold">
                        Enter verification code
                    </span>
                    <span className="block">
                        We sent a code to your email <br /> Kindly enter the
                        code below.
                    </span>
                </h2>
                <form
                    onSubmit={fetchProtected}
                    className="grid grid-cols-6 gap-[10px] md:w-[400px]"
                >
                    {otp.map((data, i) => (
                        <input
                            key={i}
                            type="text"
                            name="otp"
                            value={data}
                            maxLength={1}
                            onChange={(e) => handleChange(e, i)}
                            // onFocus={(e) => e.target.select()}
                            className="font-light text-center border-gray border outline-none focus:outline-none bg-transparent rounded-[12px] p-4"
                            onKeyDown={(e) => handleKeyDown(e, i)}
                            ref={(el) => (inputRefs.current[i] = el)}
                        />
                    ))}
                    <button
                        type="submit"
                        className={`fw-bold mt-[28px] col-span-6  p-4 rounded-[12px] text-white ${
                            isFormFilled
                                ? "bg-gradient-to-r from-red to-orange"
                                : "bg-orange-300"
                        }`}
                    >
                        Continue
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Verify;
