import { IoStorefront } from "react-icons/io5";
import { GoCheckCircleFill } from "react-icons/go";
import { IoDocumentText } from "react-icons/io5";
import { MdPayments } from "react-icons/md";

export const Nav = [
    {
        id: 1,
        icon: <IoStorefront/>,
        title:"Store Information",
        goCheck: <GoCheckCircleFill/>,
        description: 'Fill basic details about your store accurately.',
        level: '1/3',
        path: '/storeInfo2'
        

    },
    {
        id: 2,
        icon: <IoDocumentText/>,
        title:"Document Upload",
        goCheck: <GoCheckCircleFill/>,
        description: 'Upload documents for your verification',
        level: '2/3',
        path: '/uploadInfo2'

    },
    {
        id: 3,
        icon: <MdPayments/>,
        title:"Payment Information",
        goCheck: <GoCheckCircleFill/>,
        description: 'Fill details for processing your payments.',
        level: '3/3',
        path: '/paymentInfo2'

    },
]