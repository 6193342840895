/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import { useRef, useState, useEffect, useCallback } from "react";
import AttributeCard from "../AttributeCard";
import axios from "axios";
import { toast } from "react-toastify";

const EditProduct = () => {
    const {
        state: { prd },
    } = useLocation();
    const token = localStorage.getItem("authToken");
    const imageInputRef1 = useRef();
    const imageInputRef2 = useRef();
    const imageInputRef3 = useRef();
    const [imageUrl1, setImageUrl1] = useState("");
    const [imageUrl2, setImageUrl2] = useState("");
    const [imageUrl3, setImageUrl3] = useState("");
    const [loading, setLoading] = useState(false);

    const [categories, setCategories] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState("");
    const [subCategories, setSubCategories] = useState([]);

    const [isChecked, setIsChecked] = useState(
        parseInt(prd?.attributes?.enable_pickup)
    );

    const [selectedColors, setSelectedColors] = useState([]);
    const [inputColor, setInputColor] = useState("");

    const [selectedSize, setSelectedSize] = useState([]);
    const [inputSize, setInputSize] = useState("");

    const [firstFormSubmitted, setFirstFormSubmitted] = useState(false);

    // Handle category change

    // Color Variant Section
    const handleInputChange = (event) => {
        setInputColor(event.target.value);
    };

    const handleColorChange = (event) => {
        setSelectedColors([...selectedColors, event.target.value]);
    };

    const handleColorRemove = (color) => {
        setSelectedColors(selectedColors.filter((c) => c !== color));
    };

    const handleColorInput = () => {
        setSelectedColors([...selectedColors, inputColor]);
        setInputColor("");
    };

    // Pickup Checkbox
    const handleCheckboxChange = (event) => {
        if (event.target.checked) setIsChecked(1);
        else setIsChecked(0);
    };

    const handleImageChange1 = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageUrl1(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageChange2 = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageUrl2(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleImageChange3 = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageUrl3(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const fetchCategory = async () => {
        const response = await fetch(
            "https://getquickshop.online/api/v1/user/merchant/seller/prod-category",
            {
                method: "GET",
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();
        setCategories(data.data);

        const subCat = data?.data?.find(
            (dt) => dt.id === prd?.relationships?.categoty?.id
        );
        setSubCategories(subCat?.relationship?.sub_category);
    };

    const handleCategoryChange = (event) => {
        const categoryId = event?.target?.value;
        setSelectedCategoryId(categoryId);
        // Find the selected category
        const selectedCategory = categories.find(
            (category) => category.id === categoryId
        );
        // Update subcategories based on the selected category
        if (selectedCategory) {
            setSubCategories(selectedCategory.relationship.sub_category);
        } else {
            setSubCategories([]);
        }
    };

    useEffect(() => {
        fetchCategory();
        return () => fetchCategory();
    }, []);

    const [productId, setProductId] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setFirstFormSubmitted(true);

        const formData = new FormData();
        // formData.append("images[]", imageInputRef1.current.files[0]);
        // formData.append("images[]", imageInputRef2.current.files[0]);
        // formData.append("images[]", imageInputRef3.current.files[0]);
        formData.append("prod_id", prd?.id);
        formData.append("product_name", event.target.product_name.value);
        formData.append("selling_price", event.target.selling_price.value);
        formData.append("discount_price", event.target.discount_price.value);
        formData.append("in_stock", event.target.in_stock.value);
        formData.append(
            "product_description",
            event.target.product_description.value
        );
        formData.append("category_id", event.target.category_id.value);
        formData.append("sub_category_id", event.target.sub_category_id.value);
        formData.append("enable_pickup", isChecked);
        formData.append("make_product_offline", 1);
        formData.append("tags", "a tag"); // to be updated
        // formData.append("brand_id", event.target.brand.value);

        try {
            const response = await axios.post(
                "https://getquickshop.online/api/v1/user/merchant/seller/products",
                formData,
                {
                    headers: {
                        Accept: "application/vnd.api+json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success("product updated successfully");
            return;
        } catch (err) {
            toast.error(err.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const handleVariantInput = async (event) => {
        event.preventDefault();

        const attributes = [
            ...selectedColors.map((color, index) => ({
                id: index.toString(),
                value: color,
            })),
            ...selectedSize.map((size, index) => ({
                id: (index + selectedColors.length).toString(),
                value: size,
            })),
        ];

        const response1 = await fetch(
            "https://getquickshop.online/api/v1/user/merchant/prod/variant",
            {
                method: "POST",
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/vnd.api+json",
                },
                body: JSON.stringify({
                    prod_id: productId,
                    attribute: attributes,
                }),
            }
        );

        const data = await response1.json();

        if (response1.ok) {
        } else {
        }
    };

    const getAttributes = async () => {
        const attributeResponse = await fetch(
            "https://getquickshop.online/api/v1/user/merchant/seller/prod-attribute",
            {
                method: "GET",
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/vnd.api+json",
                },
            }
        );

        const data = await attributeResponse.json();
        setAttributes(data.data.attributes);
    };

    const [attributes, setAttributes] = useState([]);
    const [brands, setBrands] = useState([]);

    const getBrand = async () => {
        const response = await axios.get(
            "https://getquickshop.online/api/v1/user/merchant/seller/prod-brand",
            {
                headers: {
                    Accept: "application/vnd.api+json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        setBrands(response.data.data);
    };

    useEffect(() => {
        return () => {
            getBrand();
            getAttributes();
        };
    }, []);

    return (
        <div
            className="bg-grey px-[20px] md:px-[100px] md:py-10 py-10"
            id="AddToProduct"
        >
            <div className="text-dark-gray flex items-center">
                <div className="hover:text-red ">
                    <Link to="/Products">Products</Link>
                </div>
                {/* <div className=''> */}
                <span className="inline-block">
                    <IoIosArrowForward />
                </span>
                <div className="hover:text-red inline-block">
                    <Link to="/Business">Update Product</Link>
                </div>
            </div>
            <div className="py-5  text-lg fw-bold">
                <h2>Update Product</h2>
            </div>
            {/* <div className=" bg-white rounded-lg p-5">
                <h3 className="p-5 ">Upload Product Images</h3>
                <div className=" flex md:flex-row gap-[10px] flex-col justify-center p-5">
                    <form className="border-gray h-30  border-2 border-dashed rounded-lg flex-1 shrink-0">
                        <input
                            type="file"
                            id="upload1"
                            accept="image/*"
                            className="input-field"
                            hidden
                            onChange={handleImageChange1}
                            ref={imageInputRef1}
                        />
                        {imageUrl1 && (
                            <img
                                src={imageUrl1}
                                alt="preview"
                                className="object-cover w-full h-full"
                            />
                        )}
                        {!imageUrl1 && (
                            <>
                                <div className=" flex justify-center mt-20">
                                    <div className="flex py-2 rounded-full">
                                        <span>
                                            <svg
                                                width="36"
                                                height="36"
                                                viewBox="0 0 36 36"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx="18" cy="18" r="18" fill="#FFF0EA" />
                                                <path
                                                    d="M15.5007 22.168V17.168L13.834 18.8346"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M15.5 17.168L17.1667 18.8346"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M26.3327 16.3346V20.5013C26.3327 24.668 24.666 26.3346 20.4993 26.3346H15.4993C11.3327 26.3346 9.66602 24.668 9.66602 20.5013V15.5013C9.66602 11.3346 11.3327 9.66797 15.4993 9.66797H19.666"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M26.3327 16.3346H22.9993C20.4993 16.3346 19.666 15.5013 19.666 13.0013V9.66797L26.3327 16.3346Z"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <p className=" text-center">
                                    <span className="font-normal leading-[18.9px]">
                                        <label
                                            htmlFor="upload1"
                                            className="text-[#F90013] hover:cursor-pointer"
                                        >
                                            Click to Upload
                                        </label>{" "}
                                        or drag and drop
                                    </span>{" "}
                                </p>
                            </>
                        )}
                    </form>
                    <form className="border-gray h-30  border-2 border-dashed rounded-lg flex-1 shrink-0">
                        <input
                            type="file"
                            id="upload2"
                            accept="image/*"
                            className="input-field"
                            onChange={handleImageChange2}
                            ref={imageInputRef2}
                            hidden
                        />
                        {imageUrl2 && (
                            <img
                                src={imageUrl2}
                                alt="preview"
                                className="object-cover w-full h-full"
                            />
                        )}

                        {!imageUrl2 && (
                            <>
                                <div className=" flex justify-center mt-20">
                                    <div className="flex py-2 rounded-full">
                                        <span>
                                            <svg
                                                width="36"
                                                height="36"
                                                viewBox="0 0 36 36"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx="18" cy="18" r="18" fill="#FFF0EA" />
                                                <path
                                                    d="M15.5007 22.168V17.168L13.834 18.8346"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M15.5 17.168L17.1667 18.8346"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M26.3327 16.3346V20.5013C26.3327 24.668 24.666 26.3346 20.4993 26.3346H15.4993C11.3327 26.3346 9.66602 24.668 9.66602 20.5013V15.5013C9.66602 11.3346 11.3327 9.66797 15.4993 9.66797H19.666"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M26.3327 16.3346H22.9993C20.4993 16.3346 19.666 15.5013 19.666 13.0013V9.66797L26.3327 16.3346Z"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <p className=" text-center">
                                    <span className="font-normal  leading-[18.9px]">
                                        <label
                                            htmlFor="upload2"
                                            className="text-[#F90013] hover:cursor-pointer"
                                        >
                                            Click to Upload
                                        </label>{" "}
                                        or drag and drop
                                    </span>{" "}
                                </p>
                            </>
                        )}
                    </form>
                    <form className="border-gray h-30  border-2 border-dashed rounded-lg flex-1 shrink-0">
                        <input
                            type="file"
                            id="upload3"
                            accept="image/*"
                            className="input-field"
                            hidden
                            onChange={handleImageChange3}
                            ref={imageInputRef3}
                        />
                        {imageUrl3 && (
                            <img
                                src={imageUrl3}
                                alt="preview"
                                className="object-cover w-full h-full"
                            />
                        )}
                        {!imageUrl3 && (
                            <>
                                <div className=" flex justify-center mt-20">
                                    <div className="flex py-2 rounded-full">
                                        <span>
                                            <svg
                                                width="36"
                                                height="36"
                                                viewBox="0 0 36 36"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle cx="18" cy="18" r="18" fill="#FFF0EA" />
                                                <path
                                                    d="M15.5007 22.168V17.168L13.834 18.8346"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M15.5 17.168L17.1667 18.8346"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M26.3327 16.3346V20.5013C26.3327 24.668 24.666 26.3346 20.4993 26.3346H15.4993C11.3327 26.3346 9.66602 24.668 9.66602 20.5013V15.5013C9.66602 11.3346 11.3327 9.66797 15.4993 9.66797H19.666"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M26.3327 16.3346H22.9993C20.4993 16.3346 19.666 15.5013 19.666 13.0013V9.66797L26.3327 16.3346Z"
                                                    stroke="#F90013"
                                                    strokeWidth="1.5"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                                <p className=" text-center">
                                    <span className="font-normal  leading-[18.9px]">
                                        <label
                                            htmlFor="upload3"
                                            className="text-[#F90013] hover:cursor-pointer"
                                        >
                                            Click to Upload
                                        </label>{" "}
                                        or drag and drop
                                    </span>{" "}
                                </p>
                            </>
                        )}
                    </form>
                </div>
            </div> */}

            <div className="md:flex text-sm md:text-base flex-col md:flex-row  md:gap-[20px] gap-[10px] justify-between py-[20px]">
                <div className="bg-white rounded-lg  p-[20px]">
                    <div className="w-full">
                        <h2 className="fw-bold  leading-[18.9px] text-[#3C3D3E] mb-[26px]">
                            Product Details
                        </h2>
                        <form
                            action=""
                            onSubmit={handleSubmit}
                            className="flex flex-col gap-[28px]"
                        >
                            <label htmlFor="" className="w-full">
                                <h2 className="fw-bold  leading-[18.9px] text-[#141516]">
                                    Product Name
                                </h2>
                                <input
                                    type="text"
                                    name="product_name"
                                    placeholder="Your Product Name"
                                    required={true}
                                    defaultValue={prd?.attributes?.product_name}
                                    className="border border-[#DCDDDE] w-full h-[48px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none  focus:border-transparent"
                                />
                            </label>

                            <label htmlFor="" className="w-full">
                                <h2 className="fw-bold  leading-[18.9px] text-[#141516]">
                                    Product Description
                                </h2>
                                <textarea
                                    name="product_description"
                                    placeholder="A short description of your product"
                                    required={true}
                                    defaultValue={
                                        prd?.attributes?.product_description
                                    }
                                    className="border border-[#DCDDDE] w-full h-[140px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none  focus:border-transparent"
                                ></textarea>
                            </label>

                            {/* <label htmlFor="" className="w-full">
                                <h2 className="fw-bold  leading-[18.9px] text-[#141516]">
                                    Product Brand (optional)
                                </h2>
                                <select
                                    name="brand"
                                    required={true}
                                    // defaultValue={prd?.attributes?.product_description}
                                    className="border border-[#DCDDDE] w-full h-[55px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none focus:border-transparent"
                                >
                                    <option value="">Select a Brand</option>
                                    {brands.map((brd) => (
                                        <option key={brd.attributes.name} value={brd.id}>
                                            {brd.attributes.name}
                                        </option>
                                    ))}
                                </select>
                            </label> */}

                            <label htmlFor="" className="w-full">
                                <h2 className="fw-bold  leading-[18.9px] text-[#141516]">
                                    Product Category
                                </h2>
                                <select
                                    name="category_id"
                                    required={true}
                                    className="border border-[#DCDDDE] w-full h-[55px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none focus:border-transparent"
                                    onChange={handleCategoryChange}
                                >
                                    <option value="">Select a Category</option>
                                    {categories.map((category) => (
                                        <option
                                            selected={
                                                prd?.relationships?.categoty
                                                    ?.id === category.id
                                            }
                                            key={category.id}
                                            value={category.id}
                                        >
                                            {category.attributes.cat_name}
                                        </option>
                                    ))}
                                </select>
                            </label>

                            <label htmlFor="" className="w-full">
                                <h2 className="fw-bold t leading-[18.9px] text-[#141516]">
                                    Product Sub-Category (Optional)
                                </h2>
                                <select
                                    name="sub_category_id"
                                    required={true}
                                    className="border border-[#DCDDDE] w-full h-[55px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none  focus:border-transparent"
                                >
                                    <option value="">
                                        Select a Sub-Category
                                    </option>
                                    {subCategories.map((subCategory) => (
                                        <option
                                            key={subCategory.id}
                                            value={subCategory.id}
                                            selected={
                                                prd?.relationships?.sub_categoty
                                                    ?.id == subCategory.id
                                            }
                                        >
                                            {subCategory.sub_category_name}
                                        </option>
                                    ))}
                                </select>
                            </label>

                            <div className="flex justify-between gap-[22px]">
                                <div>
                                    <label htmlFor="" className=" fw-bold">
                                        Selling Price
                                    </label>
                                    <div className="flex border-1 outline-none border-[#DCDDDE] rounded-lg items-center w-full mt-[10px] ">
                                        <div className="border-r-1 border-[#DCDDDE] px-4">
                                            <h2>&#8358;</h2>
                                        </div>
                                        <div>
                                            <input
                                                type="number"
                                                name="selling_price"
                                                defaultValue={
                                                    prd?.attributes
                                                        ?.selling_price
                                                }
                                                required={true}
                                                placeholder="Your Selling Price"
                                                className="outline-none w-full h-[48px] pl-2"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="" className="fw-bold">
                                        Discount Price
                                    </label>
                                    <div className="flex border-1 outline-none border-[#DCDDDE] rounded-lg items-center w-full mt-[10px] ">
                                        <div className="border-r-1 border-[#DCDDDE] px-4">
                                            <h2>&#8358;</h2>
                                        </div>
                                        <div>
                                            <input
                                                type="number"
                                                name="discount_price"
                                                defaultValue={
                                                    prd?.attributes
                                                        ?.discount_price
                                                }
                                                required={true}
                                                placeholder="Your Discount Price"
                                                className="outline-none w-full h-[48px] pl-2"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <label htmlFor="" className="w-full">
                                <h2 className="fw-bold  leading-[18.9px] text-[#141516]">
                                    Number of items in stock
                                </h2>
                                <input
                                    type="number"
                                    name="in_stock"
                                    required={true}
                                    defaultValue={prd?.attributes?.in_stock}
                                    placeholder="How many items do you have?"
                                    className="border border-[#DCDDDE] w-full h-[48px] rounded-xl placeholder:text-[#8C8D8E] mt-[10px] pl-[16px] py-[15px] focus:outline-none  focus:border-transparent"
                                />
                            </label>

                            <div>
                                <div className="flex justify-between">
                                    <div>
                                        <h1 className="fw-bold  leading-[18.9px] text-[#101928]">
                                            Enable Pickup
                                        </h1>
                                        <p className="font-normal  leading-[18.9px] text-[#3C3D3E]">
                                            Buyers will be able to pickup from
                                            your address
                                        </p>
                                    </div>

                                    <input
                                        type="checkbox"
                                        name="enable_pickup"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        className="toggle checked:[--tglbg:#F90013] bg-white [--tglbg:#E4E7EC] border-[#E4E7EC] checked:bg-white checked:border-[#F90013]"
                                    />
                                </div>
                            </div>

                            <button
                                type="submit"
                                className=" p-5 rounded-lg text-white fw-bold bg-gradient-to-r from-[#F90013] to-[#FF4800]"
                                disabled={loading}
                            >
                                {loading ? "processing" : " Update Product"}
                            </button>
                        </form>
                    </div>
                </div>

                <div className="flex flex-col gap-[17px]">
                    {/* <div className=" bg-white rounded-lg  p-[20px]">
                        <fieldset disabled={!firstFormSubmitted}>
                            <form
                                onSubmit={handleVariantInput}
                                className="flex flex-col gap-[20px]"
                            >
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h2>Product Options</h2>
                                        <p>Use this if your product includes colors and sizes.</p>
                                    </div>
                                    <button
                                        type="submit"
                                        className="flex items-center 6ext-white  justify-center text-white font-bold  leadng-[18.9px] h-[28px] w-[53px] rounded-lg px-[6px] py-[15px] bg-gradient-to-r from-[#F90013] to-[#FF4800]"
                                    >
                                        Add
                                    </button>
                                </div>

                                <div className="flex flex-col  gap-4">
                                    <div className="flex md:flex-row flex-col gap-[20px] items-center">
                                        <select
                                            onChange={handleColorChange}
                                            className="select select-bordered border-[#DCDDDE] w-full text-[#8C8D8E] max-w-xs focus:outline-none"
                                        >
                                            <option disabled defaultValue>
                                                Attributes
                                            </option>
                                            {attributes &&
                                                attributes.map((attribute) => (
                                                    <option key={attribute.id} value={attribute.id}>
                                                        {attribute.attribute_name}
                                                    </option>
                                                ))}
                                        </select>
                                        <input
                                            type="text"
                                            placeholder="Add a color"
                                            value={inputColor}
                                            onChange={handleInputChange}
                                            className="border border-[#DCDDDE] w-full h-[48px] rounded-xl placeholder:text-[#8C8D8E] pl-[16px] py-[14px] focus:outline-none focus:border-transparent"
                                        />
                                        <button
                                            type="button"
                                            className="btn bg-gradient-to-r w-full md:w-auto  from-[#F90013] to-[#FF4800] text-white"
                                            onClick={handleColorInput}
                                        >
                                            Add Color
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </fieldset>
                    </div>

                    <div className="bg-white rounded-lg"></div> */}
                </div>
            </div>
        </div>
    );
};

export default EditProduct;
