import React from "react";

const Subscribe = () => {
    return (
        <div className="bg-orange-50 text-center py-[106px] mt-[50px] px-[20px] md:px-0">
            <h2 className="text-red text-[28px] fw-bold">Subscribe to our newsletter</h2>
            <p className="mt-[16px] mb-[28px] text-[18px]">
                Be the first to receive updates about the Quickshop community
            </p>

            <div className="flex justify-center">
                <div className="flex items-center justify-center border border-[#FF4800] rounded-[12px] overflow-hidden p-[8px] bg-white">
                    <input type="text" className="py-[8px] px-[12px] outline-none w-[450px]" />
                    <button className="h-full block text-white fw-bold bg-[#FF2A00] p-2 py-[7px] md:px-[19px] rounded-[8px]">
                        Subscribe
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Subscribe;
