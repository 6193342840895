import React, { useState } from "react";
import merchant from "../Images/Mask group (2).png";
import Navbar from "../Navbar/Navbar";
import video from "../../Assets/Dropbox_Homepage.mp4";
import { BiStoreAlt } from "react-icons/bi";
import { IoBagOutline } from "react-icons/io5";
import { AiOutlineInbox } from "react-icons/ai";
import { LuGanttChartSquare } from "react-icons/lu";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import Footer from "../Footer/Footer";
import Subscribe from "../Subscribe/Subscribe";
import { Link } from "react-router-dom";
import accordion from "./faq";

const Merchant = () => {
    const [openAccordion, setOpenAccordion] = useState(null);
    const handleAccordionClick = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };

    return (
        <div>
            <Navbar />
            <div className="flex flex-col md:flex-row md:px-[100px] px-[20px] gap-[40px] pt-[120px] md:pt-0  md:pb-[57px] pd:mb-0">
                <div className="flex-1 shrink-0  md:flex flex-col md:gap-[28px] justify-center">
                    <h2 className="md:text-[58px] md:fw-bold text-[40px] fw-bold leading-[40px] md:leading-normal">
                        <span className="text-red">2000+ </span>businesses{" "}
                        <br />
                        trust QuickShop.
                    </h2>
                    <p>Set up your store in less than 5 minutes.</p>
                    <div className="flex items-center fw-bold gap-[20px]">
                        <Link
                            to="/signUp"
                            className="md:px-[37px] md:py-[19px] px-[12px] py-[16px] text-[14px] md:text-base  rounded-[12px] bg-gradient-to-r from-red to-orange text-white"
                        >
                            Create store
                        </Link>
                        <Link
                            to="/Contact"
                            className="md:px-[37px] md:py-[19px] px-[12px] py-[16px] text-[14px] md:text-base rounded-[12px] text-red bg-orange-100"
                        >
                            Contact Sales
                        </Link>
                    </div>
                </div>

                <div className="flex-1 shrink-0 h-full flex items-center">
                    <div className="md:h-[60%]  w-full md:rounded-[40px] rounded-[20px] overflow-hidden">
                        <img
                            src={merchant}
                            alt=""
                            className="w-full object-cover h-full"
                        />
                    </div>
                </div>
            </div>

            <div className="md:py-[100px] py-[100px] px-[20px] md:px-0">
                <h2 className="fw-bold text-center md:text-[32px] text-[24px]">
                    How to create your store
                </h2>
                <p className="text-center">
                    watch this short video to guide you on creating your store.
                </p>
                <div className="">
                    <div className="2xl:w-70 rounded-2xl flex justify-center w-full mt-10">
                        <video
                            src={video}
                            width={700}
                            height={400}
                            autoPlay
                            loop
                            muted
                        ></video>
                    </div>
                </div>
            </div>
            <div className="md:py-[100px]  py-[100px] px-[20px] md:px-0">
                <div>
                    <h2 className="fw-bold text-center md:text-[32px] text-[24px]">
                        Why QuickShop?
                    </h2>
                    <p className="text-center">
                        Get to know why 2000+ businesses trust QuickShop
                    </p>
                </div>
                <div className="mt-[20px]">
                    <div className="justify-evenly 2xl:mx-32 md:flex-row md:mt-10 mb-4 flex flex-col">
                        <div className="border-1 border-gray rounded-xl flex px-4 py-6 md:w-[40%] ">
                            <div className="flex justify-center">
                                <h2 className="bg-skyBlue pt-7 text-blue w-10 h-16 pl-5 text-lg rounded-full">
                                    <BiStoreAlt />
                                </h2>
                            </div>
                            <div className=" px-4">
                                <h3 className="fw-bold">
                                    User-Friendly Store Setup
                                </h3>
                                <div className="">
                                    <p className="pt-2 text-sm leading-6 text-justify">
                                        Creating your online store has never
                                        been easier. QuickShop offers a seamless
                                        and intuitive setup process, allowing
                                        merchants to effortlessly showcase their
                                        products.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="border-1 border-gray rounded-xl flex md:p-4 px-4 py-6 md:w-[40%] ">
                            <div className=" flex justify-center">
                                <h2 className="bg-lightOrange pt-7 w-10 h-16 pl-5 text-lg text-[#EF4809] rounded-full">
                                    <AiOutlineInbox />
                                </h2>
                            </div>
                            <div className=" px-4">
                                <h3 className="fw-bold">
                                    Robust Product Management
                                </h3>
                                <div className="">
                                    <p className="pt-2 text-sm leading-6 text-justify">
                                        Whether you have a handful of products
                                        or an extensive catalog, our platform
                                        adapts to your needs. Take control of
                                        your inventory with our powerful product
                                        management tools.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="justify-evenly 2xl:mx-32 md:flex-row md:mt-10 md:mb-4 flex flex-col">
                        <div className="border-1 border-gray rounded-xl flex md:p-4 px-4 py-6 md:w-[40%] ">
                            <div className=" flex justify-center">
                                <h2 className="bg-lightGreen pt-7 text-darkGreen w-10 h-16 pl-5 text-lg rounded-full">
                                    {" "}
                                    <IoBagOutline />
                                </h2>
                            </div>
                            <div className=" px-4">
                                <h3 className="fw-bold">
                                    Secure Payment Options
                                </h3>
                                <div className="">
                                    <p className="pt-2 text-sm leading-6 text-justify">
                                        We support a variety of payment gateways
                                        to accommodate different preferences.
                                        Our platform ensures a smooth and secure
                                        checkout process to boost your sales.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="border-1 border-gray rounded-xl flex px-4 py-6 md:w-[40%] ">
                            <div className="flex justify-center">
                                <h2 className=" bg-lightPurple pt-7 text-darkPurple w-10 h-16 pl-5 text-lg rounded-full">
                                    <LuGanttChartSquare />
                                </h2>
                            </div>
                            <div className=" px-4">
                                <h3 className=" fw-bold">
                                    Analytics and Insights
                                </h3>
                                <p className="pt-2 text-sm leading-6 text-justify">
                                    Track your sales and monitor all customer
                                    activities with our robust analytics and
                                    insight tools. We curate helpful articles to
                                    help you understand your audience better.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:py-[100px]  py-[100px] md:px-[100px] px-[20px]">
                <div>
                    <div>
                        <h2 className="fw-bold text-center md:text-[32px] text-[24px]">
                            Some <span className="text-red">top questions</span>{" "}
                            <br />
                            Merchants ask.
                        </h2>
                        <p className="text-center">
                            We have answered this question for you.
                        </p>
                    </div>
                    <div>
                        <div className="w-full mt-[20px]">
                            {accordion.map((item) => (
                                <div key={item.id} className="">
                                    <div
                                        className=" md:mt-10"
                                        onClick={() =>
                                            handleAccordionClick(item.id)
                                        }
                                        role="button"
                                    >
                                        <div className=" flex items-center justify-between pt-4">
                                            <h2 className="inline-block fw-bold">
                                                {item.title}
                                            </h2>
                                            {openAccordion === item.id ? (
                                                <h2
                                                    class="text-sm md
                                                    "
                                                >
                                                    <IoIosArrowDown />
                                                </h2>
                                            ) : (
                                                <h2 class="text-sm  ">
                                                    <IoIosArrowForward />
                                                </h2>
                                            )}
                                        </div>
                                    </div>
                                    {openAccordion === item.id && (
                                        <div className="py-4">
                                            <h2 className="">
                                                {item.description}
                                            </h2>
                                        </div>
                                    )}
                                    <div className="border-b-1 border-gray w-full py-4"></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Subscribe />
            <Footer />
        </div>
    );
};

export default Merchant;
