const accordion = [
    {
        id: 1,
        title: "What is Quickshop?",
        description:
            "Quickshop is an e-commerce marketplace connecting vendors with online buyers",
    },
    {
        id: 2,
        title: "What can I sell on Quickshop?",
        description:
            "You can sell any physical product on Quickshop as long as it is legal.",
    },
    {
        id: 3,
        title: "Is Quickshop only available in Nigeria?",
        description:
            "Yes, Quickshop is currently only available in Nigeria but is set to have a global launch soon.",
    },
    {
        id: 4,
        title: "On what platform can I have Quickshop?",
        description:
            "You can get the Quickshop app on the Apple Store or Play Store and via the website",
    },
    {
        id: 5,
        title: "Is Quickshop free?",
        description:
            "Yes, setting up your store on Quickshop is free of charge",
    },
    {
        id: 6,
        title: "Can I offer discounts and coupons for my customers?",
        description:
            "Yes, you can offer discounts and coupon codes to your customers through the Quickshop app.",
    },
    {
        id: 7,
        title: "Does my customer need to have a Quickshop app to buy from my store?",
        description:
            "Not at all. Your customers can place orders on the quickshop website as well, so they don’t need to download the mobile app.",
    },
    {
        id: 8,
        title: "What happens when I get an order?",
        description:
            "Every time you receive an order, you will receive an in-app notification and also an email to notify you about your order.",
    },
    {
        id: 9,
        title: "How do I track my orders?",
        description:
            "All your orders are displayed on your dashboard, so you can monitor your orders(pending and completed orders) directly from your dashboard",
    },
    {
        id: 10,
        title: "How do I track my sales?",
        description:
            "All your sales are displayed on your dashboard, so you can monitor your sales directly from your dashboard. ",
    },
    {
        id: 11,
        title: "When do I receive my payment?",
        description:
            "Payment is remitted into your bank account once the product has been delivered to buyers.",
    },
    {
        id: 12,
        title: "How do I get paid?",
        description:
            "When setting up your Quickshop account, you add your account details. The bank details added is where the payouts will be sent.",
    },
    {
        id: 13,
        title: "Does Quickshop have a transaction charge?",
        description:
            "When setting up your Quickshop account, you add your account details. The bank details added is where the payouts will be sent.",
    },
    {
        id: 14,
        title: "What payment methods are available for my customers?",
        description:
            "We provide options like Card, USSD, Bank transfer, and Bank.",
    },
    {
        id: 15,
        title: "Why do I need to add my BVN?",
        description:
            "Your BVN is needed to verify your account to ensure your payment is paid into the right bank account.",
    },
];

export default accordion;
