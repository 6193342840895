import React from "react";
import { FaRegStar } from "react-icons/fa6";
import { Context } from "../Context/Context";
import { useContext } from "react";
import { Link } from "react-router-dom";

const Product = ({ type }) => {
    const { products } = useContext(Context);

    return (
        <div className="md:pt-[100px] px-[20px] pt-[48px] md:px-[100px]">
            <h2 className="text-xl md:mb-[40px] mb-[20px] fw-bold">
                {type} Product
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-4 md:gap-[40px] gap-y-[28px] gap-x-[15px]">
                {products?.data?.map((product) => (
                    <Link
                        to={`/product/${product.product_name}`}
                        state={{ product }}
                        key={product.product_name}
                    >
                        <div className="">
                            <div className="mb-[16px] md:mb-[34px] md:h-[200px] h-[100px]">
                                <img
                                    src={product.product_images[0].images}
                                    className="rounded-xl w-full h-full object-cover"
                                    alt=""
                                />
                            </div>

                            <h2 className="capitalize md:text-base text-[14px]">
                                {product.product_name}
                            </h2>
                            <h2 className="fw-bold text-[15px] md:text-[20px] leading-10">
                                ₦{product.selling_price.toLocaleString()}
                            </h2>

                            <div className="md:mt-[15px] mt-[8x] md:text-base text-[11px] flex md:gap-[15px] gap-[3px] items-center">
                                <Link
                                    state={{
                                        id: product.merchant_type.stores[0].id,
                                    }}
                                    to={`/merchant-store/${product.merchant_type.stores[0].business_name
                                        .split(" ")
                                        .join("-")}`}
                                >
                                    {
                                        product.merchant_type.stores[0]
                                            .business_name
                                    }
                                </Link>
                                <div className=" bg-orange-50 flex items-center rounded-full">
                                    <div>
                                        <h2 className="text-red">
                                            <FaRegStar />
                                        </h2>
                                    </div>
                                    <h2>(0)</h2>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Product;
